/* eslint-disable max-len */

import Svg, { Path } from 'react-native-svg';

const NotificationIcon = () => (
  <Svg width="72" height="81" viewBox="0 0 72 81" fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M23.8996 26.2999C17.2996 30.4999 18.5996 43.6999 18.6996 45.1999C18.7996 46.1999 19.6996 47 20.6996 47C20.7996 47 20.8996 47 20.8996 47C21.9996 46.9 22.7996 45.9 22.6996 44.7999C22.2996 41.6999 22.1995 32.1999 25.9995 29.6999C26.8995 29.0999 27.1995 27.8999 26.5995 26.8999C26.0995 25.9999 24.8996 25.6999 23.8996 26.2999Z"
      fill="#E87451"
    />
    <Path
      d="M2 68H26.5V70.6C26.5 75.8 30.8 80.1 36 80.1C41.2 80.1 45.5 75.8 45.5 70.6V68H70C71.1 68 72 67.1 72 66C72 60.2 68.2 55.3 63 53.6V37.5C63 25.3 55 14.9 44 11.4V8C44 3.6 40.4 0 36 0C31.6 0 28 3.6 28 8V11.4C17 14.9 9 25.2 9 37.5V53.7C3.8 55.4 0 60.3 0 66.1C0 67.1 0.9 68 2 68ZM41.5 70.7C41.5 73.7 39 76.2 36 76.2C33 76.2 30.5 73.7 30.5 70.7V68.1H41.5V70.7ZM32 8C32 5.8 33.8 4 36 4C38.2 4 40 5.8 40 8V10.4C38.7 10.2 37.4 10.1 36 10.1C34.6 10.1 33.3 10.2 32 10.4V8ZM13 37.5C13 24.7 23.3 14.2 36 14.2C48.7 14.2 59 24.7 59 37.5V53.1H13V37.5ZM13 57H59C63.3 57 66.9 60 67.8 64H4.2C5.1 60 8.7 57 13 57Z"
      fill="#E87451"
    />
  </Svg>
);

export default NotificationIcon;
