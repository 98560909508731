/* eslint-disable @typescript-eslint/no-explicit-any */

import EventEmitter from 'events';
import Constants from 'expo-constants';

const eventemitter = new EventEmitter();
const BASE = Constants?.expoConfig?.extra?.apiUrl ?? 'http://localhost:8081';

const options = {
  cache: 'no-cache',
  credentials: 'same-origin',
  mode: 'cors',
  redirect: 'manual',
  referrer: 'no-referrer',
};

const headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

const addHeader = (key, value) => {
  headers[key] = value;
};

const removeHeaders = (key) => {
  delete headers[key];
};

const getHeaders = () => headers;

const checkResponse = async (res) => {
  if (!res.ok) {
    switch (res.status) {
      case 401:
        eventemitter.emit('unauthorized');
        break;
      default:
        break;
    }
    let message = res.status;
    try {
      const body = await res.json();
      if (body && body.message) {
        message = body.message;
      }
    } catch (e) {
      // Intentionally left blank
    }
    throw new Error(message);
  }
  return res;
};

const post = async (url, data) =>
  fetch(encodeURI(`${BASE}${url}`), {
    ...options,
    method: 'POST',
    headers,
    body: JSON.stringify(data),
  }).then(checkResponse);

const postNoCheck = async (url, data) =>
  fetch(encodeURI(`${BASE}${url}`), {
    ...options,
    method: 'POST',
    headers,
    body: JSON.stringify(data),
  });

const put = async (url, data) =>
  fetch(encodeURI(`${BASE}${url}`), {
    ...options,
    method: 'PUT',
    headers,
    body: JSON.stringify(data),
  }).then(checkResponse);

const putExternalFile = async (url, body) =>
  fetch(`${url}`, {
    ...options,
    method: 'PUT',
    headers: {
      'Access-Control-Allow-Origin': '*',
    },
    body,
  }).then(checkResponse);

const patch = async (url, data) =>
  fetch(encodeURI(`${BASE}${url}`), {
    ...options,
    method: 'PATCH',
    headers,
    body: JSON.stringify(data),
  }).then(checkResponse);

const get = async (url) =>
  fetch(encodeURI(`${BASE}${url}`), {
    ...options,
    method: 'GET',
    headers,
  }).then(checkResponse);

const del = async (url, body) =>
  fetch(encodeURI(`${BASE}${url}`), {
    ...options,
    method: 'DELETE',
    headers,
    body: body ? JSON.stringify(body) : undefined,
  }).then(checkResponse);

const rest = {
  get,
  put,
  putExternalFile,
  patch,
  post,
  postNoCheck,
  del,
  getHeaders,
  addHeader,
  removeHeaders,
  eventemitter,
};
export default rest;
