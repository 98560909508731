import appsFlyer from 'react-native-appsflyer';

appsFlyer.initSdk(
  {
    devKey: 'RQRCDsu8BJyjwdDimt4a3j',
    isDebug: true,
    appId: '1471493923',
    onInstallConversionDataListener: true, //Optional
    onDeepLinkListener: true, //Optional
    timeToWaitForATTUserAuthorization: 10, //for iOS 14.5
  },
  (result) => {
    console.log(result);
  },
  (error) => {
    console.error(error);
  },
);
