import { useCallback, useContext } from 'react';
import { useLinkTo, useNavigation } from '@react-navigation/native';
import { Box, IconButton, ScrollView } from 'native-base';

import ChangeHouseholdSize from '../../components/BottomDrawer/Filters/ChangeHouseholdSize';
import ChangeTags from '../../components/BottomDrawer/Filters/ChangeTags';
import FilterSection from '../../components/BottomDrawer/Filters/FilterSection';
import FAProIcon from '../../components/FAProIcon';
import Heading1 from '../../components/Heading/Heading1';
import SupermarketChip from '../../components/SupermarketChip';
import { CommonContext } from '../../contexts/CommonContext';
import { FilterContext } from '../../contexts/FilterContext';
import { UserContext } from '../../contexts/UserContext';
import { TAG_GROUPS } from '../../hooks/useTagGroup';

const TopNavIconButton = ({ onPress, icon, outline, ...props }) => (
  <IconButton
    variant="ghost"
    size="small"
    onPress={onPress}
    icon={<FAProIcon name={icon} />}
    borderWidth={outline ? 1 : undefined}
    borderColor="#E3E3E3"
    {...props}
  />
);

const AccountScreenPreferences = () => {
  const linkTo = useLinkTo();
  const navigation = useNavigation();

  const { retailers } = useContext(CommonContext);
  const { saveFilters } = useContext(FilterContext);
  const { profile, updateProfile } = useContext(UserContext);

  const handleBack = () => {
    saveFilters();
    navigation.canGoBack() ? navigation.goBack() : linkTo('/home');
  };

  const handleRetailerChange = useCallback(
    (retailer) => {
      updateProfile({ retailer: retailer.id });
    },
    [updateProfile],
  );

  return (
    <>
      <Box height="72px" bg="white" alignItems="center" gap={4} px={5} py={4} flexDirection="row" width="100%">
        <TopNavIconButton onPress={handleBack} icon="arrow-left" />
      </Box>
      <ScrollView bg="white">
        <Box gap="4" px="4" py="4" alignItems="flex-start">
          <Heading1>My preferences</Heading1>

          <ChangeHouseholdSize />
          <ChangeTags label="Dietary requirements" group={TAG_GROUPS.avoidDiet} withClear={false} />
          <ChangeTags label="Allergies" group={TAG_GROUPS.avoidance} withClear={false} />
          <FilterSection label="Supermarkets">
            <Box flexDirection="row" gap={2} flexWrap="wrap">
              {retailers.map((retailer) => (
                <SupermarketChip
                  key={retailer.id}
                  retailer={retailer}
                  selected={retailer.id === profile?.retailer}
                  onPress={() => handleRetailerChange(retailer)}
                />
              ))}
            </Box>
          </FilterSection>
        </Box>
      </ScrollView>
    </>
  );
};

export default AccountScreenPreferences;
