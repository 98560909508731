import { useContext } from 'react';
import { Box, Button } from 'native-base';

import { FilterContext } from '../../../contexts/FilterContext';

const FiltersFooter = ({ handleClose, handleClearFilters, boxProps }) => {
  const { filteredList } = useContext(FilterContext);

  return (
    <Box
      flexDirection="row"
      justifyContent="space-between"
      width="100%"
      borderTopColor="#E3E3E3"
      borderTopStyle="solid"
      borderTopWidth="1px"
      paddingTop={4}
      {...boxProps}
    >
      <Button size="large" variant="unstyled" onPress={handleClearFilters}>
        Clear filters
      </Button>
      <Button size="large" variant="solid" onPress={handleClose}>
        {`Show ${filteredList.length} recipes`}
      </Button>
    </Box>
  );
};

export default FiltersFooter;
