import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Text } from 'react-native';
import { useLinkTo } from '@react-navigation/native';
import * as Haptics from 'expo-haptics';
import { Box, Button, Center, useTheme } from 'native-base';

import Body2 from '../../components/Body/Body2';
import Body3 from '../../components/Body/Body3';
import AppleLoginButton from '../../components/Button/AppleLoginButton/AppleLoginButton';
import FacebookLoginButton from '../../components/Button/FacebookLoginButton/FacebookLoginButton';
import ErrorInfoTip from '../../components/ErrorInfoTip';
import OrLine from '../../components/OrLine';
import PasswordTextField from '../../components/PasswordTextField';
import TextField from '../../components/TextField';
import { GlobalContext } from '../../contexts/GlobalContext';
import { UserContext } from '../../contexts/UserContext';
import { RE_EMAIL } from '../../lib/util';

import LoginContainer from './components/LoginContainer';

const LoginScreen = () => {
  const { colors } = useTheme();

  const { notLoggedInRedirect } = useContext(GlobalContext);
  const { login, loginError } = useContext(UserContext);
  const linkTo = useLinkTo();

  const [email, emailSet] = useState('');
  const [password, passwordSet] = useState('');
  const [busy, busySet] = useState(false);
  const [step, stepSet] = useState(0);

  useEffect(() => {
    if (loginError) {
      Haptics.notificationAsync(Haptics.NotificationFeedbackType.Error);
      stepSet(0);
      passwordSet('');
    }
  }, [loginError]);

  const handleSubmit = () => {
    const userLogin = async () => {
      busySet(true);
      try {
        await login(email, password);
        if (notLoggedInRedirect) {
          linkTo(notLoggedInRedirect);
        } else {
          linkTo('/home');
        }
      } finally {
        busySet(false);
      }
    };
    void userLogin();
  };

  const handleRegisterNow = () => {
    linkTo('/register');
  };

  const handleForgotPassword = () => {
    linkTo('/reset-password');
  };

  const handleContinue = () => stepSet(1);
  const handleBack = step > 0 ? () => stepSet(0) : undefined;

  const continueDisabled = useMemo(() => !RE_EMAIL.test(email), [email]);

  return (
    <LoginContainer
      icon={!!step}
      title={step === 0 ? 'Log in' : 'Welcome back'}
      subtitle="Sign in to Plate Up"
      onBackPress={handleBack}
    >
      {step === 0 ? (
        <>
          <Box gap={4}>
            <TextField placeholder="Email address" value={email} onChange={emailSet} />
            <ErrorInfoTip text={loginError} />
            <Button variant="solid" onPress={handleContinue} isDisabled={continueDisabled} width="100%">
              Continue
            </Button>
          </Box>
          <OrLine />
          <FacebookLoginButton width="100%" />
          <AppleLoginButton width="100%" />
          <Center flexDirection="row" mt="auto">
            <Text>
              <Body3>Don't have an account? </Body3>
              <Body3 onPress={handleRegisterNow} color={colors.burntApricot[500]}>
                Sign up
              </Body3>
            </Text>
          </Center>
        </>
      ) : (
        <Box gap={4}>
          <PasswordTextField value={password} onChange={passwordSet} />
          <ErrorInfoTip text={loginError} />
          <Button variant="solid" onPress={handleSubmit} isDisabled={!password} width="100%" isLoading={busy}>
            Continue
          </Button>
          <Center>
            <Body2 color={colors.burntApricot[500]} onPress={handleForgotPassword}>
              Forgot password?
            </Body2>
          </Center>
        </Box>
      )}
    </LoginContainer>
  );
};

export default LoginScreen;
