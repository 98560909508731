import { Box, Button, ScrollView } from 'native-base';

import Body2Bold from '../../components/Body/Body2Bold';
import Counter from '../../components/Counter';
import Heading3Bold from '../../components/Heading/Heading3Bold';

import OnboardingHeader from './OnboardingHeader';

const OnboardingScreenHousehold = (props) => {
  const { onNext, onPrev, onSkip, data, dataSet } = props;
  return (
    <ScrollView __contentContainer={{ alignItems: 'center', height: '100%' }} bg="white">
      <Box bg="white" height="100%" paddingY={6} paddingX={4}>
        <OnboardingHeader onPrev={onPrev} onSkip={onSkip} active={4} />

        <Box padding={10} alignItems="center" gap={6}>
          <Heading3Bold textAlign="center">Tell us about your household</Heading3Bold>

          <Body2Bold textAlign="center" paddingBottom={8}>
            How many servings do you usually need when you're making your meals?
          </Body2Bold>

          <Box padding={4} borderRadius={999} borderWidth={1} borderColor="#eee">
            <Counter
              value={data.householdSize || 0}
              onChange={(householdSize) => dataSet({ ...data, householdSize })}
            />
          </Box>
        </Box>

        <Box flex={1} />

        <Button onPress={onNext}>Next</Button>
      </Box>
    </ScrollView>
  );
};

export default OnboardingScreenHousehold;
