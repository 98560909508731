import React from 'react';
import { Box } from 'native-base';

import Placeholder from '../Placeholder';
import PlaceholderText from '../PlaceholderText';

const PlaceholderCartItem = () => (
  <Box gap={3}>
    <Placeholder width="165px" height="160px" padding={2} />
    <Box gap={1}>
      <PlaceholderText width="100%" height="12px" />
      <PlaceholderText width="66%" height="12px" />
    </Box>
  </Box>
);

const PlaceholderCartItems = () => (
  <Box gap={3} flexDirection="row">
    <PlaceholderCartItem />
    <PlaceholderCartItem />
  </Box>
);

export default PlaceholderCartItems;
