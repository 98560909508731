import { useContext, useState } from 'react';
import { useLinkTo, useNavigation } from '@react-navigation/native';
import { Box, Button, Center, IconButton, Pressable, ScrollView } from 'native-base';

import Body1 from '../../components/Body/Body1';
import FAProIcon from '../../components/FAProIcon';
import Heading3Bold from '../../components/Heading/Heading3Bold';
import TextField from '../../components/TextField';
import { UserContext } from '../../contexts/UserContext';

const TopIconButton = ({ onPress, icon, solid, color, ...props }) => (
  <IconButton
    variant="ghost"
    size="small"
    onPress={onPress}
    icon={<FAProIcon name={icon} solid={solid} color={color} />}
    {...props}
  />
);

const AccountScreenPersonalInfo = () => {
  const navigation = useNavigation();
  const linkTo = useLinkTo();
  const { email, profile, updateProfile } = useContext(UserContext);

  const [firstName, firstNameSet] = useState(profile?.firstName || '');
  const [lastName, lastNameSet] = useState(profile?.lastName || '');

  const handleSave = () => {
    updateProfile({ firstName, lastName });
  };
  const handleBack = () => {
    navigation.canGoBack() ? navigation.goBack() : linkTo('/home');
  };
  const handleDelete = () => {
    linkTo('/account/delete');
  };

  return (
    <ScrollView bg="white">
      <Box height="72px" alignItems="center" gap={4} px={5} py={4} flexDirection="row" zIndex={1} width="100%">
        <TopIconButton onPress={handleBack} icon="arrow-left" />
        <Box flexGrow={1} />
        <Button size="small" variant="ghost" onPress={handleSave}>
          Save
        </Button>
      </Box>

      <Box gap={4} px={5} py={7} alignItems="flex-start">
        <Heading3Bold>Edit personal info</Heading3Bold>

        <TextField value={firstName} placeholder="First name" onChange={firstNameSet} />
        <TextField value={lastName} placeholder="Last name" onChange={lastNameSet} />
        <TextField value={email} disabled />

        <Box height="16px" />

        <Center width="100%" paddingTop={2}>
          <Pressable onPress={handleDelete}>
            <Body1 color="ketchup.500">Delete account</Body1>
          </Pressable>
        </Center>
      </Box>
    </ScrollView>
  );
};

export default AccountScreenPersonalInfo;
