import React from 'react';
import { Box, IconButton } from 'native-base';

import FAProIcon from '../../FAProIcon';
import Heading2 from '../../Heading/Heading2';

const FiltersHeader = ({ title = 'Filters', handleBack, handleClose }) => (
  <Box width="100%" alignItems="center" justifyContent="space-between" flexDirection="row" paddingY={3}>
    <Box flex="1">
      {handleBack && (
        <Box flexDirection="row">
          <IconButton
            ml="auto"
            variant="ghost"
            size="large"
            icon={<FAProIcon name="arrow-left" />}
            onPress={handleBack}
          />
          <Box flex="1" />
        </Box>
      )}
    </Box>
    <Heading2 flex={0} textAlign="center" ml="auto">
      {title}
    </Heading2>
    <Box flex="1">
      <IconButton ml="auto" variant="ghost" size="large" icon={<FAProIcon name="close" />} onPress={handleClose} />
    </Box>
  </Box>
);

export default FiltersHeader;
