import React from 'react';
import { Box } from 'native-base';

import FAProIcon from './FAProIcon';
import TextField from './TextField';

const SearchBox = (props) => (
  <TextField
    height="40px"
    isFullWidth={true}
    flexDirection="row"
    alignItems="center"
    placeholder="Recipes, chefs, ingredients"
    flex="1"
    borderRadius="100px"
    pt="11.5px"
    pr="10px"
    pb="10px"
    pl="16px"
    bgColor="white"
    fontSize="14"
    borderStyle="solid"
    borderWidth="1px"
    borderColor="#E3E3E3"
    InputLeftElement={
      <Box pl="4">
        <FAProIcon name="search" size={18} />
      </Box>
    }
    returnKeyType="search"
    _input={{
      textOverflow: 'ellipsis',
      width: '100%',
    }}
    _android={{
      _input: {
        // TODO: find better approach for handling this
        // when set to 100% width it doesn't take into account the Icon on the left so will display text outside of the
        //  search box
        width: '80%',
      },
    }}
    ios={{
      _input: {
        // TODO: find better approach for handling this
        // when set to 100% width it doesn't take into account the Icon on the left so will display text outside of the
        //  search box
        width: '80%',
      },
    }}
    {...props}
  />
);

export default SearchBox;
