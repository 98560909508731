import React from 'react';
import { Box } from 'native-base';

import PlaceholderText from '../PlaceholderText';

import PlaceholderCartItems from './PlaceholderCartItems';
import PlaceholderListIngredients from './PlaceholderListIngredients';

const PlaceholderCartPage = () => (
  <Box maxWidth="400px" gap={3} padding={5}>
    <PlaceholderText width="165px" height="12px" />
    <PlaceholderCartItems />
    <Box height={16} />
    <PlaceholderText width="152px" height="12px" />
    <PlaceholderListIngredients />
  </Box>
);

export default PlaceholderCartPage;
