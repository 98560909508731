import React from 'react';
import { Box, useTheme } from 'native-base';

import Body3 from './Body/Body3';
import FAProIcon from './FAProIcon';

const InfoTipLarge = ({ text, children, withIcon }) => {
  const { colors } = useTheme();

  return (
    <Box bg={colors.almond[400]} borderRadius={8} px={3} py={2} flexDirection="row" alignItems="center">
      {withIcon && (
        <Box width="12px">
          <FAProIcon name="info-circle" size={12} />
        </Box>
      )}
      <Box px={2}>
        {children}
        {text && <Body3>{text}</Body3>}
      </Box>
    </Box>
  );
};

export default InfoTipLarge;
