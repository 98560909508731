import { useCallback, useEffect, useState } from 'react';

const useCrud = (use) => {
  const [loading, loadingSet] = useState(true);
  const [rows, rowsSet] = useState([]);

  const setLoading = () => {
    loadingSet(true);
    return Promise.resolve();
  };
  const clearLoading = () => {
    loadingSet(false);
    return Promise.resolve();
  };

  const list = useCallback(
    () =>
      setLoading()
        .then(() => use.list())
        .then(rowsSet)
        .then(clearLoading),
    [use],
  );

  const get = useCallback(
    (id) =>
      setLoading()
        .then(() => use.get(id))
        .then((res) => {
          clearLoading();
          return res;
        }),
    [use],
  );

  const post = useCallback(
    (id, v) =>
      setLoading()
        .then(() => use.post(id, v))
        .then(list),
    [list, use],
  );

  const put = useCallback(
    (id, v) =>
      setLoading()
        .then(() => use.put(id, v))
        .then(list),
    [list, use],
  );

  const del = useCallback(
    (ids) =>
      setLoading()
        .then(() => Promise.allSettled(ids.map((id) => use.del(id))))
        .then(list),
    [list, use],
  );

  useEffect(() => {
    list();
  }, [list]);

  return {
    loading,
    rows,
    list,
    get,
    post,
    put,
    del,
  };
};

export default useCrud;
