import { useContext, useState } from 'react';
import { Linking } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useIsFocused, useLinkTo } from '@react-navigation/native';
import Constants from 'expo-constants';
import * as Updates from 'expo-updates';
import { Box, Button, ScrollView, useTheme } from 'native-base';

import Body1 from '../../components/Body/Body1';
import CustomText from '../../components/CustomText';
import Heading1 from '../../components/Heading/Heading1';
import ListItem from '../../components/ListItem';
import Loading from '../../components/Loading';
import SubHeading2 from '../../components/SubHeading/SubHeading2';
import { UserContext } from '../../contexts/UserContext';
import { abandonedCartReset } from '../../lib/background-task/abandoned-cart';
import { userRegistrationReset } from '../../lib/background-task/user-registration';
import { showNotification } from '../../lib/notifications';

const CONTACT_EMAIL = 'support@plateup.app';

const AccountScreen = () => {
  const { profile, updateProfile, logout } = useContext(UserContext);
  const linkTo = useLinkTo();
  const { colors } = useTheme();
  const [commitTapCount, commitTapCountSet] = useState(0);

  const isFocused = useIsFocused();
  if (!isFocused) return <Loading />;

  const handleGettingStarted = async () => {
    await Linking.openURL('https://www.plateup.app/#step1');
  };
  const handleFAQs = async () => {
    await Linking.openURL('https://plateup.app/frequently-asked-questions');
  };
  const handleContactUs = async () => {
    await Linking.openURL(`mailto:${CONTACT_EMAIL}`);
  };
  const handlePersonalInfo = async () => {
    linkTo('/account/personal-info');
  };
  const handlePreferences = async () => {
    linkTo('/account/preferences');
  };

  const loggedIn = !!profile;
  const title = `${profile?.firstName || ''} ${profile?.lastName || ''}`.trim() || 'Account';

  const commitHash = Constants?.expoConfig?.extra?.commitHash;

  const handleCommitHashPress = () => {
    commitTapCountSet((a) => a + 1);
  };

  const handleResetOnboarding = async () => {
    await AsyncStorage.setItem('onboarding-complete', 'false');
    linkTo('/onboarding');
  };

  const handleResetRetailerCheckouts = async () => {
    updateProfile({ retailerCheckouts: [] });
  };

  const handleTestNotification = async () => {
    showNotification('test notification', 'this is a test notification', '/recipe/tex-mex-burger-');
  };

  const notificationNagReset = async () => {
    await AsyncStorage.removeItem('notification-permission');
    linkTo('/notification-nag');
  };

  return (
    <ScrollView bg="white">
      <Box gap="4" px="4" py="4" alignItems="flex-start">
        <Heading1>{title}</Heading1>
        {!loggedIn && (
          <>
            <Body1>Log in to start planning your meals.</Body1>
            <Box flexDirection="row" alignItems="center">
              <Body1>Don't have an account?</Body1>
              <Button variant="link" onPress={() => linkTo('/register')}>
                Sign up
              </Button>
            </Box>
            <Button variant="subtle" onPress={() => linkTo(`/login`)} width="100%">
              login
            </Button>
          </>
        )}
        <Box gap="2.5">
          <SubHeading2 py={2}>Settings</SubHeading2>
          <ListItem icon="user" text="Personal information" disabled={!loggedIn} onPress={handlePersonalInfo} />
          <ListItem icon="sliders" text="My preferences" onPress={handlePreferences} disabled={!loggedIn} />
          {/* <ListItem icon="truck" text="Previous orders" disabled={!loggedIn} /> */}
          {/* <ListItem icon="bell" text="Notifications" /> */}
        </Box>
        <Box gap="2.5">
          <SubHeading2 py={2}>Support</SubHeading2>
          <ListItem onPress={handleGettingStarted} icon="play" text="Getting started" />
          <ListItem onPress={handleFAQs} icon="message-question" text="FAQ's" />
          <ListItem onPress={handleContactUs} icon="paper-plane" text="Contact us" />
        </Box>
        {loggedIn && (
          <ListItem
            color={colors.ketchup[500]}
            onPress={() => {
              logout();
              linkTo('/logout');
            }}
            icon="right-from-bracket"
            text="Log out"
          />
        )}
        <Box flex="1" />
        <Box flexDirection="column">
          <CustomText fontSize={10} color={colors.brightNavy[200]} onPress={handleCommitHashPress}>
            {Updates.runtimeVersion || 'Plate Up'}
          </CustomText>
          <CustomText fontSize={10} color={colors.brightNavy[200]} onPress={handleCommitHashPress}>
            {commitHash}
          </CustomText>
        </Box>

        {commitTapCount > 8 && (
          <Box gap="2.5">
            <SubHeading2 py={2}>Debug</SubHeading2>

            <ListItem color={colors.ketchup[500]} onPress={handleResetOnboarding} icon="bug" text="Reset Onboarding" />

            <ListItem
              color={colors.ketchup[500]}
              onPress={handleResetRetailerCheckouts}
              icon="bug"
              text="Reset Retailer Checkouts"
            />

            <ListItem
              color={colors.ketchup[500]}
              onPress={notificationNagReset}
              icon="bug"
              text="Reset notification nag"
            />
            <ListItem
              color={colors.ketchup[500]}
              onPress={handleTestNotification}
              icon="bug"
              text="Test notification"
            />

            <ListItem
              color={colors.ketchup[500]}
              onPress={abandonedCartReset}
              icon="bug"
              text="Reset abandoned cart notification"
            />

            <ListItem
              color={colors.ketchup[500]}
              onPress={userRegistrationReset}
              icon="bug"
              text="Reset user registration notification"
            />
          </Box>
        )}
      </Box>
    </ScrollView>
  );
};

export default AccountScreen;
