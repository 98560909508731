/* eslint-disable no-restricted-globals */
import { Box, Text } from 'native-base';

const LoginAppleScreenIos = () => (
  <Box>
    <Text>Logging in with apple... (ios)</Text>
  </Box>
);

export default LoginAppleScreenIos;
