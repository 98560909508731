import { useContext, useState } from 'react';
import { Text } from 'react-native';
import { useLinkTo } from '@react-navigation/native';
import { Box, Button, useTheme } from 'native-base';

import Body3 from '../../../components/Body/Body3';
import Body3Bold from '../../../components/Body/Body3Bold';
import ErrorInfoTip from '../../../components/ErrorInfoTip';
import TextField from '../../../components/TextField';
import { UserContext } from '../../../contexts/UserContext';
import { RE_EMAIL } from '../../../lib/util';
import LoginContainer from '../components/LoginContainer';

const ResetPasswordScreen = () => {
  const { colors } = useTheme();
  const linkTo = useLinkTo();
  const { resetPassword, busy } = useContext(UserContext);
  const [inputError, inputErrorSet] = useState('');

  const [email, emailSet] = useState('');

  const handleSubmit = async () => {
    if (!RE_EMAIL.test(email)) {
      inputErrorSet('Please enter a valid email address');
      return;
    }
    await resetPassword(email);
    linkTo('/reset-password/email-sent');
  };

  return (
    <LoginContainer
      title="Reset Password"
      subtitle="Please enter your email address below and we will send you an email to reset your password."
    >
      <TextField placeholder="Email address" value={email} onChange={emailSet} />
      <Box pt={2} pb={4}>
        <Text>
          <Body3Bold color={colors.brightNavy[500]}>Please note: </Body3Bold>
          <Body3 color={colors.brightNavy[500]}>
            This feature will not work for Apple or Facebook sign-in services. If you have forgotten your password for
            these services, please request a reset via the respective website.
          </Body3>
        </Text>
      </Box>
      <ErrorInfoTip text={inputError} />
      <Button onPress={handleSubmit} busy={busy} isDisabled={!email} width="100%">
        Send reset link
      </Button>
    </LoginContainer>
  );
};

export default ResetPasswordScreen;
