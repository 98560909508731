import React, { useContext } from 'react';
import { Box } from 'native-base';

import { CommonContext } from '../../../contexts/CommonContext';
import { FilterContext } from '../../../contexts/FilterContext';
import Chip from '../../Chip';

import FilterSection from './FilterSection';

const ChangePillar = ({ label = 'Meal Categories', withClear = true, boxProps }) => {
  const { pillars } = useContext(CommonContext);
  const { pillar, pillarSet } = useContext(FilterContext);

  const handlePillarChange = (type) => {
    pillarSet(type === pillar ? undefined : type);
  };

  const handleClear = () => {
    pillarSet('');
  };

  const items = pillars.map((p) => (
    <Chip
      key={p.id}
      label={p.displayTitle}
      variant={pillar === p.id ? 'active' : 'outline'}
      onPress={() => handlePillarChange(p.id)}
    />
  ));

  return (
    <FilterSection label={label} clearFilter={withClear ? handleClear : undefined} boxProps={boxProps}>
      <Box flexDirection="row" gap={2} flexWrap="wrap">
        {items}
      </Box>
    </FilterSection>
  );
};

export default ChangePillar;
