import React, { useContext, useMemo } from 'react';
import * as Haptics from 'expo-haptics';
import { Box, Row, ScrollView } from 'native-base';

import { CommonContext } from '../contexts/CommonContext';
import { FilterContext } from '../contexts/FilterContext';
import { GlobalContext } from '../contexts/GlobalContext';

import Body2Bold from './Body/Body2Bold';
import { FILTER_SINGLE_COMPONENT } from './BottomDrawer/FiltersSingle';
import Chip from './Chip';
import FAProIcon from './FAProIcon';

const FiltersBar = () => {
  const { filtersMainDialogOpenSet, filtersSingleDialogTypeSet } = useContext(GlobalContext);
  const { pillars } = useContext(CommonContext);
  const { cuisinesSelected, mealTypesSelected, difficultiesSelected, pillar } = useContext(FilterContext);

  const tagsFilterLabel = (label, selectedTags) => {
    if (!selectedTags || !selectedTags.length) return label;
    return `${label} • ${selectedTags.length}`;
  };

  const openMainFiltersDialog = () => {
    Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
    filtersMainDialogOpenSet(true);
  };

  const cuisinesLabel = useMemo(() => tagsFilterLabel('Cuisines', cuisinesSelected), [cuisinesSelected]);

  const foundPillar = useMemo(() => pillars.find((p) => p.id === pillar), [pillar, pillars]);
  const pillarLabel = foundPillar?.displayTitle || 'Meal Categories';

  const totalActiveFilters = cuisinesSelected.length + mealTypesSelected.length + difficultiesSelected.length;

  return (
    <Box flex={1}>
      <ScrollView horizontal py={5}>
        <Row space={2}>
          <Box width={1} />
          <Chip variant="filled" onPress={openMainFiltersDialog}>
            <Row alignItems="center">
              <FAProIcon name={'sliders-simple'} />
              <Body2Bold>{totalActiveFilters > 0 ? ` • ${totalActiveFilters}` : ''}</Body2Bold>
            </Row>
          </Chip>
          <Chip
            label={pillarLabel}
            endIconName="chevron-down"
            onPress={() => filtersSingleDialogTypeSet(FILTER_SINGLE_COMPONENT.pillar)}
            variant={pillar ? 'active' : undefined}
          />
          <Chip
            label={cuisinesLabel}
            endIconName="chevron-down"
            onPress={() => filtersSingleDialogTypeSet(FILTER_SINGLE_COMPONENT.cuisines)}
            variant={cuisinesSelected.length > 0 ? 'active' : undefined}
          />
          <Chip
            label="Time"
            endIconName="chevron-down"
            onPress={() => filtersSingleDialogTypeSet(FILTER_SINGLE_COMPONENT.time)}
          />
          <Chip
            label="Cost"
            endIconName="chevron-down"
            onPress={() => filtersSingleDialogTypeSet(FILTER_SINGLE_COMPONENT.costPerServing)}
          />
          <Box width={1} />
        </Row>
      </ScrollView>
    </Box>
  );
};

export default FiltersBar;
