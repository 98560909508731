import React from 'react';
import { Box, useTheme } from 'native-base';

import Body3 from './Body/Body3';

const InfoTip = ({ text }) => {
  const { colors } = useTheme();
  return (
    <Box bg={colors.almond[400]} borderRadius={48} textAlign="center" px={3} py={2}>
      <Body3>{text}</Body3>
    </Box>
  );
};

export default InfoTip;
