import { useCallback, useContext } from 'react';

import { ChefLiveContext } from '../contexts/ChefLiveContext';
import { IngredientContext } from '../contexts/IngredientContext';

const usePopulateRecipe = () => {
  const { find: findChef } = useContext(ChefLiveContext);
  const { find: findIngredient } = useContext(IngredientContext);

  const populateRecipe = useCallback(
    (r) => {
      if (!r) return null;
      return {
        ...r,
        chef: findChef(r.chefId),
        ingredientGroups: r.ingredientGroups.map((g) => ({
          ...g,
          list: g.list.map((i) => ({ ...findIngredient(i.id), ...i })),
        })),
      };
    },
    [findChef, findIngredient],
  );

  return populateRecipe;
};

export default usePopulateRecipe;
