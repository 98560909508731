import { useLinkTo } from '@react-navigation/native';
import { Button, useTheme } from 'native-base';

import FAProIcon from '../../FAProIcon';

const FacebookLoginButtonIos = (props) => {
  const linkTo = useLinkTo();
  const { colors } = useTheme();
  return (
    <Button
      variant="outline"
      bg="white"
      size="large"
      leftIcon={<FAProIcon name="facebook" color={colors.facebookBlue} />}
      onPress={() => linkTo('/login/facebook')}
      borderWidth="1px"
      {...props}
    >
      Continue with Facebook
    </Button>
  );
};

export default FacebookLoginButtonIos;
