/* eslint-disable no-restricted-globals */
import { useContext, useEffect } from 'react';
import { useLinkTo } from '@react-navigation/native';
import { Box, Text } from 'native-base';

import { UserContext } from '../../../contexts/UserContext';

const LoginFacebookScreenWeb = () => {
  const linkTo = useLinkTo();
  const params = new URLSearchParams(window.location.hash);
  const access_token = params.get('#access_token');

  const { loginFacebook } = useContext(UserContext);

  useEffect(() => {
    if (!access_token) return;
    const login = async () => {
      try {
        await loginFacebook(access_token);

        linkTo('/home');
      } catch (err) {
        linkTo('/login', { replace: true });
      }
    };
    void login();
  }, [loginFacebook, linkTo, access_token]);

  return (
    <Box>
      <Text>Logging in with facebook...</Text>
    </Box>
  );
};

export default LoginFacebookScreenWeb;
