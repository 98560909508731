import { Box, Button } from 'native-base';

import SubHeading2 from '../../SubHeading/SubHeading2';

const FilterSection = ({ label, clearFilter, boxProps = {}, children }) => (
  <Box
    width="100%"
    borderTopColor="#E3E3E3"
    borderTopStyle="solid"
    borderTopWidth="1px"
    paddingTop={4}
    paddingBottom={6}
    {...boxProps}
  >
    <Box flexDirection="row" justifyContent="space-between">
      {label && <SubHeading2 paddingY={3}>{label}</SubHeading2>}
      {clearFilter && (
        <Button size="small" variant="unstyled" onPress={clearFilter}>
          Clear
        </Button>
      )}
    </Box>
    {children}
  </Box>
);

export default FilterSection;
