import { useContext, useEffect, useRef } from 'react';
import { useIsFocused } from '@react-navigation/native';
import { Box } from 'native-base';

import ChefCardList from '../components/ChefCardList';
import Loading from '../components/Loading';
import { ChefLiveContext } from '../contexts/ChefLiveContext';

const ChefListScreen = () => {
  const { rows } = useContext(ChefLiveContext);

  const isFocused = useIsFocused();

  const showLoader = useRef(true);

  const showLoaderSet = (value) => {
    showLoader.current = value;
  };

  useEffect(() => {
    if (!showLoader.current) {
      showLoaderSet(true);
    }
  }, [isFocused]);

  if (!isFocused && showLoader.current) return <Loading />;

  const sortedRows = [...rows].sort((a, b) => (a.displayOrder > b.displayOrder ? 1 : -1));

  return (
    <Box height="100%" bg="white">
      <ChefCardList list={sortedRows} displayDiscoverInfoTip showLoaderSet={showLoaderSet} />
    </Box>
  );
};

export default ChefListScreen;
