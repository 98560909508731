import { useContext, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { UserContext } from '../contexts/UserContext';

const useOrdered = () => {
  const { profile, updateProfile, busy } = useContext(UserContext);

  const orders = useMemo(() => profile?.orders || [], [profile?.orders]);

  const legacyOrdered = useMemo(() => profile?.ordered || [], [profile?.ordered]);

  const markCooked = (orderId, recipeId) => {
    const list = orders.map((a) => {
      if (a.id === orderId) {
        a.recipes = a.recipes.map((r) => {
          if (r.id === recipeId) {
            r.isCooked = true;
          }
          return r;
        });
      }
      return a;
    });

    updateProfile({ orders: list });
  };

  const markUncooked = (orderId, recipeId) => {
    const list = orders.map((a) => {
      if (a.id === orderId) {
        a.recipes = a.recipes.map((r) => {
          if (r.id === recipeId) {
            r.isCooked = undefined;
          }
          return r;
        });
      }
      return a;
    });

    updateProfile({ orders: list });
  };

  const addOrder = async (l) => {
    const a = { id: uuidv4(), date: new Date().toISOString(), recipes: l };

    orders.unshift(a);

    await updateProfile({ orders: orders.slice(0, 12), cart: [] });
  };

  return { legacyOrdered, orders, busy, markCooked, markUncooked, addOrder };
};

export default useOrdered;
