import { useContext } from 'react';
import { useIsFocused, useLinkTo } from '@react-navigation/native';
import { Box, Button, FlatList, ScrollView } from 'native-base';

import CarouselCard from '../../components/CarouselCard';
import FAProIcon from '../../components/FAProIcon';
import Loading from '../../components/Loading';
import RecipeCard from '../../components/RecipeCard';
import SubHeading2 from '../../components/SubHeading/SubHeading2';
import { CommonContext } from '../../contexts/CommonContext';
import { FilterContext } from '../../contexts/FilterContext';
import { RecipeLiveContext } from '../../contexts/RecipeLiveContext';
import { sharedCssStyles } from '../../styles/utils';

const HomeScreen = () => {
  const { homePageV2, pillars } = useContext(CommonContext);
  const { find: findRecipe } = useContext(RecipeLiveContext);
  const { pillarSet } = useContext(FilterContext);
  const linkTo = useLinkTo();

  const isFocused = useIsFocused();
  if (!isFocused) return <Loading />;

  if (!homePageV2) return <Loading />;

  const { heroes, suggestions, thisWeekRecipes, popularRecipes, popularWithFamilies } = homePageV2;

  const getItemsInfoList = (items) => items.map((a) => findRecipe(a)).filter((a) => !!a);

  const goToPillar = (pillarId) => {
    pillarSet(pillarId);
    linkTo('/recipe');
  };

  return (
    <ScrollView flex={1} overScrollMode="never" removeClippedSubviews={true}>
      <Box
        flex={1}
        bg={{
          linearGradient: {
            colors: ['#EFDAC0', '#FBFAF3'],
            useAngle: true,
            angle: 180,
          },
        }}
        gap={12}
        pt={9}
        pb={'68px'}
      >
        <Box>
          <FlatList
            data={heroes}
            renderItem={(feature) => <CarouselCard item={{ ...feature.item, chef: feature.item.chefInfo }} />}
            keyExtractor={(feature) => feature.id}
            horizontal={true}
            _contentContainerStyle={sharedCssStyles.flatListContainer}
            ListHeaderComponent={() => <Box width={5} />}
            ListFooterComponent={() => <Box width={5} />}
            ItemSeparatorComponent={() => <Box width={4} />}
          />
        </Box>

        <Box display="flex" alignItems="center">
          <Box display="flex" flexDirection="column" gap={3} width="200">
            {pillars.map((p) => (
              <Button
                key={p.id}
                leftIcon={<FAProIcon name={p.icon} />}
                variant="outline"
                onPress={() => goToPillar(p.id)}
              >
                {p.displayTitle}
              </Button>
            ))}
          </Box>
        </Box>

        <Box>
          <Box width="100%" px={5} py={3} alignItems="flex-start">
            <SubHeading2>Plate Up x Amazon Fresh</SubHeading2>
          </Box>
          <FlatList
            data={getItemsInfoList(popularWithFamilies)}
            renderItem={(recipe) => <RecipeCard recipe={recipe.item} width={320} />}
            keyExtractor={(recipe) => recipe.id}
            horizontal={true}
            _contentContainerStyle={sharedCssStyles.flatListContainer}
            ListHeaderComponent={() => <Box width={4} />}
            ListFooterComponent={() => <Box width={4} />}
            ItemSeparatorComponent={() => <Box width={1} />}
          />
        </Box>

        <Box>
          <Box width="100%" px={5} py={3} alignItems="flex-start">
            <SubHeading2>New this month</SubHeading2>
          </Box>
          <FlatList
            data={getItemsInfoList(thisWeekRecipes)}
            renderItem={(recipe) => <RecipeCard recipe={recipe.item} width={320} />}
            keyExtractor={(recipe) => recipe.id}
            horizontal={true}
            _contentContainerStyle={sharedCssStyles.flatListContainer}
            ListHeaderComponent={() => <Box width={4} />}
            ListFooterComponent={() => <Box width={4} />}
            ItemSeparatorComponent={() => <Box width={1} />}
          />
        </Box>

        <Box>
          <Box width="100%" px={5} py={3} alignItems="flex-start">
            <SubHeading2>Most popular recipes</SubHeading2>
          </Box>
          <FlatList
            data={getItemsInfoList(popularRecipes)}
            renderItem={(recipe) => <RecipeCard recipe={recipe.item} width={320} />}
            keyExtractor={(recipe) => recipe.id}
            horizontal={true}
            _contentContainerStyle={sharedCssStyles.flatListContainer}
            ListHeaderComponent={() => <Box width={4} />}
            ListFooterComponent={() => <Box width={4} />}
            ItemSeparatorComponent={() => <Box width={1} />}
          />
        </Box>

        <Box>
          <Box width="100%" px={5} py={3} alignItems="flex-start">
            <SubHeading2>You may also like...</SubHeading2>
          </Box>
          <FlatList
            data={suggestions}
            renderItem={(suggestion) => <CarouselCard item={{ ...suggestion.item, chef: suggestion.item.chefInfo }} />}
            keyExtractor={(suggestion) => suggestion.id}
            horizontal={true}
            _contentContainerStyle={sharedCssStyles.flatListContainer}
            ListHeaderComponent={() => <Box width={5} />}
            ListFooterComponent={() => <Box width={5} />}
            ItemSeparatorComponent={() => <Box width={4} />}
          />
        </Box>
      </Box>
    </ScrollView>
  );
};

export default HomeScreen;
