import { useWindowDimensions } from 'react-native';
import { Box, Button, Column, ScrollView } from 'native-base';

import Body2Bold from '../../components/Body/Body2Bold';
import Heading3Bold from '../../components/Heading/Heading3Bold';

import OnboardingHeader from './OnboardingHeader';

const OnboardingScreenConsiderations = (props) => {
  const { onNext, onPrev, onSkip, data, dataSet } = props;
  const { width } = useWindowDimensions();

  const considerations = data?.considerations || [];

  const options = [
    'Explore new cuisines or chefs',
    'Find child friendly recipes',
    'Learn new cooking skills',
    'Save time on multiple food shops',
    'Save money on the food bill',
    'Reduce food & packaging waste',
  ].map((a) => {
    const selected = considerations.includes(a);
    return (
      <Button
        key={a}
        variant={selected ? 'accent' : 'outline'}
        width="100%"
        onPress={() => {
          const t = selected ? considerations.filter((b) => b !== a) : [...considerations, a];
          dataSet((d) => ({ ...d, considerations: t }));
        }}
        _text={{ fontSize: width > 400 ? 14 : 12 }}
      >
        {a}
      </Button>
    );
  });

  return (
    <ScrollView __contentContainer={{ alignItems: 'center', height: '100%' }} bg="white">
      <Box bg="white" height="100%" paddingY={6} paddingX={4}>
        <OnboardingHeader onPrev={onPrev} onSkip={onSkip} active={6} />

        <Box padding={4} alignItems="center" gap={6}>
          <Heading3Bold textAlign="center">Considerations for choosing meals</Heading3Bold>

          <Body2Bold textAlign="center" paddingBottom={4}>
            What's important to you when picking meals?
          </Body2Bold>

          <Box paddingX={4} paddingY={4} alignItems="center" width="100%">
            <Column alignItems="center" space={2} width="100%">
              {options}
            </Column>
          </Box>
        </Box>

        <Box flex={1} />

        {!!considerations.length && <Button onPress={onNext}>Next</Button>}
      </Box>
    </ScrollView>
  );
};

export default OnboardingScreenConsiderations;
