import * as BackgroundFetch from 'expo-background-fetch';
import * as TaskManager from 'expo-task-manager';

import user from '../api/user';

import { abandonedCartTask } from './abandoned-cart';
import { pushTask } from './push';
import { userRegistrationInit, userRegistrationTask } from './user-registration';

const TASK_NAME = 'background-fetch';
const INTERVAL = 30 * 60 * 1000;

let nextCheck = Date.now();

const log = (...args) => {
  console.log('background-task', ...args);
};

const getProfile = async () => {
  try {
    return await user.getProfile();
  } catch (e) {
    log(e);
  }
  return null;
};

const task = async () => {
  try {
    const now = Date.now();
    log(`--- Check task --- ${new Date(now).toISOString()}`);
    if (now < nextCheck) {
      log('Running task: Not time');
      return;
    }
    nextCheck += INTERVAL;

    const profile = await getProfile();
    if (!profile) {
      log('Running task: No profile');
      return;
    }

    log(`Running task: ${new Date(now).toISOString()}`);

    if (await pushTask(now, profile)) return;

    if (await abandonedCartTask(now, profile)) return;

    if (await userRegistrationTask(now, profile)) return;

    return BackgroundFetch.BackgroundFetchResult.NewData;
  } catch (e) {
    console.error(e);
    return BackgroundFetch.BackgroundFetchResult.Failed;
  }
};

const registerFetchTask = async () => {
  TaskManager.defineTask(TASK_NAME, task);

  const status = await BackgroundFetch.getStatusAsync();

  log('getStatusAsync', status);

  if (status !== 3) {
    log('Background execution is not allowed');
    return;
  }

  console.debug('Background execution allowed');

  let tasks = await TaskManager.getRegisteredTasksAsync();

  log('tasks', tasks);

  if (tasks.find((f) => f.taskName === TASK_NAME) != null) {
    log('UNRegistering task');
    await BackgroundFetch.unregisterTaskAsync(TASK_NAME);
  }

  log('Registering task');
  await BackgroundFetch.registerTaskAsync(TASK_NAME, {
    minimumInterval: INTERVAL / 1000,
    stopOnTerminate: false,
    startOnBoot: true,
  });

  tasks = await TaskManager.getRegisteredTasksAsync();
  log('Registered tasks', tasks);

  log('Setting interval to', INTERVAL);
  await BackgroundFetch.setMinimumIntervalAsync(INTERVAL);

  await userRegistrationInit();

  setInterval(task, INTERVAL);
  task();
};

setTimeout(registerFetchTask, 5000);
