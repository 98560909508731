import React, { useContext, useState } from 'react';
import { Linking } from 'react-native';
import { useLinkTo } from '@react-navigation/native';
import { Box, Button, ScrollView, useTheme } from 'native-base';

import Body2 from '../components/Body/Body2';
import Heading3Bold from '../components/Heading/Heading3Bold';
import RatingStars from '../components/RatingStars';
import TextField from '../components/TextField';
import TopNav2 from '../components/TopNav2';
import { CheckoutContext } from '../contexts/CheckoutContext';
import { CHECKOUT_EVENTS } from '../lib/tracking';
import { sharedCssStyles } from '../styles/utils';

const FeedbackScreen = ({ route }) => {
  const initialRating = route?.params?.rating || 0;

  const { colors } = useTheme();
  const linkTo = useLinkTo();

  // Prob shouldn't do this but sure
  const { retailer, trackCheckoutEvent } = useContext(CheckoutContext);

  const [rating, ratingSet] = useState(initialRating ?? 0);
  const [feedback, feedbackSet] = useState('');

  const SUPPORT_EMAIL = 'Hello@plateup.app';
  const SUBJECT = `Customer Feedback (${rating} stars)`;
  const EMAIL_BODY = `Hi Plate Up Team, \n \n ${feedback}`;

  const handleComplete = () => {
    trackCheckoutEvent(CHECKOUT_EVENTS.RateApp, {
      rating,
      message: feedback,
      retailer,
    });
    Linking.openURL(encodeURI(`mailto:${SUPPORT_EMAIL}?subject=${SUBJECT}&body=${EMAIL_BODY}`));
    linkTo('/home');
  };

  return (
    <ScrollView contentContainerStyle={sharedCssStyles.scrollViewContainer}>
      <Box background={colors.almond[200]} height="100%">
        <TopNav2 transparent back />

        <Box px={5} py={5} flex={1} justifyContent="space-between">
          <Box gap={4}>
            <Heading3Bold>Share your feedback</Heading3Bold>
            <Body2>
              Thanks for sending us your ideas, issues, or appreciation. We can't always respond individually, but your
              feedback will be passed on to the team who are working to make Plate Up better for everyone.
            </Body2>
            <RatingStars Container={Box} value={rating} valueSet={ratingSet} />
            <TextField
              value={feedback}
              onChange={(v) => feedbackSet(v)}
              height="165px"
              placeholder="Please share your experience"
            />
          </Box>

          <Button variant="solid" onPress={handleComplete}>
            Submit
          </Button>
        </Box>
      </Box>
    </ScrollView>
  );
};

export default FeedbackScreen;
