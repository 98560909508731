import { useLinkTo } from '@react-navigation/native';
import { Button } from 'native-base';

import FAProIcon from '../../FAProIcon';

const AppleLoginButtonAndroid = (props) => {
  const linkTo = useLinkTo();
  return (
    <Button
      variant="outline"
      bg="white"
      size="large"
      leftIcon={<FAProIcon name="apple" />}
      onPress={() => linkTo('/login/apple')}
      borderWidth="1px"
      {...props}
    >
      Continue with Apple
    </Button>
  );
};

export default AppleLoginButtonAndroid;
