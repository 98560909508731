import { useLinkTo } from '@react-navigation/native';
import { Button, Center, Column } from 'native-base';

import Body1 from './Body/Body1';

const NotLoggedIn = () => {
  const linkTo = useLinkTo();
  return (
    <Center flex={1} height="100%" bg="white">
      <Column space={4} justifyContent="center" alignItems="center">
        <Body1>Log in to start planning your meals.</Body1>
        <Button variant="subtle" onPress={() => linkTo('/login')}>
          Login
        </Button>
        <Body1>Don't have an account?</Body1>
        <Button variant="link" onPress={() => linkTo('/register')}>
          Sign up
        </Button>
      </Column>
    </Center>
  );
};

export default NotLoggedIn;
