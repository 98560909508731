import { useContext } from 'react';

import { GlobalContext } from '../contexts/GlobalContext';

const useExploreTabsOptions = () => {
  const { exploreTabsOptions, exploreTabsOptionsSet } = useContext(GlobalContext);

  const onScrollChange = (a) => {
    exploreTabsOptionsSet({ ...exploreTabsOptions, hideIcons: a });
  };

  return { ...exploreTabsOptions, onScrollChange };
};

export default useExploreTabsOptions;
