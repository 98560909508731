import { Input } from 'native-base';

const TextField = (props) => {
  const { value, disabled, placeholder, onChange, type = 'text' } = props;

  return (
    <Input
      value={value}
      isDisabled={disabled}
      placeholder={placeholder}
      onChangeText={onChange}
      type={type}
      variant="outline"
      width="100%"
      size="md"
      borderRadius="8px"
      borderWidth="1px"
      borderColor="#E3E3E3"
      bg="white"
      px="16px"
      py="17px"
      _focus={{
        borderColor: 'mustard.500',
        bg: 'brightNavy.100',
      }}
      _disabled={{
        placeholderTextColor: 'brightNavy.200',
        bg: 'brightNavy.100',
        borderWidth: '0',
        color: 'brightNavy.200',
      }}
      _web={{
        _focus: {
          outlineStyle: 'none',
        },
      }}
      {...props}
    />
  );
};

export default TextField;
