import React from 'react';
import { Linking } from 'react-native';
import { Box, Center, useTheme } from 'native-base';

import Body3 from './Body/Body3';
import Heading1 from './Heading/Heading1';
import Caption from './Caption';
import Chip from './Chip';
import CustomText from './CustomText';
import FAProIcon from './FAProIcon';
import SupermarketLogo from './SupermarketLogo';

const OrderTransferredCard = ({ retailer, totalRecipes = 0 }) => {
  const { colors } = useTheme();

  const handleSupport = async () => {
    await Linking.openURL(retailer?.supportUrl || 'https://plateup.app/contact');
  };

  const StatRow = ({ value, label }) => (
    <Center flex={1}>
      {value && (
        <CustomText textAlign="center" fontFamily="body" fontSize={16}>
          {value}
        </CustomText>
      )}
      {label && (
        <Caption textAlign="center" color={colors.mushroom[500]}>
          {label}
        </Caption>
      )}
    </Center>
  );

  return (
    <Box maxWidth="100%" width={353}>
      <Center zIndex={1}>
        <Center width={10} height={10} borderRadius={50} borderWidth={5} bg={colors.wasabi[500]} borderColor="white">
          <FAProIcon name="check" fontSize={24} color="white" />
        </Center>
      </Center>
      <Center width="100%" borderTopRadius={12} bg={colors.wasabi[500]} marginTop={-6} px={5} pt={12} pb={4} gap={3}>
        <Heading1 textAlign="center" px={12}>
          Thank you for your order
        </Heading1>
        <Chip variant="supermarket">{retailer && <SupermarketLogo retailer={retailer} />}</Chip>
        <Body3 textAlign="center" px={10}>
          Your order confirmation from {retailer?.displayName} has been sent to your email address.
        </Body3>
        <Box width="100%" flexDirection="row" pt={4}>
          <StatRow
          // value="£25.49"
          // label="Total amount"
          />
          <StatRow value={`x${totalRecipes}`} label="Recipes ordered" />
          <StatRow
          // value="11 Aug"
          // label="Delivery"
          />
        </Box>
      </Center>
      <Center width="100%" height={36} borderBottomRadius={12} bg="#DEF1E6" py={3} flexDirection="row" gap={1}>
        <CustomText
          textAlign="center"
          color={colors.mushroom[700]}
          fontFamily="subHeading"
          fontWeight={400}
          fontSize={9}
        >
          Need help with your order?
        </CustomText>
        <Caption textAlign="center" color={colors.mushroom[700]} onPress={handleSupport} underline>
          Contact {retailer?.displayName} support
        </Caption>
      </Center>
    </Box>
  );
};

export default OrderTransferredCard;
