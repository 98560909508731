const colors = {
  white: '#ffffff',
  black: '#000000',
  facebookBlue: '#4267B2',

  burntApricot: {
    700: '#ae573d',
    500: '#e87451',
    400: '#ef9e85',
    200: '#f9dcd4',
    100: '#fdf1ee',
  },
  brightNavy: {
    900: '#1F0F31',
    700: '#1F0F31',
    500: '#291441',
    400: '#695b7a',
    200: '#cac4d0',
    100: '#eae8ec',
  },
  almond: {
    700: '#b3a490',
    500: '#EFDAC0',
    400: '#f4e5d3',
    200: '#fbf6ef',
    100: '#fdfbf9',
  },
  mayo: {
    700: '#bcbcb6',
    500: '#fbfaf3',
  },
  wasabi: {
    700: '#90aea0',
    500: '#c0e8d5',
  },
  mustard: {
    700: '#b68c5b',
    500: '#f2bb79',
  },
  cranberry: {
    700: '#5E2044',
    500: '#7D2A5A',
  },
  lime: {
    700: '#afb077',
    500: '#e9eb9e',
  },
  relish: {
    700: '#5C3232',
    500: '#7a4242',
  },
  blueCheese: {
    700: '#8aa0b0',
    500: '#b8d5ea',
  },
  pesto: {
    700: '#3b453d',
    500: '#4f5c51',
  },
  mint: {
    700: '#4d8f80',
    500: '#66bfab',
  },
  ketchup: {
    700: '#751717',
    500: '#9c1f1f',
  },
  mushroom: {
    700: '#434241',
    500: '#595857',
  },
};

colors.text = colors.brightNavy;
colors.primary = colors.brightNavy;

export default colors;
