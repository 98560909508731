import React from 'react';
import { Box, Button } from 'native-base';

import BottomDrawer from './BottomDrawer';

const CheckoutWebviewBottom = ({ isOpen, onClose }) => (
  <BottomDrawer disableOverlay isOpen={isOpen} pb={6}>
    <Box width="100%">
      <Button onPress={onClose} variant="accent">
        All done, back to Plate Up
      </Button>
    </Box>
  </BottomDrawer>
);

export default CheckoutWebviewBottom;
