import React, { useCallback, useContext, useMemo, useState } from 'react';

import useOrdered from '../hooks/useOrdered';
import api from '../lib/api';
import tracking from '../lib/tracking';

import { QuoteContext } from './QuoteContext';
import { RecipeLiveContext } from './RecipeLiveContext';
import { UserContext } from './UserContext';

const CheckoutContext = React.createContext({});

const CheckoutProvider = (props) => {
  const { children } = props;

  const { mapIds } = useContext(RecipeLiveContext);
  const { profile } = useContext(UserContext);
  const { addOrder } = useOrdered();

  const [cart, cartSet] = useState([]);
  const [retailer, retailerSet] = useState();
  const [products, productsSet] = useState([]);

  const quoteCtx = useContext(QuoteContext);

  const checkoutDone = async () => {
    if (!profile) return;

    addOrder(profile.cart);
  };

  const checkoutBasic = async (username, password) => {
    const credentials = { basic: { username, password } };
    const payload = {
      credentials,
      products: quoteCtx.retailerProducts[quoteCtx.retailer.id].filter((a) => a.quantity),
      retailer: quoteCtx.retailer,
    };
    return await api.checkout.post(payload);
  };

  const checkoutOauth = async (token) => {
    const credentials = { oauth: { token } };
    const payload = {
      credentials,
      products: quoteCtx.retailerProducts[quoteCtx.retailer.id].filter((a) => a.quantity),
      retailer: quoteCtx.retailer,
    };
    return await api.checkout.post(payload);
  };

  const checkoutLanding = async () => {
    const payload = {
      products: quoteCtx.retailerProducts[quoteCtx.retailer.id].filter((a) => a.quantity),
      retailer: quoteCtx.retailer,
    };
    return await api.checkout.post(payload);
  };

  const checkoutAmazonFresh = async (payload) => await api.checkout.postAmazonFresh(payload);

  const recipes = useMemo(() => mapIds(cart.map((a) => a.id)), [cart, mapIds]);

  const setTrackedCheckoutItems = (c, p, r) => {
    cartSet(c);
    productsSet(p);
    retailerSet(r);
  };

  const trackCheckoutProps = useMemo(
    () => ({
      recipes: cart?.map((r) => r.id) || [],
      retailer,
      products: products || [],
      total: quoteCtx?.retailerTotals?.[retailer?.id],
    }),
    [cart, retailer, products, quoteCtx.retailerTotals],
  );

  const trackContinueToSupermarket = (c, p, r) => {
    tracking.trackContinueToSupermarket(
      c.map((a) => a.id),
      r,
      p,
      quoteCtx?.retailerTotals?.[r?.id],
    );
  };

  const trackCheckoutScreen = useCallback(
    (name, path) =>
      tracking.trackScreen({
        name,
        path,
        ...trackCheckoutProps,
      }),
    [trackCheckoutProps],
  );

  const trackCheckoutEvent = useCallback(
    (eventName, extraProps = {}) => tracking.trackEvent(eventName, { ...trackCheckoutProps, ...extraProps }),
    [trackCheckoutProps],
  );

  const value = {
    checkoutLanding,
    checkoutOauth,
    checkoutBasic,
    checkoutDone,
    checkoutAmazonFresh,
    cart,
    recipes,
    retailer,
    products,
    setTrackedCheckoutItems,
    trackContinueToSupermarket,
    trackCheckoutScreen,
    trackCheckoutEvent,
  };

  return <CheckoutContext.Provider value={value}>{children}</CheckoutContext.Provider>;
};

export { CheckoutContext, CheckoutProvider };
