import React from 'react';
import { Center } from 'native-base';

const MurkyGradient = ({ color1 = '#67400500', color2 = '#452C0585', angle = 180, children, ...props }) => (
  <Center
    width="100%"
    bg={{
      linearGradient: {
        colors: [color1, color2],
        useAngle: true,
        angle,
      },
    }}
    {...props}
  >
    {children}
  </Center>
);

export default MurkyGradient;
