import React, { useCallback, useMemo } from 'react';

import useCrudTables from '../hooks/useCrudTables';
import usePopulateRecipe from '../hooks/usePopulateRecipe';
import api from '../lib/api';

const RecipeLiveContext = React.createContext({});

const RecipeLiveProvider = (props) => {
  const { children } = props;
  const populateRecipe = usePopulateRecipe();

  const {
    loading,
    rows,
    list,
    get,
    post,
    put,
    del,
    copyLiveToDraft,
    moveDraftToLive,
    moveDraftToArchive,
    moveLiveToArchive,
    moveArchiveToDraft,
    moveArchiveToLive,
  } = useCrudTables('live', api.recipe);

  const recipes = useMemo(() => rows.map(populateRecipe), [populateRecipe, rows]);

  const find = useCallback((id) => recipes.find((a) => a.id === id), [recipes]);

  const mapIds = useCallback((l) => l.map((a) => recipes.find((b) => b.id === a)).filter((a) => !!a), [recipes]);

  const value = {
    recipes,
    loading,
    list,
    get,
    post,
    put,
    del,
    copyLiveToDraft,
    moveDraftToLive,
    moveDraftToArchive,
    moveLiveToArchive,
    moveArchiveToDraft,
    moveArchiveToLive,
    find,
    mapIds,
  };

  return <RecipeLiveContext.Provider value={value}>{children}</RecipeLiveContext.Provider>;
};

export { RecipeLiveContext, RecipeLiveProvider };
