import { useContext, useState } from 'react';
import { Box, Button, Column, ScrollView } from 'native-base';

import Body2Bold from '../../components/Body/Body2Bold';
import DiscountBox from '../../components/DiscountBox';
import Heading3Bold from '../../components/Heading/Heading3Bold';
import SupermarketLogo from '../../components/SupermarketLogo';
import { CommonContext } from '../../contexts/CommonContext';

import OnboardingHeader from './OnboardingHeader';

const OnboardingScreenHabits = (props) => {
  const { onPrev, onSkip, onFinish, data, dataSet } = props;

  const { retailers } = useContext(CommonContext);

  const [isDiscountOpen, isDiscountOpenSet] = useState(false);
  const [currentRetailer, currentRetailerSet] = useState();

  const handleDiscountNext = () => {
    isDiscountOpenSet(false);
    onFinish();
  }

  const options = retailers.map((a) => {
    const selected = data.retailer === a.id;
    return (
      <Button
        key={a.id}
        variant={selected ? 'accent' : 'outline'}
        width="100%"
        onPress={() => {
          dataSet((d) => ({ ...d, retailer: a.id }));
          a.showDiscount && isDiscountOpenSet(a.showDiscount);
          currentRetailerSet(a);
        }}
      >
        <SupermarketLogo retailer={a} width="100px" height="18px" />
      </Button>
    );
  });

  return (
    <ScrollView __contentContainer={{ alignItems: 'center', height: '100%' }} bg="white">
      <Box bg="white" height="100%" paddingY={6} paddingX={4}>
        <OnboardingHeader onPrev={onPrev} onSkip={onSkip} active={7} />

        <Box padding={4} alignItems="center" gap={6}>
          <Heading3Bold textAlign="center">Shopping habits</Heading3Bold>

          <Body2Bold textAlign="center" paddingBottom={8}>
            Where do you usually buy your food shop?
          </Body2Bold>

          <Box paddingX={8} paddingY={4} alignItems="center" width="100%">
            <Column alignItems="center" space={2} width="100%">
              {options}
            </Column>
          </Box>
        </Box>

        <Box flex={1} />

        {!!data.retailer && <Button onPress={onFinish}>Next</Button>}
        {!!currentRetailer && (
          <DiscountBox
            isOpen={isDiscountOpen}
            onClose={() => isDiscountOpenSet(false)}
            retailer={currentRetailer}
            onPressNext={handleDiscountNext}
          />
        )}
      </Box>
    </ScrollView>
  );
};

export default OnboardingScreenHabits;
