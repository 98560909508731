import { Heading } from 'native-base';

import fontSize from '../styles/fontSize';

const Caption = (props) => (
  <Heading fontFamily="subHeading" fontWeight={700} fontSize={fontSize(9)} {...props}>
    {props.children}
  </Heading>
);

export default Caption;
