import { useContext, useEffect } from 'react';
import { Animated, Easing } from 'react-native';
import { Box, Row } from 'native-base';

import Body2Bold from '../../../components/Body/Body2Bold';
import Heading3Bold from '../../../components/Heading/Heading3Bold';
import ImageWrapper from '../../../components/ImageWrapper';
import SvgImageOverlayBottomCurve from '../../../components/SvgImageOverlayBottomCurve';
import { CommonContext } from '../../../contexts/CommonContext';
import { RecipeLiveContext } from '../../../contexts/RecipeLiveContext';
import { isMobileApp } from '../../../lib/util';

import PricesTile from './PricesTile';
import RecipeTile from './RecipeTile';

const ShowcaseSlide = ({ data }) => {
  const { recipeId, costs, chefImage, titleWord } = data;
  const { find } = useContext(RecipeLiveContext);
  const recipe = find(recipeId);
  const { retailers } = useContext(CommonContext);

  const animationImage = new Animated.Value(0);
  const animationRecipeMove = new Animated.Value(0);
  const animationRecipeFade = new Animated.Value(0);
  const animationPricesMove = new Animated.Value(0);
  const animationPricesFade = new Animated.Value(0);

  useEffect(() => {
    Animated.timing(animationImage, {
      toValue: 70,
      duration: 450,
      easing: Easing.linear,
      useNativeDriver: true,
    }).start();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recipeId]);

  useEffect(() => {
    Animated.sequence([
      Animated.parallel([
        Animated.timing(animationRecipeFade, {
          toValue: 1,
          duration: 200,
          easing: Easing.linear,
          useNativeDriver: true,
        }),
        Animated.timing(animationRecipeMove, {
          toValue: 45,
          duration: 500,
          easing: Easing.linear,
          useNativeDriver: true,
        }),
      ]),
      Animated.delay(2400),
      Animated.parallel([
        Animated.timing(animationRecipeFade, {
          toValue: 0,
          duration: 300,
          easing: Easing.linear,
          useNativeDriver: true,
        }),
        Animated.timing(animationRecipeMove, {
          toValue: 200,
          duration: 200,
          easing: Easing.linear,
          useNativeDriver: true,
        }),
      ]),
    ]).start();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recipeId]);

  useEffect(() => {
    Animated.sequence([
      Animated.parallel([
        Animated.timing(animationPricesFade, {
          toValue: 1,
          duration: 200,
          easing: Easing.linear,
          useNativeDriver: true,
        }),
        Animated.timing(animationPricesMove, {
          toValue: 20,
          duration: 500,
          easing: Easing.linear,
          useNativeDriver: true,
        }),
      ]),
      Animated.delay(2400),
      Animated.parallel([
        Animated.timing(animationPricesFade, {
          toValue: 0,
          duration: 300,
          easing: Easing.linear,
          useNativeDriver: true,
        }),
        Animated.timing(animationPricesMove, {
          toValue: 200,
          duration: 200,
          easing: Easing.linear,
          useNativeDriver: true,
        }),
      ]),
    ]).start();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recipeId]);

  const transformStyleImage = {
    transform: [
      {
        translateX: animationImage,
      },
    ],
  };

  const transformStyleRecipe = {
    transform: [
      {
        translateX: animationRecipeMove,
      },
    ],
    opacity: animationRecipeFade,
  };

  const transformStylePrices = {
    transform: [
      {
        translateX: animationPricesMove,
      },
    ],
    opacity: animationPricesFade,
  };

  if (!recipe) return null;

  return (
    <>
      <Box
        position="relative"
        width="100%"
        maxWidth={600}
        height={isMobileApp ? '50%' : '45vh'}
        maxHeight={500}
        display="flex"
        alignItems="center"
        alignSelf="center"
      >
        <Box position="absolute" bottom={-10} height="60px" width="100%" zIndex={1}>
          <SvgImageOverlayBottomCurve />
        </Box>

        <Animated.View width="152%" style={transformStyleImage}>
          <ImageWrapper alt="" width="100%" height="100%" src={chefImage} fadeDuration={0} />
        </Animated.View>

        <Animated.View width="100%" style={transformStyleRecipe} zIndex={2}>
          <RecipeTile recipe={recipe} />
        </Animated.View>
        <Animated.View width="100%" style={transformStylePrices} zIndex={2}>
          <PricesTile costs={costs} retailers={retailers} />
        </Animated.View>
      </Box>

      <Box flex={1} py={2} px={4} gap="4" alignItems="center" width="100%">
        <Box>
          <Heading3Bold textAlign="center">Grocery Shopping</Heading3Bold>
          <Row justifyContent="center">
            <Heading3Bold textAlign="center">but way </Heading3Bold>
            <Heading3Bold textAlign="center" color="#E87451">
              {titleWord}
            </Heading3Bold>
          </Row>
        </Box>

        <Body2Bold textAlign="center">
          Delivered along with the rest of your shopping by your favourite supermarket
        </Body2Bold>

        <Box flex={1} />
      </Box>
    </>
  );
};

export default ShowcaseSlide;
