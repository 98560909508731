import colors from '../base/colors';

const primary = colors.brightNavy[700];
const hover = colors.brightNavy[400];

const Button = {
  defaultProps: {
    variant: 'solid',
    size: 'medium',
  },

  baseStyle: {
    flexDirection: 'row',
    justifyContent: 'center',

    _stack: {
      space: '12px',
      alignItems: 'center',
    },

    borderRadius: '34px',
    _text: {
      fontFamily: 'body',
      fontWeight: 700,
      fontSize: 14,
      color: primary,
    },
    _icon: {
      color: primary,
    },
    _hover: {
      borderColor: hover,
      _text: {
        color: hover,
      },
      _icon: {
        color: hover,
      },
    },
    _pressed: {
      borderColor: hover,
      _text: {
        color: hover,
      },
      _icon: {
        color: hover,
      },
    },
  },

  variants: {
    solid: {
      bg: primary,
      _text: {
        color: 'white',
      },
      _icon: {
        color: 'white',
      },
      _hover: {
        bg: hover,
      },
      _pressed: {
        bg: hover,
      },
      _disabled: {
        bg: `${primary}33`,
      },
    },
    outline: {
      borderWidth: '2px',
      borderColor: primary,
    },
    subtle: {
      bg: 'almond.400',
    },
    ghost: {
      bg: 'white',
      _hover: {
        bg: 'mayo.500',
      },
    },
    link: {
      _text: {
        textDecorationLine: 'underline',
      },
    },
    accent: {
      bg: 'mustard.500',
    },
    success: {
      bg: 'mint.500',
    },
  },

  sizes: {
    fullWidth: {
      paddingLeft: '12px',
      paddingRight: '12px',
      width: '100%',
      height: '48px',
      _icon: {
        size: 'large',
      },
    },
    large: {
      paddingLeft: '24px',
      paddingRight: '24px',
      height: '48px',
      _icon: {
        size: '24px',
      },
    },
    medium: {
      paddingLeft: '24px',
      paddingRight: '24px',
      height: '40px',
      _icon: {
        size: '16px',
      },
    },
    small: {
      paddingLeft: '24px',
      paddingRight: '24px',
      height: '36px',
      _icon: {
        size: '16px',
      },
    },
  },
};

export default Button;
