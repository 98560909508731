import * as BrandIcons from '@fortawesome/free-brands-svg-icons';
import * as RegularIcons from '@fortawesome/pro-regular-svg-icons';
import * as SolidIcons from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';

const FAProIcon = (props) => {
  const { color, name, size = 16, solid } = props;

  if (!name) {
    console.warn('FAProIcon: name is required');
    return null;
  }

  const camelize = (s) => `fa-${s}`.replace(/-./g, (x) => x[1].toUpperCase());

  const n = camelize(name);

  let icon = RegularIcons[n];
  if (solid) icon = SolidIcons[n];
  if (BrandIcons[n]) icon = BrandIcons[n];

  if (!icon) {
    console.warn('FAProIcon: icon not found', name);
    return null;
  }

  return <FontAwesomeIcon icon={icon} size={parseInt(size)} color={color} />;
};

export default FAProIcon;
