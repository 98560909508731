import React from 'react';
import { Box } from 'native-base';

import Placeholder from '../Placeholder';
import PlaceholderText from '../PlaceholderText';

const PlaceholderListIngredient = () => (
  <Box gap={2} flexDirection="row" px={5} py={2} alignItems="center" justifyContent="space-between">
    <Placeholder width="64px" height="64px" padding={2} borderRadius="12px" />
    <Box gap={1} flexDirection="column">
      <PlaceholderText width="123px" height="12px" />
      <PlaceholderText width="100px" height="12px" />
    </Box>
    <Placeholder width="90px" height="48px" padding={2} borderRadius="12px" />
  </Box>
);

const PlaceholderListIngredients = () => (
  <Box gap={6}>
    <PlaceholderListIngredient />
    <PlaceholderListIngredient />
    <PlaceholderListIngredient />
  </Box>
);

export default PlaceholderListIngredients;
