import { useContext, useState } from 'react';
import { Box, Button, Column, Row, ScrollView } from 'native-base';

import Body2Bold from '../../components/Body/Body2Bold';
import Heading3Bold from '../../components/Heading/Heading3Bold';
import SubHeading1 from '../../components/SubHeading/SubHeading1';
import { TagContext } from '../../contexts/TagContext';

import OnboardingHeader from './OnboardingHeader';

const OnboardingScreenDietary = (props) => {
  const { onNext, onPrev, onSkip, data, dataSet } = props;
  const { avoidDiets } = useContext(TagContext);
  const [showOpts, showOptsSet] = useState(null);

  const tags = data?.tags || [];

  const dietList = avoidDiets.map((a) => {
    const { id, name } = a;
    const selected = tags.includes(id);
    return (
      <Button
        key={id}
        variant={selected ? 'accent' : 'outline'}
        width="100%"
        onPress={() => {
          const t = selected ? tags.filter((b) => b !== id) : [...tags, id];
          dataSet((d) => ({ ...d, tags: t }));
        }}
      >
        {name}
      </Button>
    );
  });

  return (
    <ScrollView __contentContainer={{ alignItems: 'center', height: '100%' }} bg="white">
      <Box bg="white" height="100%" paddingY={6} paddingX={4}>
        <OnboardingHeader onPrev={onPrev} onSkip={onSkip} active={5} />

        <Box padding={10} alignItems="center" gap={6}>
          <Heading3Bold textAlign="center">Your dietary requirements</Heading3Bold>

          <Body2Bold textAlign="center" paddingBottom={8}>
            We'll use this to help recommend appropriate recipes for you.
          </Body2Bold>

          <Box paddingX={8} paddingY={4} alignItems="center" width="100%">
            <SubHeading1 textAlign="center">Does anyone follow any diets?</SubHeading1>
            <Row paddingY={4} alignItems="center" space={4}>
              <Button
                variant={showOpts === true ? 'accent' : 'outline'}
                flex={1}
                size="large"
                onPress={() => showOptsSet(true)}
              >
                Yes
              </Button>
              <Button
                variant={showOpts === false ? 'accent' : 'outline'}
                flex={1}
                size="large"
                onPress={() => showOptsSet(false)}
              >
                No
              </Button>
            </Row>

            {showOpts && (
              <Column alignItems="center" space={2} width="100%">
                {dietList}
              </Column>
            )}
          </Box>
        </Box>

        <Box flex={1} />

        {showOpts !== null && <Button onPress={onNext}>Next</Button>}
      </Box>
    </ScrollView>
  );
};

export default OnboardingScreenDietary;
