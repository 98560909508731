/* eslint-disable no-restricted-globals */
import { Dimensions } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Constants from 'expo-constants';
import { parseInitialURLAsync } from 'expo-linking';
import { requestTrackingPermissionsAsync } from 'expo-tracking-transparency';
import * as Updates from 'expo-updates';

import api from './api';
import { isAndroidApp, isIOSApp, isMobileApp } from './util';

let branchTags = {};
let appsflyerTags = {};
let anonymousId = null;

const uuid = () =>
  'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });

const loadAnonId = async () => {
  anonymousId = await AsyncStorage.getItem('anonId');
  if (!anonymousId) {
    anonymousId = uuid();
    await AsyncStorage.setItem('anonId', anonymousId);
  }
};

const trackEvent = async (event, p) => {
  let platform = navigator.platform;
  if (isIOSApp()) {
    platform = 'ios-app';
  } else if (isAndroidApp()) {
    platform = 'android-app';
  }
  const actionSource = isMobileApp ? 'app' : 'website';
  const properties = { ...p, ...appsflyerTags, ...branchTags, actionSource, platform };
  if (event === 'screen') {
    api.tracking.post({ anonymousId, type: event, name: p.name, properties });
  } else if (event === 'page') {
    api.tracking.post({ anonymousId, type: event, event, properties });
  } else if (event === 'identify') {
    api.tracking.post({ anonymousId, type: event, event, traits: properties });
  } else {
    api.tracking.post({ anonymousId, type: 'track', event, properties });
  }
};

const trackPlatform = () => {
  const { height, width } = Dimensions.get('window');

  const properties = {
    userAgent: navigator.userAgent,
    width,
    height,
    version: Constants?.expoConfig?.extra?.commitHash ?? 'dev',
    runtimeVersion: Updates.runtimeVersion || '0.0.0',
  };

  trackEvent('Platform', properties);
};
export const parseBranchTags = async (queryParams) => {
  const TAGS = ['utm_source', 'utm_campaign', 'utm_medium'];
  let urlBranchTags = {};

  if (queryParams) {
    urlBranchTags = Object.entries(queryParams).reduce((a, [k, v]) => {
      if (!TAGS.includes(k)) return a;
      return { ...a, [k]: v };
    }, {});
  }

  branchTags = { ...branchTags, ...urlBranchTags };

  trackEvent('Branch tags', branchTags);
  AsyncStorage.setItem('branchTags', JSON.stringify(branchTags));
};

const loadBranchTags = async () => {
  const res = await AsyncStorage.getItem('branchTags');
  try {
    branchTags = JSON.parse(res || '{}');
  } catch (e) {
    console.error(e);
  }

  const initialURL = await parseInitialURLAsync();
  parseBranchTags(initialURL?.queryParams);
};

export const parseAppsflyerTags = async (queryParams) => {
  const TAGS = ['media_source', 'campaign', 'shortlink', 'deep_link_value'];
  let urlAppsflyerTags = {};

  if (queryParams) {
    urlAppsflyerTags = Object.entries(queryParams).reduce((a, [k, v]) => {
      if (!TAGS.includes(k)) return a;
      return { ...a, [k]: v };
    }, {});
  }

  appsflyerTags = { ...appsflyerTags, ...urlAppsflyerTags };

  trackEvent('Appsflyer tags', appsflyerTags);
  AsyncStorage.setItem('appsflyerTags', JSON.stringify(appsflyerTags));

  console.log('appsflyerTags', appsflyerTags);
};

const loadAppsflyerTags = async () => {
  const res = await AsyncStorage.getItem('appsflyerTags');
  try {
    appsflyerTags = JSON.parse(res || '{}');
  } catch (e) {
    console.error(e);
  }
  const initialURL = await parseInitialURLAsync();
  parseAppsflyerTags(initialURL?.queryParams);
};

const load = async () => {
  await loadAnonId();

  if (isIOSApp()) {
    const { status } = await requestTrackingPermissionsAsync();
    if (status === 'granted') {
      console.log('have user permission to track data');
    } else {
      console.log('NOT have user permission to track data');
    }
  }

  trackPlatform();

  loadBranchTags();

  loadAppsflyerTags();
};
load();

export default trackEvent;
