import React from 'react';
import { Box, Center, useTheme } from 'native-base';

import Body3Bold from './Body/Body3Bold';

const ErrorInfoTip = ({ text }) => {
  const { colors } = useTheme();

  if (!text) return undefined;

  return (
    <Center>
      <Box bg={colors.ketchup[500]} borderRadius={48} px={3} py={2}>
        <Body3Bold color={colors.white} textAlign="center">
          {text}
        </Body3Bold>
      </Box>
    </Center>
  );
};

export default ErrorInfoTip;
