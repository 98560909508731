import AsyncStorage from '@react-native-async-storage/async-storage';

import { showNotification } from '../notifications';

const taskKey = 'notify-user-registration';
const doneKey = `${taskKey}-done`;
const delay = 48 * 60 * 60 * 1000;

const log = (...args) => {
  console.log(taskKey, ...args);
};

export const userRegistrationTrigger = async () => {
  log(`userRegistrationTrigger`);
  await AsyncStorage.setItem(taskKey, `${Date.now()}`);
};

export const userRegistrationDone = async () => {
  log(`userRegistrationDone`);
  await AsyncStorage.setItem(doneKey, 'true');
};

export const userRegistrationReset = async () => {
  log(`userRegistrationReset`);
  await AsyncStorage.removeItem(taskKey);
  await AsyncStorage.removeItem(doneKey);
};

export const userRegistrationInit = async () => {
  log(`userRegistrationInit`);
  const trigger = Number(await AsyncStorage.getItem(taskKey)) || 0;
  if (trigger) await userRegistrationDone();
};

export const userRegistrationTask = async (now, profile) => {
  log(`Check ${taskKey}`);
  const trigger = Number(await AsyncStorage.getItem(taskKey)) || 0;
  const done = await AsyncStorage.getItem(doneKey);

  if (done) {
    log(`if (done) {`);
    return false;
  }

  if (!trigger) {
    log(`if (!trigger) {`);
    return false;
  }

  if (now < trigger + delay) {
    log(`if (now < trigger + delay) {`);
    return false;
  }

  if (!profile) {
    log(`if (!profile) {`);
    return false;
  }

  log(`show ${taskKey}`);
  await showNotification(
    'Hello! 👋',
    'Welcome to Plate Up! Discover hundreds of delicious recipes and get ingredients delivered to your door.',
    '/',
  );

  await userRegistrationDone();

  return true;
};
