import { useLinkTo } from '@react-navigation/native';
import { Box, Button, useTheme } from 'native-base';

import Body3 from '../../../components/Body/Body3';
import Body3Bold from '../../../components/Body/Body3Bold';
import LoginContainer from '../components/LoginContainer';

const ResetPasswordEmailSentScreen = () => {
  const linkTo = useLinkTo();
  const { colors } = useTheme();

  return (
    <LoginContainer title="Reset Password" subtitle="We've sent you an email to allow you to reset your password">
      <Box py={5}>
        <Body3Bold color={colors.brightNavy[500]}>
          Please check your email and click the link to reset your password
        </Body3Bold>
        <Body3 color={colors.brightNavy[500]}></Body3>
        <Body3Bold color={colors.brightNavy[500]}>Your new password must:</Body3Bold>
        <Body3 color={colors.brightNavy[500]}>• Contain at least 6 characters</Body3>
        <Body3 color={colors.brightNavy[500]}>• Contain at least one number</Body3>
        <Body3 color={colors.brightNavy[500]}>• Contain at least one letter</Body3>
      </Box>
      <Button onPress={() => linkTo('/login')} width="100%">
        Continue
      </Button>
    </LoginContainer>
  );
};

export default ResetPasswordEmailSentScreen;
