import React from 'react';

import useCrud from '../hooks/useCrud';
import api from '../lib/api';
import { byTagOrdering } from '../lib/util';

const TagContext = React.createContext({});

const TagProvider = (props) => {
  const { children } = props;

  const { loading, rows, list, get, post, put, del } = useCrud(api.tag);

  const cuisines = rows.filter((a) => a.group === 'cuisine').sort(byTagOrdering);
  const avoidances = rows.filter((a) => a.group === 'avoidance').sort(byTagOrdering);
  const avoidDiets = rows.filter((a) => a.group === 'avoid-diet').sort(byTagOrdering);
  const mealTypes = rows.filter((a) => a.group === 'meal-type').sort(byTagOrdering);
  const difficulties = rows.filter((a) => a.group === 'difficulty').sort(byTagOrdering);
  const additionalInfo = rows.filter((a) => a.group === 'additional-info').sort(byTagOrdering);

  const value = {
    loading,
    rows,
    list,
    get,
    post,
    put,
    del,
    cuisines,
    avoidances,
    avoidDiets,
    mealTypes,
    difficulties,
    additionalInfo,
  };

  return <TagContext.Provider value={value}>{children}</TagContext.Provider>;
};

export { TagContext, TagProvider };
