import React from 'react';

import useCrud from '../hooks/useCrud';
import api from '../lib/api';

const CommonContext = React.createContext({});

const pillarRecipesByDate = (a) => {
  if (!a.startDate && !a.endDate) return true;

  const now = Date.now();

  if (a.startDate) {
    const startDate = new Date(a.startDate).getTime();
    if (startDate > now) return false;
  }

  if (a.endDate) {
    const endDate = new Date(a.endDate).getTime();
    if (now > endDate) return false;
  }

  return true;
};

const CommonProvider = (props) => {
  const { children } = props;

  const { loading, rows, list, get, post, put, del } = useCrud(api.common);

  const homePage = rows.find((a) => a.id === 'home-page');
  const homePageV2 = rows.find((a) => a.id === 'home-page-v2');
  const pillarQuickEats = rows.find((a) => a.id === 'pillar-quick-eats') || { recipes: [] };
  const pillarMakeAhead = rows.find((a) => a.id === 'pillar-make-ahead') || { recipes: [] };
  const pillarSlowerCooks = rows.find((a) => a.id === 'pillar-slower-cooks') || { recipes: [] };
  const retailers = rows.find((a) => a.id === 'retailers')?.list || [];
  const nutrients = rows.find((a) => a.id === 'nutrients') || {};

  pillarQuickEats.recipes = pillarQuickEats.recipes.filter(pillarRecipesByDate);
  pillarMakeAhead.recipes = pillarMakeAhead.recipes.filter(pillarRecipesByDate);
  pillarSlowerCooks.recipes = pillarSlowerCooks.recipes.filter(pillarRecipesByDate);

  pillarQuickEats.displayTitle ??= 'Quick Meals';
  pillarMakeAhead.displayTitle ??= 'Make Ahead';
  pillarSlowerCooks.displayTitle ??= 'Family Favourites';

  pillarQuickEats.icon ??= 'bolt';
  pillarMakeAhead.icon ??= 'clock';
  pillarSlowerCooks.icon ??= 'family';

  const pillars = [pillarQuickEats, pillarMakeAhead, pillarSlowerCooks];

  const value = {
    loading,
    rows,
    list,
    get,
    post,
    put,
    del,
    homePage,
    homePageV2,
    retailers,
    pillars,
    pillarQuickEats,
    pillarMakeAhead,
    pillarSlowerCooks,
    nutrients,
  };

  return <CommonContext.Provider value={value}>{children}</CommonContext.Provider>;
};

export { CommonContext, CommonProvider };
