// Actionsheet
export const ActionsheetContent = {
  baseStyle: () => ({
    bg: 'white',
    _web: {
      style: {
        boxShadow: '0px -4px 10px rgba(0, 0, 0, 0.10)',
      },
    },
  }),
};
