import { Platform, StyleSheet } from 'react-native';
import { Actionsheet } from 'native-base';

const styles = StyleSheet.create({
  actionSheet: {
    ...Platform.select({
      ios: {
        shadowColor: 'black',
        shadowOffset: { width: 0, height: -4 },
        shadowOpacity: 0.1,
        shadowRadius: 3,
      },
      android: {
        elevation: 5,
      },
    }),
  },
});

const BottomDrawer = (props) => {
  const { isOpen, onClose, disableOverlay, children, pt = '22px', pb = '6px', px = '21px' } = props;
  return (
    <Actionsheet
      isOpen={isOpen}
      onClose={onClose}
      hideDragIndicator={true}
      disableOverlay={disableOverlay}
      _backdrop={{ bg: '#33230B', opacity: 0.8 }}
      style={styles.actionSheet}
    >
      <Actionsheet.Content px={px} pt={pt} pb={pb} borderColor="#E3E3E3" borderWidth="1">
        {children}
      </Actionsheet.Content>
    </Actionsheet>
  );
};

export default BottomDrawer;
