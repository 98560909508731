import { useContext, useEffect, useState } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useLinkTo } from '@react-navigation/native';

import { UserContext } from '../../contexts/UserContext';
import tracking from '../../lib/tracking';

import OnboardingScreenGetStarted from './OnboardingScreenGetStarted/OnboardingScreenGetStarted';
import OnboardingScreenAnimation1 from './OnboardingScreenAnimation1';
import OnboardingScreenAnimation2 from './OnboardingScreenAnimation2';
import OnboardingScreenAnimation3 from './OnboardingScreenAnimation3';
import OnboardingScreenConsiderations from './OnboardingScreenConsiderations';
import OnboardingScreenDietary from './OnboardingScreenDietary';
import OnboardingScreenHabits from './OnboardingScreenHabits';
import OnboardingScreenHousehold from './OnboardingScreenHousehold';

const OnboardingScreen = () => {
  const linkTo = useLinkTo();
  const [state, stateSet] = useState(0);
  const { profile } = useContext(UserContext);
  const [onboardingData, onboardingDataSet] = useState({ householdSize: 2 });

  useEffect(() => {
    tracking.trackScreen({ name: `Onboarding-${state + 1}` });
  }, [state]);

  const screens = [
    OnboardingScreenGetStarted,
    OnboardingScreenAnimation1,
    OnboardingScreenAnimation2,
    OnboardingScreenAnimation3,
    OnboardingScreenHousehold,
    OnboardingScreenDietary,
    OnboardingScreenConsiderations,
    OnboardingScreenHabits,
  ];

  const Screen = screens[state];

  const handleSkip = () => {
    tracking.trackSkipOnboarding();
    AsyncStorage.setItem('onboarding-complete', 'true');
    linkTo('/home');
  };

  const handleFinish = () => {
    AsyncStorage.setItem('onboarding-complete', 'true');
    AsyncStorage.setItem(
      'onboarding-data',
      JSON.stringify({
        retailer: 'tesco',
        ...onboardingData,
      }),
    );

    if (profile) {
      linkTo('/home');
    } else {
      linkTo('/register');
    }
  };

  return (
    <Screen
      data={onboardingData}
      dataSet={onboardingDataSet}
      onNext={() => stateSet(Math.min(screens.length - 1, state + 1))}
      onPrev={() => stateSet(Math.max(0, state - 1))}
      onSkip={handleSkip}
      onFinish={handleFinish}
    />
  );
};

export default OnboardingScreen;
