import React, { useCallback } from 'react';
import { FlatList, useWindowDimensions } from 'react-native';
import { Box, Center } from 'native-base';

import useScrollHandler from '../hooks/useScrollHandler';
import { sharedCssStyles } from '../styles/utils';

import Body3 from './Body/Body3';
import ChefCard, { CHEF_CARD_HEIGHT, CHEF_CARD_MARGIN, CHEF_CARD_WIDTH } from './ChefCard';
import InfoTip from './InfoTip';

const CHEF_CARD_LIST_PADDING_X = 3;

const ChefCardList = (props) => {
  const { width } = useWindowDimensions();

  const { ref, handleScroll } = useScrollHandler();

  const numColumns = Math.min(
    4,
    Math.floor(width / (CHEF_CARD_WIDTH + CHEF_CARD_MARGIN * 2 + CHEF_CARD_LIST_PADDING_X)),
  );

  const { list, displayDiscoverInfoTip, listHeaderComponent, showLoaderSet } = props;

  const ListHeaderComponent = useCallback(
    () =>
      (listHeaderComponent || displayDiscoverInfoTip) && (
        <Center key="chef-card-list-header" py={5}>
          {listHeaderComponent}
          {displayDiscoverInfoTip && <InfoTip text={`Discover our ${list.length} chefs and their recipes`} />}
        </Center>
      ),
    [displayDiscoverInfoTip, list.length, listHeaderComponent],
  );

  const renderItemFunc = (a) => {
    if (!a.item) return null;
    return <ChefCard key={a.item.id} chef={a.item} showLoaderSet={showLoaderSet} />;
  };

  if (!list || !list.length) {
    return (
      <Box flex={1} justifyContent="flex-start" textAlign="center">
        <Body3>No chefs</Body3>
      </Box>
    );
  }

  if (numColumns === 1) {
    return (
      <FlatList
        ref={ref}
        data={list}
        renderItem={renderItemFunc}
        keyExtractor={(chef) => chef.id}
        key={numColumns}
        onScroll={handleScroll}
        contentContainerStyle={sharedCssStyles.flatListContainer}
        maxToRenderPerBatch={2}
        windowSize={2}
        initialNumToRender={2}
        getItemLayout={(_, i) => ({ length: CHEF_CARD_HEIGHT, offset: CHEF_CARD_HEIGHT * i, index: i })}
        ListHeaderComponent={ListHeaderComponent}
      />
    );
  }

  return (
    <FlatList
      ref={ref}
      data={list}
      renderItem={renderItemFunc}
      keyExtractor={(chef) => chef.id}
      key={numColumns}
      onScroll={handleScroll}
      contentContainerStyle={sharedCssStyles.flatListContainer}
      numColumns={numColumns}
      paddingX={CHEF_CARD_LIST_PADDING_X}
      maxToRenderPerBatch={2}
      windowSize={2}
      initialNumToRender={2}
      getItemLayout={(_, i) => ({ length: CHEF_CARD_HEIGHT, offset: CHEF_CARD_HEIGHT * i, index: i })}
      ListHeaderComponent={ListHeaderComponent}
    />
  );
};

export default ChefCardList;
