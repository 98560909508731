import { useContext } from 'react';
import WebView from 'react-native-webview';
import { useLinkTo } from '@react-navigation/native';
import { Box } from 'native-base';

import { GlobalContext } from '../../../contexts/GlobalContext';
import { UserContext } from '../../../contexts/UserContext';

const facebookLoginHref =
  `https://www.facebook.com/v14.0/dialog/oauth` +
  `?client_id=2665787293441815` +
  `&redirect_uri=https://staging-v2.plateup.app/dummy` +
  `&state=123456` +
  `&scope=email` +
  `&response_type=token`;

const LoginFacebookScreenAndroid = () => {
  const linkTo = useLinkTo();
  const { loginFacebook } = useContext(UserContext);
  const { notLoggedInRedirect } = useContext(GlobalContext);

  const handleNavChange = async (event) => {
    console.log('event.url', event.url);
    if (!event.url.includes('#access_token')) return;

    const token = event.url.replace(/.*#access_token=/g, '').replace(/&.*/, '');
    if (!token) return;
    try {
      await loginFacebook(token);
      if (notLoggedInRedirect) {
        linkTo(notLoggedInRedirect);
      } else {
        linkTo('/home');
      }
    } catch (err) {
      linkTo('/login', { replace: true });
    }
  };

  return (
    <Box height="100%">
      <WebView
        incognito
        textZoom={125}
        source={{ uri: facebookLoginHref }}
        userAgent="Mozilla/5.0 (Ubuntu; Mobile) Firefox/115.0"
        onNavigationStateChange={handleNavChange}
      />
    </Box>
  );
};

export default LoginFacebookScreenAndroid;
