import { Platform } from 'react-native';

import AppleLoginButtonAndroid from './AppleLoginButtonAndroid';
import AppleLoginButtonIos from './AppleLoginButtonIos';
import AppleLoginButtonWeb from './AppleLoginButtonWeb';

const AppleLoginButton = (props) => {
  if (Platform.OS === 'ios') {
    return <AppleLoginButtonIos {...props} />;
  }

  if (Platform.OS === 'android') {
    return <AppleLoginButtonAndroid {...props} />;
  }

  return <AppleLoginButtonWeb {...props} />;
};

export default AppleLoginButton;
