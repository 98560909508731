import { useContext, useEffect } from 'react';
import { useIsFocused } from '@react-navigation/native';

import Loading from '../../components/Loading';
import { QuoteContext } from '../../contexts/QuoteContext';
import useCart from '../../hooks/useCart';
import tracking from '../../lib/tracking';

import AmazonFreshCartScreen from './AmazonFreshCartScreen/AmazonFreshCartScreen';
import SainsburysCartScreen from './SainsburysCartScreen/SainsburysCartScreen';
import EmptyCartScreen from './EmptyCartScreen';
import OcadoCartScreen from './OcadoCartScreen';
import TescoCartScreen from './TescoCartScreen';

const CartScreen = () => {
  const { retailer } = useContext(QuoteContext);
  const { recipes } = useCart();

  useEffect(() => {
    tracking.trackViewCart(recipes, retailer);
  }, [recipes, retailer]);

  const isFocused = useIsFocused();
  if (!isFocused) return <Loading />;

  if (!recipes.length) {
    return <EmptyCartScreen />;
  }

  let cartScreen = null;
  if (!retailer) {
    return null;
  } else if (retailer.id === 'tesco') {
    cartScreen = <TescoCartScreen />;
  } else if (retailer.id === 'amazonfresh-gb') {
    cartScreen = <AmazonFreshCartScreen />;
  } else if (retailer.id === 'sainsburys') {
    cartScreen = <SainsburysCartScreen />;
  } else if (retailer.id === 'ocado') {
    cartScreen = <OcadoCartScreen />;
  }

  return cartScreen;
};

export default CartScreen;
