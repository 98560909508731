import React, { useCallback, useContext, useEffect } from 'react';
import { FlatList, useWindowDimensions } from 'react-native';
import { Box, Button, Center, Column, useTheme } from 'native-base';

import { FilterContext } from '../contexts/FilterContext';
import useScrollHandler from '../hooks/useScrollHandler';
import { sharedCssStyles } from '../styles/utils';

import Body2Bold from './Body/Body2Bold';
import Body3 from './Body/Body3';
import FiltersBar from './FiltersBar';
import RecipeCard from './RecipeCard';

const RecipeCardList = (props) => {
  const { width } = useWindowDimensions();
  const { colors } = useTheme();

  const { ref, handleScroll } = useScrollHandler();

  const numColumns = Math.min(2, Math.floor(width / 450));

  const { id, list, displayTotalRecipes, listHeaderComponent, withFilters, showLoaderSet } = props;
  const { resetFilters } = useContext(FilterContext);

  useEffect(() => {
    if (!ref.current) return;
    ref.current.scrollToOffset({ offset: 0, animated: false });
  }, [id, ref]);

  const renderItemFunc = (a) => {
    if (!a.item) return null;
    return <RecipeCard key={a.item.id} recipe={a.item} showLoaderSet={showLoaderSet} />;
  };

  const ListHeaderComponent = useCallback(
    () => (
      <Column key="list-header" flex={1}>
        {listHeaderComponent}

        {withFilters && <FiltersBar />}

        {displayTotalRecipes && (
          <Center pt={3} pb={1}>
            <Body3 color={colors.mushroom[500]}>Showing {list.length} recipes</Body3>
          </Center>
        )}
      </Column>
    ),
    [listHeaderComponent, withFilters, displayTotalRecipes, colors.mushroom, list.length],
  );

  if (!list || !list.length) {
    return (
      <Column>
        <Box height="100px">{withFilters && <FiltersBar />}</Box>
        <Center marginTop={20}>
          <Body2Bold>No recipes found</Body2Bold>
          <Body3 my={2} color={colors.black}>
            Try changing or removing some of your filters
          </Body3>
          <Button onPress={resetFilters} marginTop={3}>
            Reset all filters
          </Button>
        </Center>
      </Column>
    );
  }

  let flatList;

  if (numColumns === 1) {
    flatList = (
      <FlatList
        ref={ref}
        data={list}
        renderItem={renderItemFunc}
        keyExtractor={(recipe) => recipe.id}
        onScroll={handleScroll}
        contentContainerStyle={sharedCssStyles.flatListContainer}
        maxToRenderPerBatch={2}
        windowSize={3}
        initialNumToRender={2}
        ListHeaderComponent={ListHeaderComponent}
        // eslint-disable-next-line react-native/no-inline-styles
        ListHeaderComponentStyle={{ width: '100%' }}
      />
    );
  } else {
    flatList = (
      <FlatList
        ref={ref}
        data={list}
        renderItem={renderItemFunc}
        keyExtractor={(recipe) => recipe.id}
        numColumns={numColumns}
        key={numColumns}
        onScroll={handleScroll}
        contentContainerStyle={sharedCssStyles.flatListContainer}
        maxToRenderPerBatch={2}
        windowSize={3}
        initialNumToRender={2}
        ListHeaderComponent={ListHeaderComponent}
        // eslint-disable-next-line react-native/no-inline-styles
        ListHeaderComponentStyle={{ width: '100%' }}
      />
    );
  }

  return flatList;
};

export default RecipeCardList;
