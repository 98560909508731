import { useContext } from 'react';
import { useIsFocused, useRoute } from '@react-navigation/native';

import Loading from '../../components/Loading';
import { RecipeLiveContext } from '../../contexts/RecipeLiveContext';
import usePopulateRecipe from '../../hooks/usePopulateRecipe';

import RecipeDetail from './RecipeDetail';

const RecipeScreen = () => {
  const route = useRoute();

  const { id } = route.params;
  const { loading, find } = useContext(RecipeLiveContext);
  const populateRecipe = usePopulateRecipe();

  const isFocused = useIsFocused();
  if (!isFocused) return <Loading />;

  const recipe = populateRecipe(find(id));

  const cooking = route.path.startsWith('/ordered');

  if (!route.params) return null;
  if (!recipe) return null;

  if (loading) return <Loading />;

  return <RecipeDetail recipe={recipe} cooking={cooking} />;
};

export default RecipeScreen;
