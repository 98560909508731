import { useLinkTo } from '@react-navigation/native';
import { Button, PresenceTransition } from 'native-base';

import useCart from '../hooks/useCart';

import Body2Bold from './Body/Body2Bold';
import FAProIcon from './FAProIcon';
import TopNavIconButton from './TopNavIconButton';

const TopNavCartButton = ({ variant = 'outline', disableOnPress }) => {
  const { recipes } = useCart();

  const linkTo = useLinkTo();

  const handleCart = () => {
    if (disableOnPress) return;
    linkTo('/cart');
  };

  if (recipes && recipes.length >= 1) {
    return (
      <PresenceTransition
        visible={true}
        initial={{
          opacity: 0,
          scale: 0,
          translateX: 50,
        }}
        animate={{
          translateX: 0,
          scale: 1,
          opacity: 1,
        }}
        exit={{
          translateX: 50,
          scale: 0.5,
          opacity: 0,
        }}
      >
        <Button
          onPress={handleCart}
          variant={variant}
          border="1px"
          size="medium"
          paddingX={3}
          paddingY={0}
          justifyContent="center"
          alignItems="center"
          gap={1}
          endIcon={<FAProIcon name="cart-shopping" solid />}
        >
          <Body2Bold>
            {recipes.length} meal{recipes.length > 1 ? 's' : ''}
          </Body2Bold>
        </Button>
      </PresenceTransition>
    );
  }

  return <TopNavIconButton onPress={handleCart} icon="cart-shopping" outline></TopNavIconButton>;
};

export default TopNavCartButton;
