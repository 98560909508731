import React from 'react';
import { Box, Button, ScrollView, useTheme } from 'native-base';

import Body2Bold from '../../components/Body/Body2Bold';
import Heading3Bold from '../../components/Heading/Heading3Bold';
import ImageWrapper from '../../components/ImageWrapper';
import TopNav2 from '../../components/TopNav2';
import useCart from '../../hooks/useCart';
import { sharedCssStyles } from '../../styles/utils';

const CartAbortScreen = ({ handleResume, handleComplete, handleBackToCart }) => {
  const { colors } = useTheme();
  const { recipes } = useCart();

  return (
    <ScrollView contentContainerStyle={sharedCssStyles.scrollViewContainer}>
      <Box background={colors.almond[200]} height="100%">
        <TopNav2 transparent back onBackPress={handleBackToCart} />
        <Box justifyContent="space-between" py={5} flex={1}>
          <Box gap={12}>
            <Box pl={5}>
              <ScrollView horizontal width="100%">
                {recipes.map((recipe) => (
                  <ImageWrapper
                    width="164px"
                    height="164px"
                    resizeMode="cover"
                    src={recipe.images[0]}
                    alt={recipe.id}
                    key={recipe.id}
                    borderRadius={10}
                    margin={2}
                  />
                ))}
              </ScrollView>
            </Box>
            <Box gap={12}>
              <Box gap={4} px={5} py={3}>
                <Heading3Bold textAlign="center">Do you want to leave checkout?</Heading3Bold>
                <Body2Bold textAlign="center">You've got some amazing recipes in your basket</Body2Bold>
              </Box>
              <Box gap={3} px={5}>
                <Button variant="solid" onPress={handleResume}>
                  No, continue to checkout
                </Button>
                <Button variant="outline" onPress={handleBackToCart}>
                  Yes, take me back to my basket
                </Button>
              </Box>
            </Box>
          </Box>
          <Button variant="unstyled" onPress={handleComplete}>
            I've already checked out
          </Button>
        </Box>
      </Box>
    </ScrollView>
  );
};

export default CartAbortScreen;
