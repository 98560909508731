import { Text } from 'native-base';

import fontSize from '../styles/fontSize';

const CustomText = (props) => (
  <Text fontFamily="body" fontWeight={500} fontSize={fontSize(12)} {...props}>
    {props.children}
  </Text>
);

export default CustomText;
