import React, { useCallback, useEffect } from 'react';
import { Platform, StyleSheet } from 'react-native';
import { registerRootComponent } from 'expo';
import { useFonts } from 'expo-font';
import * as SplashScreen from 'expo-splash-screen';
import * as Updates from 'expo-updates';
import { View } from 'native-base';

import { isDevelopmentMode } from './lib/util';
import Main from './Main';
import Providers from './Providers';

SplashScreen.preventAutoHideAsync();

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});

const App = () => {
  async function onFetchUpdateAsync() {
    if (isDevelopmentMode()) {
      return;
    }
    if (Platform.OS === 'web') {
      return;
    }
    try {
      const update = await Updates.checkForUpdateAsync();

      if (update.isAvailable) {
        await Updates.fetchUpdateAsync();
        await Updates.reloadAsync();
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    onFetchUpdateAsync();
  }, []);

  const [fontsLoaded] = useFonts({
    'Bagoss-Bold': require('../assets/fonts/BagossStandard-Bold.ttf'),
    'Bagoss-SemiBold': require('../assets/fonts/BagossStandard-SemiBold.ttf'),
    'Bagoss-Regular': require('../assets/fonts/BagossStandard-Regular.ttf'),
    'Manrope-ExtraBold': require('../assets/fonts/Manrope-ExtraBold.ttf'),
    'Manrope-Bold': require('../assets/fonts/Manrope-Bold.ttf'),
    'Manrope-Medium': require('../assets/fonts/Manrope-Medium.ttf'),
  });

  const onLayoutRootView = useCallback(async () => {
    if (fontsLoaded) {
      await SplashScreen.hideAsync();
    }
  }, [fontsLoaded]);

  if (!fontsLoaded) {
    return null;
  }

  return (
    <Providers>
      <View onLayout={onLayoutRootView} style={styles.container}>
        <Main />
      </View>
    </Providers>
  );
};

registerRootComponent(App);
