import { useContext, useMemo } from 'react';
import { Linking, Pressable, useWindowDimensions } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useIsFocused, useLinkTo, useNavigation, useRoute } from '@react-navigation/native';
import { Box, Center, useTheme } from 'native-base';

import AnimatedHeaderFlatList from '../components/AnimatedHeader/AnimatedHeaderFlatList';
import Body3 from '../components/Body/Body3';
import Heading1 from '../components/Heading/Heading1';
import Heading2 from '../components/Heading/Heading2';
import ImageWrapper from '../components/ImageWrapper';
import Loading from '../components/Loading';
import MurkyGradient from '../components/MurkyGradient';
import RecipeCard, { RECIPE_CARD_WIDTH } from '../components/RecipeCard';
import Tag from '../components/Tag';
import TopNavIconButton from '../components/TopNavIconButton';
import { ChefLiveContext } from '../contexts/ChefLiveContext';
import { RecipeLiveContext } from '../contexts/RecipeLiveContext';
import { getCuisineTagCountFromRecipeList, UCFirst } from '../lib/util';
import { sharedCssStyles } from '../styles/utils';

const CHEF_IMAGE_HEIGHT = 310;
const CHEF_RECIPES_TEXT_HEIGHT = 35;

const ChefScreen = () => {
  const { width } = useWindowDimensions();
  const { colors } = useTheme();
  const navigation = useNavigation();
  const route = useRoute();
  const linkTo = useLinkTo();
  const insets = useSafeAreaInsets();

  const { recipes } = useContext(RecipeLiveContext);
  const { find } = useContext(ChefLiveContext);

  const id = route?.params?.id;

  const recipelist = useMemo(() => {
    const defaultDate = new Date('2000-01-01T00:00:00.000Z').toISOString();

    return recipes
      .filter((a) => a.chefId === id)
      .sort((a, b) => ((a.dateModified || defaultDate) > (b.dateModified || defaultDate) ? -1 : 1));
  }, [id, recipes]);

  const chef = useMemo(() => find(id), [find, id]);

  const allCuisineTags = useMemo(() => getCuisineTagCountFromRecipeList(recipelist), [recipelist]);

  const primaryCuisineTag = useMemo(() => {
    if (!allCuisineTags.length) return undefined;
    return <Tag label={UCFirst(allCuisineTags[0][0])} />;
  }, [allCuisineTags]);

  const isFocused = useIsFocused();
  if (!isFocused) return <Loading />;

  if (!chef) return null;

  const { avatarPill, name, biography, website } = chef;

  const handleBack = () => {
    navigation.canGoBack() ? navigation.goBack() : linkTo('/home');
  };

  const handleWebsite = () => {
    Linking.openURL(website);
  };

  const numColumns = Math.floor(width / RECIPE_CARD_WIDTH);

  const Header = (
    <>
      <Box position="relative" flex={1} justifyContent="flex-end" height={CHEF_IMAGE_HEIGHT}>
        <Box width="100%" height="100%" position="absolute" top={0} left={0} overflow="hidden">
          <ImageWrapper src={avatarPill} alt={name} width="100%" height="120%" resizeMode="cover" />
        </Box>
        <MurkyGradient maxHeight="250px" py={5} px={5} color1="#29262D00" color2="#29262D99">
          <Center gap={4}>
            <Center gap={1}>
              <Heading1 textAlign="center" color="white">
                {name}
              </Heading1>
              <Box flexDirection="row" gap={2}>
                {primaryCuisineTag}
                {website && (
                  <Pressable onPress={handleWebsite}>
                    <Tag label="My link" variant="ghost" icon="link" />
                  </Pressable>
                )}
              </Box>
            </Center>
            <Body3 color="white" textAlign="center">
              {biography}
            </Body3>
          </Center>
        </MurkyGradient>
      </Box>
      <Center pt={3} pb={1} height={CHEF_RECIPES_TEXT_HEIGHT}>
        <Body3 color={colors.mushroom[500]}>{recipelist.length} recipes</Body3>
      </Center>
    </>
  );

  const TopNavBar = (
    <Box
      pt={`${Math.max(insets?.top, 16)}px`}
      py={4}
      px={4}
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      gap={4}
      background="none"
    >
      <TopNavIconButton onPress={handleBack} icon="arrow-left" />
      <Box flex={1} />
    </Box>
  );

  const ReplacementTopNavBar = (
    <Box
      pt={`${Math.max(insets?.top, 16)}px`}
      py={4}
      px={4}
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      gap={4}
      background="white"
      borderBottomColor="#E3E3E3"
      borderBottomWidth="1px"
    >
      <TopNavIconButton onPress={handleBack} outline icon="arrow-left" />
      <Center flex="1">
        <Heading2 textAlign="center" alignSelf="center" flex={1} isTruncated pt={2}>
          {name}
        </Heading2>
      </Center>
      <Box width="36px" />
    </Box>
  );

  return (
    <Box flex={1} bg="white">
      <AnimatedHeaderFlatList
        flatListProps={{
          key: numColumns,
          numColumns,
          data: recipelist,
          renderItem: (a) => <RecipeCard key={a.item.id} recipe={a.item} />,
          keyExtractor: (recipe) => recipe.id,
          contentContainerStyle: sharedCssStyles.flatListContainer,
          ListHeaderComponentStyle: { width: '100%' },
          maxToRenderPerBatch: 2,
          windowSize: 2,
          initialNumToRender: 2,
        }}
        Header={Header}
        TopNavBar={TopNavBar}
        ReplacementTopNavBar={ReplacementTopNavBar}
        headerHeight={CHEF_IMAGE_HEIGHT + CHEF_RECIPES_TEXT_HEIGHT}
      />
    </Box>
  );
};

export default ChefScreen;
