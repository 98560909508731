import React from 'react';
import * as Haptics from 'expo-haptics';
import { Button, PresenceTransition } from 'native-base';

const AddRemoveButton = ({ isAdded, handleAdd, handleRemove }) => {
  const handleAddWithVibrate = () => {
    handleAdd();
    Haptics.notificationAsync(Haptics.NotificationFeedbackType.Success);
  };

  const handleRemoveWithVibrate = () => {
    handleRemove();
    Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
  };

  return (
    <>
      {!isAdded && (
        <PresenceTransition
          visible={true}
          initial={{
            opacity: 0,
            scale: 1.15,
          }}
          animate={{
            scale: 1,
            opacity: 1,
          }}
          exit={{
            scale: 0.75,
            opacity: 1.15,
          }}
        >
          <Button onPress={handleAddWithVibrate}>Add</Button>
        </PresenceTransition>
      )}
      {isAdded && (
        <PresenceTransition
          visible={true}
          initial={{
            opacity: 0,
            scale: 0.65,
          }}
          animate={{
            scale: 1,
            opacity: 1,
          }}
          exit={{
            scale: 0.75,
            opacity: 0,
          }}
        >
          <Button variant="subtle" onPress={handleRemoveWithVibrate}>
            Remove
          </Button>
        </PresenceTransition>
      )}
    </>
  );
};

export default AddRemoveButton;
