import { Text } from 'native-base';

import fontSize from '../../styles/fontSize';

const Body2 = (props) => (
  <Text fontFamily="body" fontWeight={500} fontSize={fontSize(14)} {...props}>
    {props.children}
  </Text>
);

export default Body2;
