/* eslint-disable react-native/no-inline-styles */
import React from 'react';
import { Animated } from 'react-native';
import { ScrollView } from 'native-base';

import useAnimatedHeader from './useAnimatedHeader';

const AnimatedHeaderScrollView = ({
  Header,
  TopNavBar,
  ReplacementTopNavBar,
  scrollViewProps = {},
  headerHeight = 315,
  navBarHeight = 72,
  children,
}) => {
  const { onScroll, topNavOpacity, replacementTopNavOpacity } = useAnimatedHeader(headerHeight, navBarHeight);

  // Different from Animated.ScrollView as we're using the NativeBase ScrollView here
  const AnimatedScrollView = Animated.createAnimatedComponent(ScrollView);

  return (
    <>
      <AnimatedScrollView onScroll={onScroll} height="100%" scrollEventThrottle={16} {...scrollViewProps}>
        {Header}
        {children}
      </AnimatedScrollView>
      {/* TopNav will be rendered on top of the ScrollView and fade out on scroll down */}
      <Animated.View
        style={{
          position: 'absolute',
          top: 0,
          elevation: 2,
          height: navBarHeight,
          opacity: topNavOpacity,
          width: '100%',
        }}
      >
        {TopNavBar}
      </Animated.View>
      {/* ReplacementTopNav will be rendered on top of the ScrollView and fade in on scroll down */}
      <Animated.View
        style={{
          position: 'absolute',
          top: 0,
          elevation: 2,
          height: navBarHeight,
          opacity: replacementTopNavOpacity,
          width: '100%',
        }}
      >
        {ReplacementTopNavBar}
      </Animated.View>
    </>
  );
};

export default AnimatedHeaderScrollView;
