import apple from './apple';
import checkout from './checkout';
import crud from './crud';
import facebook from './facebook';
import quote from './quote';
import rest from './rest';
import tracking from './tracking';
import user from './user';

const api = {
  rest,

  user,

  common: crud('common'),
  tag: crud('tag'),
  ingredient: crud('ingredient'),

  chef: {
    draft: crud('chef-draft'),
    live: crud('chef-live'),
    archive: crud('chef-archive'),
  },

  recipe: {
    draft: crud('recipe-draft'),
    live: crud('recipe-live'),
    archive: crud('recipe-archive'),
  },

  quote,
  checkout,

  apple,
  facebook,
  tracking,
};
export default api;
