import AsyncStorage from '@react-native-async-storage/async-storage';

import { showNotification } from '../notifications';

const taskKey = 'notify-abandoned-cart';
const doneKey = `${taskKey}-done`;
const delay = 24 * 60 * 60 * 1000;

const log = (...args) => {
  console.log(taskKey, ...args);
};

export const abandonedCartTrigger = async () => {
  log(`abandonedCartTrigger`);
  await AsyncStorage.setItem(taskKey, `${Date.now()}`);
};

export const abandonedCartDone = async () => {
  log(`abandonedCartDone`);
  await AsyncStorage.setItem(doneKey, 'true');
};

export const abandonedCartReset = async () => {
  log(`abandonedCartReset`);
  await AsyncStorage.removeItem(taskKey);
  await AsyncStorage.removeItem(doneKey);
};

export const abandonedCartTask = async (now, profile) => {
  log(`Check ${taskKey}`);
  const trigger = Number(await AsyncStorage.getItem(taskKey)) || 0;
  const done = await AsyncStorage.getItem(doneKey);

  if (done) {
    log(`if (done) {`);
    return false;
  }

  if (!trigger) {
    log(`if (!trigger) {`);
    return false;
  }

  if (now < trigger + delay) {
    log(`if (now < trigger + delay) {`);
    return false;
  }

  if (!profile?.cart?.length) {
    log(`if (profile?.cart?.length) {`);
    return false;
  }

  log(`show ${taskKey}`);
  await showNotification(
    'Forgotten something? 🤔',
    'Still thinking about those recipes? Complete your order now and whip up a delicious meal',
    '/cart',
  );

  await abandonedCartDone();

  return true;
};
