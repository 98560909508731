import { useLinkTo } from '@react-navigation/native';
import { Box, IconButton, Pressable } from 'native-base';

import Body2Bold from '../../components/Body/Body2Bold';
import Body3Bold from '../../components/Body/Body3Bold';
import FAProIcon from '../../components/FAProIcon';
import ImageWrapper from '../../components/ImageWrapper';
import useCart from '../../hooks/useCart';

const CartRecipeItem = (props) => {
  const { recipe } = props;
  const { cartItem, remove, changeQuantity } = useCart(recipe.id);
  const linkTo = useLinkTo();

  return (
    <Box width={164} marginX={3}>
      <Box position="relative">
        <Pressable onPress={() => linkTo(`/recipe/${recipe.id}`)}>
          <ImageWrapper
            displayWidth={164}
            alt={recipe.name}
            width={164}
            height={164}
            src={recipe.images[0]}
            borderRadius={10}
          />
        </Pressable>
        <Box position="absolute" top={4} right={4}>
          <IconButton variant="subtle" size="small" onPress={() => remove(recipe.id)}>
            <FAProIcon name="trash-can" />
          </IconButton>
        </Box>

        <Box
          position="absolute"
          bottom={4}
          right={4}
          left={4}
          justifyContent="space-between"
          alignItems="center"
          flexDirection="row"
          bg="almond.400"
          borderRadius={100}
        >
          <IconButton variant="subtle" size="medium" onPress={() => changeQuantity(recipe.id, cartItem.servings - 1)}>
            <FAProIcon name="minus" />
          </IconButton>

          <Body2Bold>{cartItem.servings}</Body2Bold>

          <IconButton variant="subtle" size="medium" onPress={() => changeQuantity(recipe.id, cartItem.servings + 1)}>
            <FAProIcon name="add" />
          </IconButton>
        </Box>
      </Box>
      <Body3Bold paddingTop={3}>{recipe.name}</Body3Bold>
    </Box>
  );
};

export default CartRecipeItem;
