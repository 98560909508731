import React, { useContext } from 'react';
import { Box, Button, Center } from 'native-base';

import Body3 from '../../components/Body/Body3';
import SubHeading1 from '../../components/SubHeading/SubHeading1';
import TopNav2 from '../../components/TopNav2';
import { QuoteContext } from '../../contexts/QuoteContext';

import CartScreenHeader from './CartScreenHeader';

const CartErrorScreen = ({ clearError }) => {
  const { retailer } = useContext(QuoteContext);

  return (
    <Box height="100%" bg="white">
      <TopNav2 back title="Basket" />

      <CartScreenHeader withRecipes={false} />

      <Box gap={6} px={10} py={6} pt="70px">
        <Box gap={1}>
          <SubHeading1 textAlign="center">
            We ran into problems trying to connect to {retailer?.name ?? 'your selected retailer'}.
          </SubHeading1>
          <Body3 textAlign="center">
            It looks like the service with this supermarket may be temporarily down. Why not try checking out your
            basket with one of our other supermarkets by pressing the button below.
          </Body3>
        </Box>
      </Box>
      <Center>
        <Button size="large" onPress={clearError}>
          Back to cart
        </Button>
      </Center>
    </Box>
  );
};

export default CartErrorScreen;
