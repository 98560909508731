import { useContext, useState } from 'react';
import { useLinkTo } from '@react-navigation/native';

import { CheckoutContext } from '../../contexts/CheckoutContext';
import { GlobalContext } from '../../contexts/GlobalContext';
import { QuoteContext } from '../../contexts/QuoteContext';
import { UserContext } from '../../contexts/UserContext';
import useCart from '../../hooks/useCart';
import { CHECKOUT_EVENTS } from '../../lib/tracking';

import CartAbortScreen from './CartAbortScreen';
import CartBasketScreen from './CartBasketScreen';
import CartErrorScreen from './CartErrorScreen';
import CartExplainerScreen from './CartExplainerScreen';
import CartWebViewScreen from './CartWebViewScreen';

// TODO: Move to retailer
const RETAILER_HOSTNAME = 'tesco.co.uk';
const RETAILER_LOGIN_URL = 'https://www.tesco.com/account/login';
const RETAILER_BASKET_URL = 'https://www.tesco.com/groceries/en-GB/trolley';
const RETAILER_SLOT_URL = 'https://www.tesco.com/groceries/en-GB/slots';
const RETAILER_CHECKOUT_URL = 'https://payment.tesco.com/en-GB/payment';
const RETAILER_CHECKOUT_CONFIRMATION_URL = 'https://www.tesco.com/checkout/en-GB/groceries/confirmation';

/**
 * Tesco checkout flow:
 * -> Explainer: For first time checkouts open Explainer
 * -> Checkout
 */
const TescoCartScreen = () => {
  const linkTo = useLinkTo();
  const { cart } = useCart();

  const [busy, busySet] = useState(false);
  const [error, errorSet] = useState(false);
  const [checkoutUrl, checkoutUrlSet] = useState('');
  const [explain, explainSet] = useState(false);
  const [aborted, abortedSet] = useState(false);
  const [checkoutComplete, checkoutCompleteSet] = useState(false);

  const [trackedRetailerLogin, trackedRetailerLoginSet] = useState(false);
  const [trackedBasket, trackedBasketSet] = useState(false);
  const [trackedSlot, trackedSlotSet] = useState(false);
  const [trackedCheckout, trackedCheckoutSet] = useState(false);
  const [trackedConfirm, trackedConfirmSet] = useState(false);

  const { notLoggedInDialogOpenSet } = useContext(GlobalContext);
  const { profile, updateProfile } = useContext(UserContext);
  const { retailerProducts, retailer } = useContext(QuoteContext);
  const { checkoutLanding, checkoutDone, setTrackedCheckoutItems, trackCheckoutScreen, trackCheckoutEvent } =
    useContext(CheckoutContext);

  const products = retailerProducts[retailer.id] || [];

  const handleCheckout = async () => {
    if (!profile) {
      notLoggedInDialogOpenSet(true);
      return;
    }

    if (!profile?.retailerCheckouts?.includes(retailer.id)) {
      if (explain) {
        updateProfile({ retailerCheckouts: [...(profile.retailerCheckouts ?? []), retailer.id] });
        explainSet(false);
      } else {
        explainSet(true);
        return;
      }
    }

    setTrackedCheckoutItems(cart, products, retailer);
    busySet(true);

    const data = await checkoutLanding();

    if (!data) {
      busySet(false);
      return;
    }
    if (data.status === 'CompleteCheckoutUrlProvided') {
      checkoutUrlSet(data.completeCheckoutUrl);
    } else {
      console.error('checkout error', data);
      errorSet(true);
    }
    busySet(false);
  };

  const handleExplainBack = () => {
    explainSet(false);
  };

  const handleAbort = () => {
    trackCheckoutEvent(CHECKOUT_EVENTS.PressCancel);
    abortedSet(true);
  };

  const handleResume = () => {
    trackCheckoutEvent(CHECKOUT_EVENTS.PressContinueToCheckout);

    abortedSet(false);
  };

  const handleBackToCart = () => {
    trackCheckoutEvent(CHECKOUT_EVENTS.PressBackToBasket);
    trackedRetailerLoginSet(false);
    trackedBasketSet(false);
    trackedSlotSet(false);
    trackedCheckoutSet(false);
    trackedConfirmSet(false);
    checkoutUrlSet('');
    abortedSet(false);
  };

  const handleComplete = async () => {
    await checkoutDone();
    linkTo('/order-transferred');
  };

  const handleAlreadyCheckedOut = async () => {
    trackCheckoutEvent(CHECKOUT_EVENTS.PressAlreadyCheckedOut);
    return handleComplete();
  };

  const handleBackToPlateUp = async () => {
    trackCheckoutEvent(CHECKOUT_EVENTS.PressCheckoutComplete);
    return handleComplete();
  };

  const handleNavChange = async (event) => {
    const { url } = event;

    checkoutUrlSet(url);

    if (!trackedRetailerLogin && url.startsWith(RETAILER_LOGIN_URL)) {
      trackedRetailerLoginSet(true);
      trackCheckoutScreen(CHECKOUT_EVENTS.ViewLogin, RETAILER_LOGIN_URL);
    }
    if (!trackedBasket && url.startsWith(RETAILER_BASKET_URL)) {
      trackedBasketSet(true);
      trackCheckoutScreen(CHECKOUT_EVENTS.ViewBasket, RETAILER_BASKET_URL);
    }
    if (!trackedSlot && url.startsWith(RETAILER_SLOT_URL)) {
      trackedSlotSet(true);
      trackCheckoutScreen(CHECKOUT_EVENTS.ViewSlotBooking, RETAILER_SLOT_URL);
    }
    if (!trackedCheckout && url.startsWith(RETAILER_CHECKOUT_URL)) {
      trackedCheckoutSet(true);
      trackCheckoutScreen(CHECKOUT_EVENTS.ViewPayment, RETAILER_CHECKOUT_URL);
    }
    if (!trackedConfirm && url.startsWith(RETAILER_CHECKOUT_CONFIRMATION_URL)) {
      trackedConfirmSet(true);
      trackCheckoutScreen(CHECKOUT_EVENTS.ViewConfirmation, RETAILER_CHECKOUT_CONFIRMATION_URL);
      checkoutCompleteSet(true);
    }
  };

  if (error) {
    return <CartErrorScreen clearError={() => errorSet(false)} />;
  }

  if (explain) {
    return <CartExplainerScreen retailer={retailer} handleComplete={handleCheckout} handleBack={handleExplainBack} />;
  }

  if (aborted) {
    return (
      <CartAbortScreen
        handleComplete={handleAlreadyCheckedOut}
        handleBackToCart={handleBackToCart}
        handleResume={handleResume}
      />
    );
  }

  if (checkoutUrl) {
    return (
      <CartWebViewScreen
        url={checkoutUrl}
        handleNavChange={handleNavChange}
        handleAbort={handleAbort}
        handleComplete={handleBackToPlateUp}
        fakeUrl={RETAILER_HOSTNAME}
        checkoutComplete={checkoutComplete}
      />
    );
  }

  return <CartBasketScreen products={products} retailer={retailer} handleCheckout={handleCheckout} isLoading={busy} />;
};

export default TescoCartScreen;
