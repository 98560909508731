import { FlatList } from 'react-native';
import { Box } from 'native-base';

import SubHeading2 from '../../components/SubHeading/SubHeading2';

import CartRecipeItem from './CartRecipeItem';

const CartRecipeItemList = (props) => {
  const { list } = props;

  return (
    <Box>
      <SubHeading2 paddingY={3} paddingX={5}>
        Your recipes
      </SubHeading2>
      <FlatList
        data={list}
        renderItem={(a) => <CartRecipeItem recipe={a.item} />}
        keyExtractor={(feature) => feature.id}
        horizontal={true}
      />
    </Box>
  );
};

export default CartRecipeItemList;
