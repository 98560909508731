import { useContext, useEffect, useMemo, useState } from 'react';
import { Linking, Text } from 'react-native';
import { useLinkTo } from '@react-navigation/native';
import { Box, Button, Center, useTheme } from 'native-base';

import Body3 from '../../components/Body/Body3';
import Body3Bold from '../../components/Body/Body3Bold';
import AppleLoginButton from '../../components/Button/AppleLoginButton/AppleLoginButton';
import FacebookLoginButton from '../../components/Button/FacebookLoginButton/FacebookLoginButton';
import ErrorInfoTip from '../../components/ErrorInfoTip';
import OrLine from '../../components/OrLine';
import PasswordTextField from '../../components/PasswordTextField';
import TextField from '../../components/TextField';
import { GlobalContext } from '../../contexts/GlobalContext';
import { UserContext } from '../../contexts/UserContext';
import { RE_EMAIL } from '../../lib/util';

import LoginContainer from './components/LoginContainer';

const RegisterScreen = () => {
  const { colors } = useTheme();
  const { register, registerError, clearErrors } = useContext(UserContext);
  const { notLoggedInRedirect } = useContext(GlobalContext);
  const linkTo = useLinkTo();

  const [name, nameSet] = useState('');
  const [email, emailSet] = useState('');
  const [password, passwordSet] = useState('');
  const [confirmPassword, confirmPasswordSet] = useState('');
  const [busy, busySet] = useState(false);
  const [inputError, inputErrorSet] = useState('');
  const [step, stepSet] = useState(0);

  useEffect(() => {
    if (registerError) {
      stepSet(0);
      passwordSet('');
      confirmPasswordSet('');
    }
  }, [registerError]);

  const handleSubmit = async () => {
    if (password !== confirmPassword) {
      inputErrorSet("Passwords don't match");
      return;
    }

    const passwordErr = password.length < 6 || /\d/.test(password) === false || /[a-z]/.test(password) === false;

    if (passwordErr) {
      inputErrorSet(
        'Password must be at least 6 characters long and contain at least one letter and at least one number',
      );
      return;
    }

    inputErrorSet('');
    clearErrors();

    try {
      const [firstName = '', lastName = ''] = name.split(' ');
      busySet(true);
      await register(email, password, { firstName, lastName });
      if (notLoggedInRedirect) {
        linkTo(notLoggedInRedirect);
      } else {
        linkTo('/home');
      }
    } finally {
      busySet(false);
    }
  };

  const handleTermsOfService = async () => {
    await Linking.openURL('https://plateup.app/legal/terms');
  };

  const handlePrivacyPolicy = async () => {
    await Linking.openURL('https://plateup.app/legal/privacy-policy');
  };

  const handleContinue = () => stepSet(1);
  const handleBack = step > 0 ? () => stepSet(0) : undefined;

  const continueDisabled = useMemo(() => !name || !RE_EMAIL.test(email), [name, email]);

  return (
    <LoginContainer title="Register" subtitle="Create an account" onBackPress={handleBack}>
      {step === 0 ? (
        <>
          <FacebookLoginButton width="100%" />
          <AppleLoginButton width="100%" />
          <OrLine />
          <TextField placeholder="Name" value={name} onChange={nameSet} />
          <TextField placeholder="Email address" value={email} onChange={emailSet} />
          <ErrorInfoTip text={registerError} />
          <Button variant="solid" onPress={handleContinue} isDisabled={continueDisabled} width="100%">
            Continue
          </Button>
          <Center flexDirection="row" mt="auto">
            <Text>
              <Body3>Already have an account? </Body3>
              <Body3 onPress={() => linkTo('/login')} color={colors.burntApricot[500]}>
                Login
              </Body3>
            </Text>
          </Center>
        </>
      ) : (
        <>
          <PasswordTextField value={password} onChange={passwordSet} />
          <PasswordTextField value={confirmPassword} onChange={confirmPasswordSet} placeholder="Confirm Password" />
          <Box flexDirection="row" flexWrap="wrap" pt={4} pb={5} px={1}>
            <Text>
              <Body3>By selecting </Body3>
              <Body3Bold>Agree and continue</Body3Bold>
              <Body3>, I agree to Plate Up's </Body3>
              <Body3Bold color={colors.burntApricot[500]} onPress={handleTermsOfService}>
                Terms of Service
              </Body3Bold>
              <Body3> and </Body3>
              <Body3Bold color={colors.burntApricot[500]} onPress={handlePrivacyPolicy}>
                Privacy Policy.
              </Body3Bold>
            </Text>
          </Box>
          <ErrorInfoTip text={inputError} />
          <Button
            variant="solid"
            onPress={handleSubmit}
            width="100%"
            isLoading={busy}
            isDisabled={!password || !confirmPassword}
          >
            Agree and continue
          </Button>
        </>
      )}
    </LoginContainer>
  );
};

export default RegisterScreen;
