import React, { useCallback, useContext, useMemo } from 'react';
import { Box, ScrollView } from 'native-base';

import { FilterContext } from '../../contexts/FilterContext';
import { GlobalContext } from '../../contexts/GlobalContext';
import { TAG_GROUPS } from '../../hooks/useTagGroup';

import ChangeCostPerServing from './Filters/ChangeCostPerServing';
import ChangePillar from './Filters/ChangePillar';
import ChangePrepTime from './Filters/ChangePrepTime';
import ChangeTags from './Filters/ChangeTags';
import ChangeTotalTime from './Filters/ChangeTotalTime';
import FiltersFooter from './Filters/FiltersFooter';
import FiltersHeader from './Filters/FiltersHeader';
import BottomDrawer from './BottomDrawer';

export const FILTER_SINGLE_COMPONENT = {
  cuisines: 'Cuisines',
  difficulty: 'Difficulty',
  costPerServing: 'Cost per serving',
  pillar: 'Meal Categories',
  time: 'Time',
};

const FiltersSingle = () => {
  const { filtersSingleDialogType, filtersSingleDialogTypeSet } = useContext(GlobalContext);
  const { tags, tagsSet, costMinSet, costMaxSet, timeMinSet, timeMaxSet, pillarSet } = useContext(FilterContext);

  const handleClose = () => {
    filtersSingleDialogTypeSet(false);
  };

  const filterComponent = useMemo(() => {
    const boxProps = {
      borderTopColor: undefined,
      borderTopStyle: undefined,
      borderTopWidth: undefined,
    };

    switch (filtersSingleDialogType) {
      case FILTER_SINGLE_COMPONENT.cuisines:
        return <ChangeTags group={TAG_GROUPS.cuisine} boxProps={boxProps} withClear={false} />;
      case FILTER_SINGLE_COMPONENT.difficulty:
        return <ChangeTags group={TAG_GROUPS.difficulty} boxProps={boxProps} withClear={false} />;
      case FILTER_SINGLE_COMPONENT.costPerServing:
        return <ChangeCostPerServing label="" boxProps={boxProps} withClear={false} />;
      case FILTER_SINGLE_COMPONENT.pillar:
        return <ChangePillar label="" boxProps={boxProps} withClear={false} />;
      case FILTER_SINGLE_COMPONENT.time:
        return (
          <Box width="100%">
            <ChangePrepTime />
            <ChangeTotalTime />
          </Box>
        );
      default:
        return false;
    }
  }, [filtersSingleDialogType]);

  const handleClearFilters = useCallback(() => {
    switch (filtersSingleDialogType) {
      case FILTER_SINGLE_COMPONENT.cuisines:
        tagsSet(tags.filter((tag) => !tag.startsWith(TAG_GROUPS.cuisine)));
        return;
      case FILTER_SINGLE_COMPONENT.difficulty:
        tagsSet(tags.filter((tag) => !tag.startsWith(TAG_GROUPS.difficulty)));
        return;
      case FILTER_SINGLE_COMPONENT.costPerServing:
        costMinSet(0);
        costMaxSet(9999);
        return;
      case FILTER_SINGLE_COMPONENT.mealTypes:
        tagsSet(tags.filter((tag) => !tag.startsWith(TAG_GROUPS.mealType)));
        return;
      case FILTER_SINGLE_COMPONENT.pillar:
        pillarSet('');
        return;
      case FILTER_SINGLE_COMPONENT.time:
        console.error('prep time not implemented');
        timeMinSet(0);
        timeMaxSet(9999);
        return;
      default:
        return false;
    }
  }, [costMaxSet, costMinSet, filtersSingleDialogType, pillarSet, tags, tagsSet, timeMaxSet, timeMinSet]);

  if (!filtersSingleDialogType) return null;
  if (!filterComponent) return null;

  return (
    <BottomDrawer isOpen={true} onClose={handleClose}>
      <FiltersHeader title={filtersSingleDialogType} handleClose={handleClose} />
      <ScrollView>{filterComponent}</ScrollView>
      <FiltersFooter handleClearFilters={handleClearFilters} handleClose={handleClose} />
    </BottomDrawer>
  );
};

export default FiltersSingle;
