import React from 'react';
import { Box } from 'native-base';

import { TAG_GROUPS } from '../../../hooks/useTagGroup';

import ChangeHouseholdSize from './ChangeHouseholdSize';
import ChangeTags from './ChangeTags';

const ChangeGlobalPreferences = () => (
  <Box>
    <ChangeHouseholdSize />
    <ChangeTags label="Dietary requirements" group={TAG_GROUPS.avoidDiet} withClear={false} />
    <ChangeTags label="Allergies" group={TAG_GROUPS.avoidance} withClear={false} />
  </Box>
);

export default ChangeGlobalPreferences;
