import { Box, Row } from 'native-base';

const CarouselIndicator = (props) => {
  const { active = 0, count = 7 } = props;

  const items = [];

  for (let i = 0; i < count; i++) {
    items.push(
      <Box
        key={`CarouselIndicator-${i}`}
        width="24px"
        height="8px"
        borderRadius={999}
        bgColor={i < active ? 'burntApricot.500' : 'almond.500'}
      />,
    );
  }

  return (
    <Row padding={1} space={1} justifyContent="center">
      {items}
    </Row>
  );
};

export default CarouselIndicator;
