import React, { useContext } from 'react';
import { Box } from 'native-base';

import { FilterContext } from '../../../contexts/FilterContext';
import useTagGroup from '../../../hooks/useTagGroup';
import { UCFirst } from '../../../lib/util';
import Chip from '../../Chip';

import FilterSection from './FilterSection';

const ChangeTags = ({ label, group, withClear = true, boxProps }) => {
  const { tagGroup } = useTagGroup(group);
  const { tags, tagsSet } = useContext(FilterContext);

  const handleClear = () => {
    const newTags = tags.filter((tag) => !tagGroup.some((tgTag) => tgTag.id === tag));
    tagsSet(newTags);
  };

  return (
    <FilterSection label={label} clearFilter={withClear ? handleClear : undefined} boxProps={boxProps}>
      <Box flexDirection="row" gap={2} flexWrap="wrap">
        {tagGroup.map((tag) => {
          const selected = tags.includes(tag.id);
          const handlePress = () => tagsSet(selected ? tags.filter((b) => b !== tag.id) : [...tags, tag.id]);
          return (
            <Chip
              key={tag.id}
              label={UCFirst(tag.name)}
              variant={selected ? 'active' : 'outline'}
              onPress={handlePress}
            />
          );
        })}
      </Box>
    </FilterSection>
  );
};

export default ChangeTags;
