import { useContext, useEffect } from 'react';
import { useIsFocused, useLinkTo } from '@react-navigation/native';
import { Box, Button, Center, ScrollView, useTheme } from 'native-base';

import Heading2 from '../components/Heading/Heading2';
import Loading from '../components/Loading';
import OrderTransferredCard from '../components/OrderTransferredCard';
import RatingStars from '../components/RatingStars';
import TopNav2 from '../components/TopNav2';
import { CheckoutContext } from '../contexts/CheckoutContext';
import { GlobalContext } from '../contexts/GlobalContext';
import { CHECKOUT_EVENTS } from '../lib/tracking';
import { sharedCssStyles } from '../styles/utils';

const OrderTransferredScreen = ({ navigation }) => {
  const linkTo = useLinkTo();

  const { colors } = useTheme();

  const { retailer, recipes, trackCheckoutScreen } = useContext(CheckoutContext);
  const { currentScreen } = useContext(GlobalContext);

  useEffect(() => {
    if (currentScreen === 'OrderTransferred') {
      trackCheckoutScreen(CHECKOUT_EVENTS.ViewPUConfirmation, '/order-transferred');
    }
  }, [trackCheckoutScreen, currentScreen]);

  const handleComplete = () => {
    linkTo('/home');
  };

  const handleRatingChange = (rating) => {
    navigation.navigate('Feedback', {
      rating,
    });
  };

  const isFocused = useIsFocused();
  if (!isFocused) return <Loading />;

  return (
    <ScrollView contentContainerStyle={sharedCssStyles.scrollViewContainer}>
      <Box background={colors.almond[200]} height="100%">
        <TopNav2 transparent title="Order overview" />

        <Center px={5} py={5} flex={1}>
          <OrderTransferredCard retailer={retailer} totalRecipes={recipes?.length} />

          <Center gap={2} pt={12}>
            <Heading2 textAlign="center">How was your Plate Up experience?</Heading2>
            <RatingStars valueSet={handleRatingChange} />
          </Center>

          <Button variant="solid" onPress={handleComplete} mt="auto">
            Done
          </Button>
        </Center>
      </Box>
    </ScrollView>
  );
};

export default OrderTransferredScreen;
