const Switch = {
  defaultProps: {
    size: 'medium',
  },

  baseStyle: {
    offTrackColor: '#E9E9EA',
    onTrackColor: '#65C466',
    onThumbColor: 'white',
    offThumbColor: 'white',
  },

  sizes: {
    medium: {},
  },
};

export default Switch;
