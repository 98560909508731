import React from 'react';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { Center, useTheme } from 'native-base';

import ScaleUp from '../components/Animations/ScaleUp';
import FAProIcon from '../components/FAProIcon';
import Loading from '../components/Loading';
import useExploreTabsOptions from '../hooks/useExploreTabsOptions';

import HomeScreen from './HomeScreen/HomeScreen';
import SearchScreen from './SearchScreen/SearchScreen';
import ChefListScreen from './ChefListScreen';
import FavouritesScreen from './FavouritesScreen';
import RecipeListScreen from './RecipeListScreen';

const Tab = createMaterialTopTabNavigator();
const Stack = createNativeStackNavigator();

const ExploreTabs = () => {
  const { colors } = useTheme();

  const { hideIcons } = useExploreTabsOptions();

  return (
    <Tab.Navigator
      screenOptions={{
        tabBarLabelStyle: {
          fontFamily: 'Manrope-Bold',
          fontStyle: 'normal',
          fontWeight: 700,
          fontSize: 12,
          textTransform: 'none',
        },
        tabBarActiveTintColor: colors.brightNavy[500],
        tabBarInactiveTintColor: colors.brightNavy[400],
        tabBarIndicatorStyle: {
          backgroundColor: colors.mustard[500],
          left: '5%',
          width: '15%',
        },
        swipeEnabled: false,
        tabBarShowIcon: !hideIcons,
        lazy: true,
        lazyPlaceholder: () => (
          <Center flex={1} bg="white">
            <Loading />
          </Center>
        ),
        tabBarItemStyle: {
          paddingBottom: 4,
        },
      }}
    >
      <Tab.Screen
        name="Home"
        component={HomeScreen}
        options={{
          title: "What's hot",
          tabBarIcon: ({ focused, color }) => (
            <ScaleUp>
              <FAProIcon name="fire" solid={focused} color={color} size={24} />
            </ScaleUp>
          ),
        }}
      />
      <Tab.Screen
        name="RecipeList"
        component={RecipeListScreen}
        options={{
          title: 'Recipes',
          tabBarIcon: ({ focused, color }) => (
            <ScaleUp>
              <FAProIcon name="bowl-hot" solid={focused} color={color} size={24} />
            </ScaleUp>
          ),
        }}
      />
      <Tab.Screen
        name="ChefList"
        component={ChefListScreen}
        options={{
          title: 'Chefs',
          tabBarIcon: ({ focused, color }) => (
            <ScaleUp>
              <FAProIcon name="user-chef" solid={focused} color={color} size={24} />
            </ScaleUp>
          ),
        }}
      />
      <Tab.Screen
        name="Favourites"
        component={FavouritesScreen}
        options={{
          title: 'Favourites',
          tabBarIcon: ({ focused, color }) => (
            <ScaleUp>
              <FAProIcon name="heart" solid={focused} color={color} size={24} />
            </ScaleUp>
          ),
        }}
      />
    </Tab.Navigator>
  );
};

const ExploreStack = () => (
  <Stack.Navigator
    screenOptions={{
      headerShown: false,
      lazy: true,
      lazyPlaceholder: () => (
        <Center flex={1} bg="white">
          <Loading />
        </Center>
      ),
      freezeOnBlur: true,
    }}
  >
    <Stack.Screen name="Explore" component={ExploreTabs} />
    <Stack.Screen
      name="Search"
      component={SearchScreen}
      options={{
        tabBarShowLabel: false,
        tabBarBadge: () => null,
      }}
    />
  </Stack.Navigator>
);

export default ExploreStack;
