import React, { useContext, useMemo } from 'react';
import { Box } from 'native-base';

import { FilterContext } from '../../../contexts/FilterContext';
import { UserContext } from '../../../contexts/UserContext';
import { VisitorContext } from '../../../contexts/VisitorContext';
import Filter from '../../Filter';
import SubHeading2 from '../../SubHeading/SubHeading2';

const ChangeGlobalPreferencesPreview = ({ onPress }) => {
  const { profile } = useContext(UserContext);
  const { visitorHouseholdSize } = useContext(VisitorContext);
  const { tags } = useContext(FilterContext);

  const householdSizeLabel = useMemo(
    () => (profile ? profile?.householdSize : visitorHouseholdSize),
    [profile, visitorHouseholdSize],
  );

  const dietaryRequirementsLabel = useMemo(() => {
    const l = tags.filter((tag) => tag.startsWith('avoid-diet__')).length;
    if (l) return `${l} selected`;
    return 'none';
  }, [tags]);

  const alergiesLabel = useMemo(() => {
    const l = tags.filter((tag) => tag.startsWith('avoidance__')).length;
    if (l) return `${l} selected`;
    return 'none';
  }, [tags]);

  return (
    <Box paddingY={4} alignItems="flex-start" gap={2}>
      <SubHeading2 paddingY={3}>Global preferences</SubHeading2>
      <Filter title="Household size" label={householdSizeLabel} onPress={onPress} />
      <Filter title="Dietary requirements" label={dietaryRequirementsLabel} onPress={onPress} />
      <Filter title="Allergies" label={alergiesLabel} onPress={onPress} />
    </Box>
  );
};

export default ChangeGlobalPreferencesPreview;
