/* eslint-disable react-native/no-inline-styles */
import React, { useContext, useEffect, useRef, useState } from 'react';
import WebView from 'react-native-webview';
import { Box, Button, Center, useTheme } from 'native-base';

import Body2Bold from '../../components/Body/Body2Bold';
import CheckoutWebviewBottom from '../../components/BottomDrawer/CheckoutWebviewBottom';
import FAProIcon from '../../components/FAProIcon';
import Loading from '../../components/Loading';
import TopNavIconButton from '../../components/TopNavIconButton';
import { CheckoutContext } from '../../contexts/CheckoutContext';
import { CHECKOUT_EVENTS } from '../../lib/tracking';

const CartWebViewScreen = ({
  url,
  fakeUrl,
  handleComplete,
  handleAbort,
  handleNavChange,
  checkoutComplete = false,
}) => {
  const webViewRef = useRef(null);
  const { colors } = useTheme();

  const [canGoBack, canGoBackSet] = useState(false);
  const [canGoForward, canGoForwardSet] = useState(false);
  const [urlTapCount, urlTapCountSet] = useState(0);

  const { trackCheckoutScreen } = useContext(CheckoutContext);

  const handleUrlTap = () => {
    urlTapCountSet((a) => a + 1);
  };

  useEffect(() => {
    if (urlTapCount === 10) trackCheckoutScreen(CHECKOUT_EVENTS.ViewConfirmation, 'tapped url 10x');
  }, [urlTapCount, trackCheckoutScreen]);

  const handleNavChangeWrapper = ({ nativeEvent }) => {
    handleNavChange(nativeEvent);
    canGoBackSet(nativeEvent.canGoBack);
    canGoForwardSet(nativeEvent.canGoForward);
  };

  const handleBack = () => {
    webViewRef?.current?.goBack();
  };

  const handleForward = () => {
    webViewRef?.current?.goForward();
  };

  const handleReload = () => {
    webViewRef?.current?.reload();
  };

  return (
    <>
      <Box height="100%" bg="white">
        <Box
          height="52px"
          alignItems="center"
          gap={4}
          px={4}
          py={2}
          flexDirection="row"
          zIndex={1}
          width="100%"
          borderBottomColor="#E3E3E3"
          borderBottomWidth="1px"
        >
          <Button
            onPress={handleAbort}
            size="small"
            variant="unstyled"
            width="100px"
            _text={{ color: colors.mushroom[500] }}
          >
            Cancel
          </Button>
          <Center flex="1" flexDirection="row" gap={2}>
            <FAProIcon name="lock" size="12" solid color={colors.brightNavy[700]}></FAProIcon>
            <Body2Bold color={colors.brightNavy[700]} isTruncated onPress={handleUrlTap}>
              {fakeUrl}
            </Body2Bold>
          </Center>
          <Box width="100px" />
        </Box>
        <Box flex={1}>
          <WebView
            ref={webViewRef}
            source={{ uri: url }}
            startInLoadingState={true}
            renderLoading={() => (
              <Loading wrapperProps={{ height: '100%', width: '100%', position: 'absolute', top: 0 }} />
            )}
            // Using onLoadProgress instead of onNavigationStateChange
            onLoadProgress={handleNavChangeWrapper}
            allowsInlineMediaPlayback
          />
        </Box>

        <Box width="100%" height="50px" bg="white" flexDirection="row">
          <Center flex={1}>
            <TopNavIconButton
              icon="chevron-left"
              onPress={handleBack}
              disabled={!canGoBack}
              color={!canGoBack ? '#E3E3E3' : undefined}
            />
          </Center>
          <Center flex={1}>
            <TopNavIconButton
              icon="chevron-right"
              onPress={handleForward}
              disabled={!canGoForward}
              color={!canGoForward ? '#E3E3E3' : undefined}
            />
          </Center>
          <Center flex={1} />
          <Center flex={1} />
          <Center flex={1}>
            <TopNavIconButton icon="arrow-rotate-right" onPress={handleReload} />
          </Center>
        </Box>
      </Box>
      <CheckoutWebviewBottom isOpen={urlTapCount >= 10 || checkoutComplete} onClose={handleComplete} />
    </>
  );
};

export default CartWebViewScreen;
