import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Keyboard, TouchableWithoutFeedback } from 'react-native';
import { useIsFocused, useLinkTo, useNavigation } from '@react-navigation/native';
import { Box } from 'native-base';

import Heading1 from '../../components/Heading/Heading1';
import ListItem from '../../components/ListItem';
import Loading from '../../components/Loading';
import SearchBox from '../../components/SearchBox';
import TopNavCartButton from '../../components/TopNavCartButton';
import TopNavIconButton from '../../components/TopNavIconButton';
import { SearchContext } from '../../contexts/SearchContext';
import { isMobileApp } from '../../lib/util';

import SearchResults from './SearchResults';

let timeout;
const SearchScreen = () => {
  const linkTo = useLinkTo();

  const navigation = useNavigation();

  const { keywords, keywordsSet, recentSearches } = useContext(SearchContext);

  const [loading, loadingSet] = useState(false);
  const [searchTerm, searchTermSet] = useState('');

  const isFocused = useIsFocused();

  const showLoader = useRef(true);

  const showLoaderSet = (value) => {
    showLoader.current = value;
  };

  useEffect(() => {
    if (!showLoader.current) {
      showLoaderSet(true);
    }
  }, [isFocused]);

  const dismissKeyboard = () => {
    if (isMobileApp) {
      Keyboard.dismiss();
    }
  };

  const search = useCallback(
    (st) => {
      console.log('searching', st);
      dismissKeyboard();
      loadingSet(true);
      keywordsSet([st]);
      setTimeout(() => loadingSet(false), 500);
    },
    [keywordsSet],
  );

  useEffect(() => {
    if (searchTerm) {
      if (!isMobileApp) {
        timeout = setTimeout(() => {
          search(searchTerm);
          console.log('keywords set', searchTerm);
        }, 500);
      }
    } else {
      keywordsSet([]);
    }

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [searchTerm, search, keywordsSet]);

  const handleMobileSearchSubmitted = (e) => {
    const {
      nativeEvent: { text },
    } = e;
    search(text);
    console.log(e.nativeEvent);
  };

  const handleSearchTermButton = useCallback(
    (term) => {
      searchTermSet(term);
      if (isMobileApp) search(term);
    },
    [search],
  );

  const handleBack = () => {
    if (searchTerm) {
      searchTermSet('');
    } else {
      navigation.canGoBack() ? navigation.goBack() : linkTo('/home');
    }
  };

  const recentSearchesBlock = useMemo(() => {
    if (!recentSearches || recentSearches.length === 0) {
      return (
        <Box gap={2.5} paddingY={3.5} paddingX={5}>
          <Heading1>Suggested Searches</Heading1>
          {['BBQ Steak', 'Curry', 'Adam Haskin', 'Mushroom rice'].map((recentSearch, index) => (
            <ListItem
              key={`${recentSearch}${index}`}
              icon="search"
              onPress={() => handleSearchTermButton(recentSearch)}
              text={recentSearch}
            />
          ))}
        </Box>
      );
    } else {
      return (
        <Box gap={2.5} paddingY={3.5} paddingX={5}>
          <Heading1>Recent Searches</Heading1>
          {recentSearches.map((recentSearch, index) => (
            <ListItem
              key={`${recentSearch}${index}`}
              icon="search"
              onPress={() => handleSearchTermButton(recentSearch)}
              text={recentSearch}
            />
          ))}
        </Box>
      );
    }
  }, [handleSearchTermButton, recentSearches]);

  if (!isFocused && showLoader.current) return <Loading />;

  return (
    <TouchableWithoutFeedback onPress={dismissKeyboard}>
      <Box bg="white" flex={1}>
        <Box
          top={0}
          left={0}
          right={0}
          py={4}
          px={4}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          gap={4}
        >
          <TopNavIconButton onPress={handleBack} icon="arrow-left" outline />
          <SearchBox
            value={searchTerm}
            onChange={searchTermSet}
            onSubmitEditing={handleMobileSearchSubmitted}
            autoFocus
          />
          <TopNavCartButton />
        </Box>

        {loading ? (
          <Loading />
        ) : searchTerm.length >= 1 && keywords && keywords.length >= 1 ? (
          <SearchResults showLoaderSet={showLoaderSet} />
        ) : (
          recentSearchesBlock
        )}
      </Box>
    </TouchableWithoutFeedback>
  );
};

export default SearchScreen;
