import { Heading } from 'native-base';

import fontSize from '../../styles/fontSize';

const SubHeading2 = (props) => (
  <Heading fontFamily="subHeading" fontWeight={700} fontSize={fontSize(22)} {...props}>
    {props.children}
  </Heading>
);

export default SubHeading2;
