import React, { useContext, useEffect, useState } from 'react';

import { CommonContext } from './CommonContext';
import { RecipeLiveContext } from './RecipeLiveContext';
import { TagContext } from './TagContext';
import { UserContext } from './UserContext';

const FilterContext = React.createContext({});

let tagsLoadedFromProfile = false;

const FilterProvider = (props) => {
  const { children } = props;
  const { profile, updateProfile } = useContext(UserContext);
  const { recipes } = useContext(RecipeLiveContext);
  const { cuisines, avoidances, avoidDiets, mealTypes, difficulties } = useContext(TagContext);
  const { pillars } = useContext(CommonContext);

  const [retailer, retailerSet] = useState(profile?.retailer || 'tesco');

  const [prepTimeMin, prepTimeMinSet] = useState(0);
  const [prepTimeMax, prepTimeMaxSet] = useState(9999);
  const [timeMin, timeMinSet] = useState(0);
  const [timeMax, timeMaxSet] = useState(9999);
  const [costMin, costMinSet] = useState(0);
  const [costMax, costMaxSet] = useState(9999);
  const [tags, tagsSet] = useState([]);
  const [pillar, pillarSet] = useState();

  useEffect(() => {
    if (!profile) return;
    retailerSet(profile.retailer || 'tesco');
    if (tagsLoadedFromProfile) return;
    tagsSet(profile.tags || []);
    tagsLoadedFromProfile = true;
  }, [profile]);

  const cuisinesSelected = cuisines.filter((a) => tags.includes(a.id));
  const avoidancesSelected = avoidances.filter((a) => tags.includes(a.id));
  const avoidDietsSelected = avoidDiets.filter((a) => tags.includes(a.id));
  const mealTypesSelected = mealTypes.filter((a) => tags.includes(a.id));
  const difficultiesSelected = difficulties.filter((a) => tags.includes(a.id));
  const pillarSelected = pillars.find((a) => a.id === pillar)?.recipes.map((r) => r.id);

  const saveFilters = () => {
    if (!profile) return;
    updateProfile({ tags: [...avoidancesSelected.map((a) => a.id), ...avoidDietsSelected.map((a) => a.id)] });
  };

  const filteredList = recipes
    .filter((r) => !pillarSelected || pillarSelected.includes(r.id))
    .filter((r) => !avoidDietsSelected.some((b) => r.tags.includes(b.id)))
    .filter((r) => !avoidancesSelected.some((b) => r.tags.includes(b.id)))
    .filter((r) => !cuisinesSelected.length || cuisinesSelected.some((b) => r.tags.includes(b.id)))
    .filter((r) => !mealTypesSelected.length || mealTypesSelected.some((b) => r.tags.includes(b.id)))
    .filter((r) => !difficultiesSelected.length || difficultiesSelected.some((b) => r.tags.includes(b.id)))
    .filter((r) => !r.prepTime || (r.prepTime >= prepTimeMin && r.prepTime <= prepTimeMax))
    .filter((r) => r.totalTime >= timeMin && r.totalTime <= timeMax)
    .filter((r) => r.costPerRetailer && r.costPerRetailer[retailer] >= costMin)
    .filter((r) => r.costPerRetailer && r.costPerRetailer[retailer] <= costMax);

  const resetFilters = () => {
    prepTimeMinSet(0);
    prepTimeMaxSet(9999);
    timeMinSet(0);
    timeMaxSet(9999);
    costMinSet(0);
    costMaxSet(9999);
    tagsSet([]);
    pillarSet();
  };

  const value = {
    saveFilters,

    filteredList,

    retailer,
    retailerSet,

    tags,
    tagsSet,

    prepTimeMin,
    prepTimeMax,
    timeMin,
    timeMax,
    costMin,
    costMax,
    cuisinesSelected,
    avoidancesSelected,
    avoidDietsSelected,
    mealTypesSelected,
    difficultiesSelected,
    pillar,

    prepTimeMinSet,
    prepTimeMaxSet,
    timeMinSet,
    timeMaxSet,
    costMinSet,
    costMaxSet,
    pillarSet,

    resetFilters,
  };

  return <FilterContext.Provider value={value}>{children}</FilterContext.Provider>;
};

export { FilterContext, FilterProvider };
