import { useContext } from 'react';
import { Pressable } from 'react-native';
import { useLinkTo, useNavigation } from '@react-navigation/native';
import { Box, Button } from 'native-base';

import { GlobalContext } from '../contexts/GlobalContext';

import Heading2 from './Heading/Heading2';
import PlaceholderSearchBox from './PlaceholderSearchBox';
import TopNavCartButton from './TopNavCartButton';
import TopNavIconButton from './TopNavIconButton';

const TopNav = () => {
  const linkTo = useLinkTo();

  const navigation = useNavigation();

  const { currentScreen } = useContext(GlobalContext);

  const back = ['Recipe', 'Chef', 'Cart'].includes(currentScreen);
  const search = ['Home', 'RecipeList', 'ChefList', 'Favourites'].includes(currentScreen);
  const cart = ['Home', 'RecipeList', 'ChefList', 'Favourites', 'Ordered', 'Account'].includes(currentScreen);
  const favourite = ['Recipe'].includes(currentScreen);
  const share = ['Recipe'].includes(currentScreen);
  const follow = [''].includes(currentScreen);

  const clear = [].includes(currentScreen);
  const greyBackground = [].includes(currentScreen);
  const transparentBackground = [''].includes(currentScreen);
  const hidden = [
    'Login',
    'LoginFacebook',
    'LoginApple',
    'Logout',
    'Register',
    'ResetPassword',
    'ResetPasswordEmailSent',
    'ResetPasswordCode',
    'ResetPasswordConfirm',
    'Recipe',
    'OrderedRecipe',
    'Chef',
    'AccountScreenPersonalInfo',
    'AccountScreenPreferences',
    'AccountScreenDelete',
    'OrderTransferred',
    'Chef',
    'Search',
    'OnboardingScreen',
    'Cart',
    'Feedback',
    'NotificationNagScreen',
  ].includes(currentScreen);
  const absolute = [''].includes(currentScreen);

  const title = {
    Cart: 'Basket',
  };

  const handleBack = () => {
    navigation.canGoBack() ? navigation.goBack() : linkTo('/home');
  };

  const handleFavourite = () => {
    console.log('handleFavourite');
  };

  const handleShare = () => {
    console.log('handleShare');
  };

  const handleFollow = () => {
    console.log('handleFollow');
  };

  const handleClear = () => {
    console.log('handleClear');
  };

  const handleSearch = () => {
    linkTo('/search');
  };

  if (hidden) return null;

  let bg = 'white';
  if (greyBackground) {
    bg = 'brightNavy.100';
  }
  if (transparentBackground) {
    bg = undefined;
  }

  return (
    <Box
      height="62px"
      alignItems="center"
      gap={4}
      px={5}
      pt={4}
      pb={1}
      bg={bg}
      flexDirection="row"
      position={absolute ? 'absolute' : 'relative'}
      zIndex={1}
      width="100%"
    >
      {back && <TopNavIconButton onPress={handleBack} icon="arrow-left" />}
      <Box flex={1} height="40px">
        {search && (
          <Pressable flex={1} onPress={handleSearch}>
            <PlaceholderSearchBox />
          </Pressable>
        )}
        {title[currentScreen] && (
          <Heading2 alignSelf="center" flex={1} isTruncated pt={2}>
            {title[currentScreen]}
          </Heading2>
        )}
      </Box>
      <Box flexDirection="row" ml="auto" gap={4}>
        {cart && <TopNavCartButton />}
        {share && <TopNavIconButton onPress={handleShare} icon="arrow-up-from-bracket" />}
        {favourite && <TopNavIconButton onPress={handleFavourite} icon="heart" />}
        {follow && (
          <Button size="small" variant="ghost" onPress={handleFollow}>
            Follow
          </Button>
        )}
        {clear && (
          <Button size="small" variant="ghost" onPress={handleClear}>
            Clear
          </Button>
        )}
        {back && !cart && !share && !favourite && !follow && !clear && title[currentScreen] && <Box width="36px" />}
      </Box>
    </Box>
  );
};

export default TopNav;
