import { Center, Image } from 'native-base';

const Loading = ({ wrapperProps }) => (
  <Center flex={1} width="100%" bg="white" {...wrapperProps}>
    <Image
      source={require('../../assets/gif/plateupSpinner700.gif')}
      alt="Loading..."
      // eslint-disable-next-line react-native/no-inline-styles
      style={{ width: 245, height: 213 }}
    />
  </Center>
);

export default Loading;
