import { useContext, useMemo } from 'react';
import { useToast } from 'native-base';

import RecipeAddedToast from '../components/Toast/RecipeAddedToast';
import { RecipeLiveContext } from '../contexts/RecipeLiveContext';
import { UserContext } from '../contexts/UserContext';
import { VisitorContext } from '../contexts/VisitorContext';
import { abandonedCartTrigger } from '../lib/background-task/abandoned-cart';
import tracking from '../lib/tracking';

import usePopulateRecipe from './usePopulateRecipe';

const useCart = (recipeId) => {
  const { find } = useContext(RecipeLiveContext);
  const { profile, updateProfile } = useContext(UserContext);
  const { visitorCart, visitorCartSet } = useContext(VisitorContext);

  const recipeAddedToastId = 'recipe-added-toast';

  const toast = useToast();

  const populateRecipe = usePopulateRecipe();

  const cart = profile ? profile.cart : visitorCart;

  const restore = (list) => {
    if (profile) {
      updateProfile({ cart: list });
    } else {
      visitorCartSet(list);
    }
  };

  const add = (id, servings) => {
    tracking.trackAddToCart(id, servings);

    if (profile) {
      updateProfile({ cart: [...profile.cart, { id, servings }] });
    } else {
      visitorCartSet([...cart, { id, servings }]);
    }

    if (!toast.isActive(recipeAddedToastId)) {
      toast.show({
        id: recipeAddedToastId,
        placement: 'top',
        duration: 2750,
        render: () => <RecipeAddedToast />,
      });
    }

    abandonedCartTrigger();
  };

  const remove = (id) => {
    tracking.trackRemoveFromCart(id);
    if (profile) {
      updateProfile({ cart: profile.cart.filter((b) => b.id !== id) });
    } else {
      visitorCartSet(cart.filter((b) => b.id !== id));
    }
  };

  const empty = () => {
    if (profile) {
      updateProfile({ cart: [] });
    } else {
      visitorCartSet([]);
    }
  };

  const recipes = useMemo(
    () =>
      cart
        .map((a) => {
          const f = find(a.id);
          return f ? { ...f, cartServings: a.servings } : null;
        })
        .filter((a) => !!a)
        .map(populateRecipe),
    [cart, find, populateRecipe],
  );

  const changeQuantity = (id, servings) => {
    const nCart = cart.map((b) => (b.id === id ? { ...b, servings } : b)).filter((b) => b.servings > 0);

    if (profile) {
      updateProfile({ cart: nCart });
    } else {
      visitorCartSet(nCart);
    }
  };

  const cartItem = useMemo(() => recipeId && cart.find((r) => r.id === recipeId), [cart, recipeId]);
  const isAdded = useMemo(() => !!cartItem, [cartItem]);

  return { cart, recipes, isAdded, cartItem, add, remove, empty, restore, changeQuantity };
};

export default useCart;
