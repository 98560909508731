import { Text } from 'native-base';

import fontSize from '../../styles/fontSize';

const Body3Bold = (props) => (
  <Text fontFamily="body" fontWeight={700} fontSize={fontSize(12)} {...props}>
    {props.children}
  </Text>
);

export default Body3Bold;
