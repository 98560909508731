import { Box, Button, IconButton, Row } from 'native-base';

import CarouselIndicator from '../../components/CarouselIndicator';
import FAProIcon from '../../components/FAProIcon';

const OnboardingHeader = (props) => {
  const { onPrev, onSkip, active } = props;

  return (
    <Row alignItems="center">
      <Box flex={1}>
        <CarouselIndicator flex={1} active={active} />
      </Box>

      <Box position="absolute" left={0}>
        <IconButton variant="ghost" size="small" onPress={onPrev} icon={<FAProIcon name="arrow-left" />} />
      </Box>

      <Box position="absolute" right={0}>
        <Button variant="link" onPress={onSkip}>
          Skip
        </Button>
      </Box>
    </Row>
  );
};

export default OnboardingHeader;
