import React from 'react';
import { Text as ReactNativeText } from 'react-native';

import Body2 from './Body/Body2';
import Body2ExtraBold from './Body/Body2ExtraBold';

const TextWithBold = ({ text, NormalTypography = Body2, BoldTypography = Body2ExtraBold }) => {
  const boldRegex = /\*\*(.*?)\*\*/g;

  const parts = text.split(boldRegex);

  const processedText = parts.map((part, index) => {
    if (index % 2 === 0) {
      return (
        <NormalTypography key={index} flex={0}>
          {part}
        </NormalTypography>
      );
    } else {
      return <BoldTypography key={index}>{part}</BoldTypography>;
    }
  });

  return <ReactNativeText>{processedText}</ReactNativeText>;
};

export default TextWithBold;
