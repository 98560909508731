import { useContext, useEffect, useMemo, useState } from 'react';
import { BackHandler, Share } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useLinkTo, useNavigation } from '@react-navigation/native';
import { Box, Center, IconButton, Pressable, Row } from 'native-base';

import AnimatedHeaderScrollView from '../../components/AnimatedHeader/AnimatedHeaderScrollView';
import Body3 from '../../components/Body/Body3';
import RecipeBottom from '../../components/BottomDrawer/RecipeBottom';
import RecipeBottomCooking from '../../components/BottomDrawer/RecipeBottomCooking';
import RecipeBottomIconsMoreInfo from '../../components/BottomDrawer/RecipeBottomIconsMoreInfo';
import ChefAvatar from '../../components/ChefAvatar';
import FAProIcon from '../../components/FAProIcon';
import Heading1 from '../../components/Heading/Heading1';
import Heading2 from '../../components/Heading/Heading2';
import ImageWrapper from '../../components/ImageWrapper';
import Tag from '../../components/Tag';
import TopNavIconButton from '../../components/TopNavIconButton';
import { TagContext } from '../../contexts/TagContext';
import { UserContext } from '../../contexts/UserContext';
import useFavourites from '../../hooks/useFavourites';
import tracking from '../../lib/tracking';
import { getTimeAsString } from '../../lib/util';

import Tabs from './Tabs';

const RecipeDetail = (props) => {
  const { recipe, cooking } = props;
  const navigation = useNavigation();
  const linkTo = useLinkTo();
  const tagCtx = useContext(TagContext);
  const { profile, visitorHouseholdSize } = useContext(UserContext);
  const { isFavourite, add, remove } = useFavourites();
  const [showIconInfo, showIconInfoSet] = useState(false);
  const insets = useSafeAreaInsets();

  const [servings, servingsSet] = useState(
    recipe.fixedServings ? recipe.servings : profile?.householdSize || visitorHouseholdSize || recipe.servings,
  );

  const { name, images, nutrition, prepTime, totalTime, chef, description } = recipe;
  const calories = (nutrition?.enerc_kcal || 0) / recipe.servings;
  const tags = recipe.tags || [];

  const handleBack = useMemo(
    () => () => navigation.canGoBack() ? navigation.goBack() : linkTo('/home'),
    [linkTo, navigation],
  );

  useEffect(() => {
    const backHandler = BackHandler.addEventListener('hardwareBackPress', handleBack);
    return () => backHandler.remove();
  }, [handleBack]);

  const handleFavourite = () => {
    if (isFavourite(recipe.id)) {
      return remove(recipe.id);
    }
    return add(recipe.id);
  };

  const handleShare = async () => {
    try {
      tracking.trackShareRecipe({ recipe: recipe.id, name });
      Share.share(
        {
          title: name,
          message: `Check out this recipe on Plate Up - https://groceries.plateup.app/recipe/${recipe.id}`,
        },
        {
          dialogTitle: name,
        },
      );
    } catch (error) {
      console.error('Error sharing:', error);
    }
  };

  const tagItems = tags
    .map((a) => tagCtx.additionalInfo.find((b) => b.id === a))
    .filter((a) => !!a)
    .map((a) => <Tag m={0.5} key={a.id} label={a.name} variant="filled" />);

  const Header = (
    <Box position="relative">
      <ImageWrapper src={images[0]} alt={name} width="100%" height="315px" resizeMode="cover" />
    </Box>
  );

  const TopNavBar = (
    <Box
      pt={`${Math.max(insets?.top, 16)}px`}
      py={4}
      px={4}
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      gap={4}
    >
      <TopNavIconButton onPress={handleBack} icon="arrow-left" />
      <Box flex={1} />
      <TopNavIconButton onPress={handleShare} icon="arrow-up-from-bracket" />
      <TopNavIconButton
        onPress={handleFavourite}
        icon="heart"
        solid={isFavourite(recipe.id)}
        color={isFavourite(recipe.id) ? '#E87451' : undefined}
      />
    </Box>
  );

  const ReplacementTopNavBar = (
    <Box
      pt={`${Math.max(insets?.top, 16)}px`}
      py={4}
      px={4}
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      gap={4}
      background="white"
      borderBottomColor="#E3E3E3"
      borderBottomWidth="1px"
    >
      <TopNavIconButton onPress={handleBack} outline icon="arrow-left" />
      <Center flex="1">
        <Heading2 textAlign="center" alignSelf="center" flex={1} isTruncated pt={2}>
          {name}
        </Heading2>
      </Center>
      <TopNavIconButton onPress={handleShare} icon="arrow-up-from-bracket" outline />
      <TopNavIconButton
        onPress={handleFavourite}
        icon="heart"
        solid={isFavourite(recipe.id)}
        color={isFavourite(recipe.id) ? '#E87451' : undefined}
        outline
      />
    </Box>
  );

  return (
    <AnimatedHeaderScrollView
      headerHeight={350}
      Header={Header}
      TopNavBar={TopNavBar}
      ReplacementTopNavBar={ReplacementTopNavBar}
      scrollViewProps={{
        height: '100%',
        background: 'white',
      }}
    >
      <Box bg="white" px={4} borderTopLeftRadius={10} borderTopRightRadius={10} mt={-2.5}>
        <Row pt={6}>
          <Heading1>{name}</Heading1>
        </Row>

        <Pressable flexDirection="row" pt={2} alignItems="center" onPress={() => linkTo(`/chef/${chef?.id}`)}>
          <ChefAvatar chef={chef} size={22} />
          <Box w="6px" />
          <Body3 color="mushroom.500">By {chef?.name}</Body3>
        </Pressable>

        <Row pt={2} space={4}>
          <Row pt={2} space={2}>
            <FAProIcon name="knife-kitchen" />
            <Body3>{getTimeAsString(prepTime)}</Body3>
          </Row>
          <Row pt={2} space={2}>
            <FAProIcon name="clock" />
            <Body3>{getTimeAsString(totalTime)}</Body3>
          </Row>
          <Row pt={2} space={2}>
            <FAProIcon name="fire-flame-curved" />
            <Body3>{calories.toFixed(0)} kcal</Body3>
          </Row>
          <Box marginLeft="auto" pt={2}>
            <IconButton icon={<FAProIcon name="info-circle" />} onPress={() => showIconInfoSet(true)} />
          </Box>
        </Row>

        <Row
          marginTop={4}
          paddingY={2}
          width="100%"
          flexWrap="wrap"
          borderTopWidth={0.5}
          borderBottomWidth={0.5}
          borderColor="#E3E3E3"
        >
          {tagItems}
        </Row>

        <Row paddingY={4}>
          <Body3>{description}</Body3>
        </Row>

        <Tabs recipe={recipe} servings={servings} />
      </Box>

      <Box height={200} />

      {showIconInfo ? (
        <RecipeBottomIconsMoreInfo handleClose={() => showIconInfoSet(false)} />
      ) : (
        <>
          {!cooking && <RecipeBottom recipe={recipe} servings={servings} servingsSet={servingsSet} />}
          {cooking && <RecipeBottomCooking recipe={recipe} servings={servings} servingsSet={servingsSet} />}
        </>
      )}
    </AnimatedHeaderScrollView>
  );
};

export default RecipeDetail;
