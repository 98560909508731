import React from 'react';
import { useWindowDimensions } from 'react-native';
import { Path, Svg } from 'react-native-svg';
import { Box, Center, ScrollView, useTheme } from 'native-base';

import Body2Bold from '../../../components/Body/Body2Bold';
import Heading3Bold from '../../../components/Heading/Heading3Bold';
import PUIcon from '../../../components/PUIcon';
import TopNav2 from '../../../components/TopNav2';

const LoginContainer = ({ icon, title = '', subtitle = '', onBackPress, children }) => {
  const { width } = useWindowDimensions();
  const { colors } = useTheme();

  const scrollViewContainerStyle = {
    flex: 1,
    gap: 2,
  };

  return (
    <Box flex={1} bg={colors.almond[400]}>
      <TopNav2 back transparent onBackPress={onBackPress} />
      <Center gap={4} px={5} pt={3} pb={8}>
        {icon && <PUIcon />}
        {title && (
          <Heading3Bold color={colors.brightNavy[500]} textAlign="center">
            {title}
          </Heading3Bold>
        )}
        {subtitle && (
          <Body2Bold color={colors.mushroom[500]} textAlign="center">
            {subtitle}
          </Body2Bold>
        )}
      </Center>

      {/* stupidWhiteCutoutWidth */}
      <Svg height="28" width={width}>
        <Path d={`M ${width},28 a 1 1 0 0 0 -${width} ${width * 4}`} fill="white" />
      </Svg>
      <Center bg="white" justifyContent="flex-start" flex={1}>
        <ScrollView _contentContainerStyle={scrollViewContainerStyle} width={width} mt={8} px={5} mb={10}>
          {children}
        </ScrollView>
      </Center>
    </Box>
  );
};

export default LoginContainer;
