/* eslint-disable no-restricted-globals */
import { Platform } from 'react-native';

import LoginFacebookScreenAndroid from './LoginFacebookScreenAndroid';
import LoginFacebookScreenIos from './LoginFacebookScreenIos';
import LoginFacebookScreenWeb from './LoginFacebookScreenWeb';

const LoginFacebookScreen = (props) => {
  if (Platform.OS === 'ios') {
    return <LoginFacebookScreenIos {...props} />;
  }

  if (Platform.OS === 'android') {
    return <LoginFacebookScreenAndroid {...props} />;
  }

  return <LoginFacebookScreenWeb {...props} />;
};

export default LoginFacebookScreen;
