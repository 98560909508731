/* eslint-disable no-restricted-globals */

import React, { Fragment, useContext, useMemo } from 'react';
import { SafeAreaView } from 'react-native-safe-area-context';
import { NavigationContainer, useNavigationContainerRef } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import * as Linking from 'expo-linking';
import { Center, StatusBar } from 'native-base';

import FiltersMain from './components/BottomDrawer/FiltersMain';
import FiltersSingle from './components/BottomDrawer/FiltersSingle';
import NotLoggedInDrawer from './components/BottomDrawer/NotLoggedInDrawer';
import Loading from './components/Loading';
import NotificationLinkHandler from './components/NotificationLinkHandler';
import TopNav from './components/TopNav';
import { GlobalContext } from './contexts/GlobalContext';
import { UserContext } from './contexts/UserContext';
import tracking from './lib/tracking';
import { isMobileApp } from './lib/util';
import AccountScreenDelete from './screens/AccountScreen/AccountScreenDelete';
import AccountScreenPersonalInfo from './screens/AccountScreen/AccountScreenPersonalInfo';
import AccountScreenPreferences from './screens/AccountScreen/AccountScreenPreferences';
import CartScreen from './screens/CartScreen/CartScreen';
import ChefScreen from './screens/ChefScreen';
import Dummy from './screens/Dummy';
import FeedbackScreen from './screens/FeedbackScreen';
import LoginAppleScreen from './screens/Login/LoginAppleScreen/LoginAppleScreen';
import LoginFacebookScreen from './screens/Login/LoginFacebookScreen/LoginFacebookScreen';
import LoginScreen from './screens/Login/LoginScreen';
import LogoutScreen from './screens/Login/LogoutScreen';
import RegisterScreen from './screens/Login/RegisterScreen';
import ResetPasswordCodeScreen from './screens/Login/ResetPassword/ResetPasswordCodeScreen';
import ResetPasswordConfirmScreen from './screens/Login/ResetPassword/ResetPasswordConfirmScreen';
import ResetPasswordEmailSentScreen from './screens/Login/ResetPassword/ResetPasswordEmailSentScreen';
import ResetPasswordScreen from './screens/Login/ResetPassword/ResetPasswordScreen';
import MainTabs from './screens/MainTabs';
import NotificationNagScreen from './screens/NotificationNagScreen';
import OnboardingRedirector from './screens/OnboardingScreen/OnboardingRedirector';
import OnboardingScreen from './screens/OnboardingScreen/OnboardingScreen';
import OrderTransferredScreen from './screens/OrderTransferredScreen';
import RecipeScreen from './screens/RecipeScreen/RecipeScreen';

import './lib/background-task';
import './lib/appsflyer';

const Stack = createNativeStackNavigator();

const linking = {
  prefixes: [Linking.createURL('/'), 'https://staging-v2.plateup.app'],
  config: {
    screens: {
      MainTabs: {
        screens: {
          ExploreStack: {
            screens: {
              Explore: {
                screens: {
                  Home: 'home',
                  RecipeList: 'recipe',
                  ChefList: 'chef',
                  Favourites: 'favourites',
                },
              },
              Search: 'search',
            },
          },
          Ordered: 'ordered',
          Account: 'account',
        },
      },
      OrderedRecipe: 'ordered/:id',
      Recipe: 'recipe/:id',
      Chef: 'chef/:id',
      Cart: 'cart',
      Login: 'login',
      LoginApple: 'login/apple',
      LoginFacebook: 'login/facebook',
      Logout: 'logout',
      Register: 'register',
      ResetPassword: 'reset-password',
      ResetPasswordEmailSent: 'reset-password/email-sent',
      ResetPasswordCode: 'reset-password/:resetCode',
      ResetPasswordConfirm: 'reset-password/confirm',

      AccountScreenPersonalInfo: 'account/personal-info',
      AccountScreenPreferences: 'account/preferences',
      AccountScreenDelete: 'account/delete',

      Feedback: 'feedback',

      OrderTransferred: 'order-transferred',

      OnboardingScreen: 'onboarding',
      NotificationNagScreen: 'notification-nag',

      Dummy: 'dummy',
    },
  },
};

const Main = () => {
  const navRef = useNavigationContainerRef();
  const { profile } = useContext(UserContext);
  const { currentScreen, currentScreenSet, filtersMainDialogOpen, filtersSingleDialogType, notLoggedInDialogOpen } =
    useContext(GlobalContext);

  const handlePathChange = async () => {
    const { name, path } = navRef.getCurrentRoute();

    if (isMobileApp) {
      tracking.trackScreen({ name, path });
    } else {
      tracking.trackPage({
        name,
        url: window.location.href,
        path: window.location.pathname,
        title: document.title,
        referrer: document.referrer,
      });
    }
    console.log('Main:name', name);
    console.log('Main:path', path);
    currentScreenSet(name);
  };

  const safeEdges = useMemo(() => {
    const fullHeight = ['Chef', 'Recipe'];
    const rbl = ['right', 'bottom', 'left'];
    if (fullHeight.includes(currentScreen)) return rbl;
    return ['top', ...rbl];
  }, [currentScreen]);

  const SafeAreaMobile = isMobileApp ? SafeAreaView : Fragment;

  return (
    <SafeAreaMobile flex={1} edges={safeEdges}>
      <NavigationContainer ref={navRef} linking={linking} onReady={handlePathChange} onStateChange={handlePathChange}>
        <StatusBar />
        <TopNav />
        <Stack.Navigator
          screenOptions={{
            headerShown: false,
            lazy: true,
            lazyPlaceholder: () => (
              <Center flex={1} bg="white">
                <Loading />
              </Center>
            ),
            freezeOnBlur: true,
          }}
        >
          <Stack.Screen name="MainTabs" component={MainTabs} />
          <Stack.Screen name="Recipe" component={RecipeScreen} />
          <Stack.Screen name="Chef" component={ChefScreen} />
          <Stack.Screen name="Cart" component={CartScreen} />
          {!profile && <Stack.Screen name="Login" component={LoginScreen} />}
          <Stack.Screen name="LoginApple" component={LoginAppleScreen} />
          <Stack.Screen name="LoginFacebook" component={LoginFacebookScreen} />
          <Stack.Screen name="Logout" component={LogoutScreen} />
          {!profile && <Stack.Screen name="Register" component={RegisterScreen} />}
          {!profile && (
            <Stack.Screen name="ResetPassword" component={ResetPasswordScreen} options={{ title: 'Reset Password' }} />
          )}
          {!profile && (
            <Stack.Screen
              name="ResetPasswordEmailSent"
              component={ResetPasswordEmailSentScreen}
              options={{ title: 'Reset Password' }}
            />
          )}
          {!profile && <Stack.Screen name="ResetPasswordCode" component={ResetPasswordCodeScreen} />}
          {!profile && (
            <Stack.Screen
              name="ResetPasswordConfirm"
              component={ResetPasswordConfirmScreen}
              options={{ title: 'Reset Password' }}
            />
          )}

          <Stack.Screen
            name="AccountScreenPersonalInfo"
            component={AccountScreenPersonalInfo}
            options={{ title: 'Personal Info' }}
          />
          <Stack.Screen
            name="AccountScreenPreferences"
            component={AccountScreenPreferences}
            options={{ title: 'Preferences' }}
          />
          <Stack.Screen name="AccountScreenDelete" component={AccountScreenDelete} />

          <Stack.Screen
            name="OrderTransferred"
            component={OrderTransferredScreen}
            options={{ title: 'Order Transferred' }}
          />

          <Stack.Screen name="OrderedRecipe" component={RecipeScreen} />

          <Stack.Screen name="Dummy" component={Dummy} />
          <Stack.Screen name="OnboardingScreen" component={OnboardingScreen} />
          <Stack.Screen name="Feedback" component={FeedbackScreen} />
          <Stack.Screen name="NotificationNagScreen" component={NotificationNagScreen} />
        </Stack.Navigator>
        {filtersMainDialogOpen && <FiltersMain />}
        {filtersSingleDialogType && <FiltersSingle />}
        {notLoggedInDialogOpen && <NotLoggedInDrawer />}

        <OnboardingRedirector />
        <NotificationLinkHandler />
      </NavigationContainer>
    </SafeAreaMobile>
  );
};

export default Main;
