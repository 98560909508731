import { useCallback, useContext, useMemo } from 'react';

import { RecipeLiveContext } from '../contexts/RecipeLiveContext';
import { UserContext } from '../contexts/UserContext';
import tracking from '../lib/tracking';

const useFavourites = () => {
  const { mapIds, loading: rLoading } = useContext(RecipeLiveContext);
  const { profile, busy: uLoading, updateProfile } = useContext(UserContext);

  const favourites = useMemo(() => profile?.favourites || [], [profile?.favourites]);

  const recipes = useMemo(() => mapIds(favourites), [favourites, mapIds]);

  const loading = useMemo(() => rLoading || uLoading, [rLoading, uLoading]);

  const add = (id) => {
    updateProfile({ favourites: [...favourites, id] });
    tracking.trackAddFavorite(id);
  };

  const remove = (id) => {
    updateProfile({ favourites: favourites.filter((a) => a !== id) });
    tracking.trackRemoveFavorite(id);
  };

  const isFavourite = useCallback((id) => id && favourites.includes(id), [favourites]);

  return { recipes, loading, isFavourite, add, remove };
};

export default useFavourites;
