import RNUxcam from 'react-native-ux-cam';
import { UXCamOcclusionType } from 'react-native-ux-cam/UXCamOcclusion';
import Constants from 'expo-constants';
import hash from 'hash.js';

import trackEvent from './trackEvent';
const ENV = Constants?.expoConfig?.extra?.env ?? 'staging';
const devMode = !!Constants?.expoConfig?.extra?.devMode;

console.log('ENV', ENV);
console.log('devMode', devMode);

if (!devMode) {
  RNUxcam.optIntoSchematicRecordings();

  const hideTextFields = {
    type: UXCamOcclusionType.OccludeAllTextFields,
  };
  const hideCartPage = {
    type: UXCamOcclusionType.Blur,
    screens: ['Cart'],
  };
  const configuration = {
    userAppKey: ENV === 'production' ? 'nb6lx8nfpff25r0' : 'f49k24eea4n662t',
    enableAutomaticScreenNameTagging: false,
    enableImprovedScreenCapture: true,
    occlusions: [hideTextFields, hideCartPage],
  };
  RNUxcam.setAutomaticScreenNameTagging(false);
  RNUxcam.tagScreenName('/');
  RNUxcam.startWithConfiguration(configuration);
}

const trackLogin = (email, profile, source) => {
  const emailHash = hash.sha256().update(email).digest('hex');
  const { retailer, householdSize, tags, onboardingAnswers } = profile;
  const traits = {
    email: emailHash,
    userId: emailHash,
    retailer,
    householdSize,
    tags,
    source,
    onboardingAnswers,
  };

  if (!devMode) {
    RNUxcam.setUserIdentity(emailHash);
    RNUxcam.setUserProperty('email', emailHash);
    RNUxcam.setUserProperty('retailer', retailer);
    RNUxcam.setUserProperty('householdSize', `${householdSize}`);
    RNUxcam.setUserProperty('tags', tags.join(','));
    RNUxcam.setUserProperty('source', source);
  }

  trackEvent('identify', traits);
};

const trackRegister = (source) => trackEvent('Register', { source });

const trackAddToCart = async (recipe, servings) => trackEvent('Add to Cart', { recipe, servings });

const trackRemoveFromCart = async (recipe) => trackEvent('Remove from Cart', { recipe });

const trackAddFavorite = async (recipe) => trackEvent('Favorite', { recipe });

const trackRemoveFavorite = async (recipe) => trackEvent('Unfavorite', { recipe });

const trackGetQuote = async (recipes, retailer) => trackEvent('Get quote', { recipes, retailer });

const trackViewCart = async (recipes, retailer) =>
  trackEvent('View cart', { recipes, retailer, numRecipes: recipes.length });

const trackContinueToSupermarket = async (recipes, retailer, products, total) =>
  trackEvent(CHECKOUT_EVENTS.PressCheckoutAtRetailer, { recipes, retailer, products, total });

const trackUpdateProductQuantity = (product) => trackEvent('Update Product Quantity', product);

const trackSearch = (keywords) => trackEvent('Search', { keywords });

const trackSkipOnboarding = () => trackEvent('Skip onboarding');

const trackPage = (properties) => trackEvent('page', properties);

const trackScreen = (properties) => {
  if (!devMode) {
    RNUxcam.tagScreenName(properties.name);
  }
  trackEvent('screen', properties);
};

const trackRating = (properties) => trackEvent('Rate App', properties);

const trackShareRecipe = (properties) => trackEvent('Share recipe', properties);

const trackNotificationsPermission = (properties) => trackEvent('Notifications Permission', properties);

const trackDeleteAccount = (properties) => trackEvent('Delete Account', properties);

const tracking = {
  trackLogin,
  trackRegister,
  trackAddToCart,
  trackRemoveFromCart,
  trackAddFavorite,
  trackRemoveFavorite,
  trackGetQuote,
  trackViewCart,
  trackContinueToSupermarket,
  trackUpdateProductQuantity,
  trackSearch,
  trackSkipOnboarding,
  trackPage,
  trackScreen,
  trackRating,
  trackEvent,
  trackShareRecipe,
  trackNotificationsPermission,
  trackDeleteAccount,
};

export const CHECKOUT_EVENTS = {
  ViewLogin: 'View Supermarket - Login',
  ViewBasket: 'View Supermarket - Basket',
  ViewSlotBooking: 'View Supermarket - Slot booking',
  ViewPayment: 'View Supermarket - Payment',
  ViewConfirmation: 'View Supermarket - Checkout confirmation',
  ViewPUConfirmation: 'View Plate up confirmation',
  PressCheckoutAtRetailer: 'Continue to Supermarket',
  PressCancel: 'Cancel',
  PressContinueToCheckout: 'No, continue to checkout',
  PressBackToBasket: 'Yes, take me back to my basket',
  PressAlreadyCheckedOut: "I've already checked out",
  PressCheckoutComplete: 'Checkout Complete',
  RateApp: 'Rate App',
};

export default tracking;
