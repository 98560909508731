import { Badge, Box } from 'native-base';

import FAProIcon from './FAProIcon';

const Tag = (props) => {
  const { icon, variant, label } = props;

  let bg = 'almond.400';

  if (variant === 'active') {
    bg = 'mustard.500';
  }

  if (variant === 'ghost') {
    bg = 'white';
  }

  return (
    <Badge
      bg={bg}
      shadow="none"
      variant="subtle"
      size="large"
      height="auto"
      paddingLeft="6px"
      paddingRight="6px"
      paddingTop="4px"
      paddingBottom="4px"
      borderRadius="34px"
      {...props}
      display="flex"
      flexDirection="row"
    >
      <Box
        _text={{
          fontFamily: 'body',
          fontWeight: 700,
          fontSize: 9,
          color: 'brightNavy.700',
        }}
        flexDirection="row"
        gap={1}
        alignItems="center"
      >
        {icon && <FAProIcon size={12} name={icon} />}
        {label}
      </Box>
    </Badge>
  );
};

export default Tag;
