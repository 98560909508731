import { useCallback, useEffect, useRef } from 'react';
import { useNavigation } from '@react-navigation/core';

import useExploreTabsOptions from './useExploreTabsOptions';

const useScrollHandler = () => {
  const navigation = useNavigation();
  const ref = useRef();
  const { exploreTabsOptions, onScrollChange } = useExploreTabsOptions();

  const handleScroll = (event) => {
    if (!navigation.isFocused()) return;
    const hasScrolled = event?.nativeEvent?.contentOffset?.y > 15;
    if (exploreTabsOptions?.hideIcons === hasScrolled) return;
    onScrollChange(hasScrolled);
  };

  const scrollToTop = useCallback(() => {
    if (!ref.current) return;
    ref.current.scrollToOffset({ offset: 0, animated: false });
    onScrollChange(false);
  }, [onScrollChange]);

  useEffect(() => {
    const unsubscribe = navigation.addListener('blur', () => {
      if (navigation?.getParent()?.getParent()?.getState().routes.length > 1) return;
      scrollToTop();
    });
    return unsubscribe;
  }, [navigation, scrollToTop]);

  return { ref, handleScroll, scrollToTop };
};

export default useScrollHandler;
