/* eslint-disable no-restricted-globals */
import Constants from 'expo-constants';
import { Button } from 'native-base';

import FAProIcon from '../../FAProIcon';

const BASE = Constants?.expoConfig?.extra?.apiUrl ?? 'http://localhost:8081';

const AppleLoginButtonWeb = (props) => {
  const handleClick = () => {
    const appleLoginHref =
      `https://appleid.apple.com/auth/authorize` +
      `?client_id=app.plateup.groceries` +
      `&redirect_uri=${BASE}/v8/apple/auth` +
      `&response_type=code%20id_token` +
      `&scope=name%20email` +
      `&state=${window.location.origin}/login/apple` +
      `&response_mode=form_post`;

    window.open(appleLoginHref, '_self');
  };

  return (
    <Button
      variant="solid"
      bg="black"
      size="large"
      leftIcon={<FAProIcon name="apple" />}
      onPress={handleClick}
      {...props}
    >
      Continue with Apple
    </Button>
  );
};

export default AppleLoginButtonWeb;
