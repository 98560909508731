import { Pressable } from 'react-native';
import { useLinkTo, useNavigation } from '@react-navigation/native';
import { Box } from 'native-base';

import Heading2 from './Heading/Heading2';
import PlaceholderSearchBox from './PlaceholderSearchBox';
import TopNavCartButton from './TopNavCartButton';
import TopNavIconButton from './TopNavIconButton';

const TopNav2 = (props) => {
  const { back, transparent, cart, title, search, color = 'white', onBackPress } = props;

  const navigation = useNavigation();
  const linkTo = useLinkTo();

  const handleBack = () => {
    if (onBackPress) {
      return onBackPress();
    }
    navigation.canGoBack() ? navigation.goBack() : linkTo('/home');
  };

  const handleSearch = () => {
    linkTo('/search');
  };

  let bg = color;
  if (transparent) {
    bg = undefined;
  }

  return (
    <Box height="72px" alignItems="center" gap={4} px={5} py={4} bg={bg} flexDirection="row" zIndex={1} width="100%">
      {back && <TopNavIconButton onPress={handleBack} icon="arrow-left" variant={transparent ? undefined : 'ghost'} />}
      <Box flex={1}>
        {search && (
          <Pressable flex={1} onPress={handleSearch}>
            <PlaceholderSearchBox />
          </Pressable>
        )}
        {title && (
          <Heading2 alignSelf="center" flex={1} isTruncated pt={2}>
            {title}
          </Heading2>
        )}
      </Box>
      <Box flexDirection="row" ml="auto" gap={4}>
        {cart && <TopNavCartButton />}
        {back && !cart && title && <Box width="36px" />}
      </Box>
    </Box>
  );
};

export default TopNav2;
