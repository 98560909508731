import { useContext } from 'react';
import { View } from 'native-base';
import { Box, Row } from 'native-base';

import CustomText from '../../../components/CustomText';
import SupermarketLogo from '../../../components/SupermarketLogo';
import { CommonContext } from '../../../contexts/CommonContext';
import fontSize from '../../../styles/fontSize';

const PricesTile = ({ costs }) => {
  const { retailers } = useContext(CommonContext);

  return (
    <View
      // eslint-disable-next-line react-native/no-inline-styles, react-native/no-color-literals
      style={{
        position: 'absolute',
        left: 190,
        bottom: 15,
        backgroundColor: 'white',
        borderRadius: 10,
        padding: 12,
        elevation: 10,
        gap: 2,
        shadowColor: 'black',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
      }}
    >
      {costs.map((c) => {
        const r = retailers.find((a) => a.id === c.id);
        if (!r) return null;
        return (
          <Row key={r.id} bgColor="almond.200" borderRadius={8} paddingY={0.25} paddingX={1} alignItems="center">
            <SupermarketLogo width="50px" retailer={r} />
            <Box flex={1} paddingX={1} />
            <CustomText fontFamily="body" fontWeight={700} fontSize={fontSize(10)}>
              £{c.cost.toFixed(2)} p/s
            </CustomText>
          </Row>
        );
      })}
    </View>
  );
};

export default PricesTile;
