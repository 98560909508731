import { memo, useContext, useMemo } from 'react';
import { useLinkTo } from '@react-navigation/native';
import { Box, Center, Pressable } from 'native-base';

import { RecipeLiveContext } from '../contexts/RecipeLiveContext';
import { getCuisineTagCountFromRecipeList, UCFirst } from '../lib/util';

import Heading2 from './Heading/Heading2';
import ImageWrapper from './ImageWrapper';
import MurkyGradient from './MurkyGradient';
import Tag from './Tag';

export const CHEF_CARD_HEIGHT = 223;
export const CHEF_CARD_WIDTH = 168;
export const CHEF_CARD_MARGIN = 2;

const ChefCard = (props) => {
  const linkTo = useLinkTo();

  const { recipes } = useContext(RecipeLiveContext);

  const { chef, showLoaderSet } = props;

  const recipelist = useMemo(() => recipes.filter((a) => a.chefId === chef?.id), [chef, recipes]);

  const allCuisineTags = useMemo(() => getCuisineTagCountFromRecipeList(recipelist), [recipelist]);

  const primaryCuisineTag = useMemo(() => {
    if (!allCuisineTags.length) return undefined;
    return <Tag label={UCFirst(allCuisineTags[0][0])} />;
  }, [allCuisineTags]);

  const secondaryCuisineTag = useMemo(() => {
    if (allCuisineTags.length > 2) {
      return <Tag label={`+${allCuisineTags.length - 1} more`} />;
    } else if (allCuisineTags.length === 2) {
      return <Tag label={UCFirst(allCuisineTags[1][0])} />;
    }
    return undefined;
  }, [allCuisineTags]);

  const handleRedirect = () => {
    showLoaderSet && showLoaderSet(false);
    linkTo(`/chef/${id}`);
  };

  if (!chef) return null;

  const { id, name, avatarPill } = chef;

  return (
    <Pressable onPress={handleRedirect} width={CHEF_CARD_WIDTH} height={CHEF_CARD_HEIGHT} margin={CHEF_CARD_MARGIN}>
      <ImageWrapper
        displayWidth={CHEF_CARD_WIDTH}
        src={avatarPill}
        alt={name}
        width="100%"
        height="100%"
        resizeMode="cover"
        borderRadius={24}
        position="absolute"
        top="0"
        left="0"
      />
      <Center height="100%" width="100%" justifyContent="flex-end">
        <MurkyGradient py="20px" borderBottomRightRadius={24} borderBottomLeftRadius={24} gap={1}>
          <Heading2 textAlign="center" color="white">
            {name}
          </Heading2>
          <Box flexDirection="row" gap={1}>
            {primaryCuisineTag}
            {secondaryCuisineTag}
          </Box>
        </MurkyGradient>
      </Center>
    </Pressable>
  );
};

export default memo(ChefCard);
