import React, { useCallback, useEffect, useState } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import jwtDecode from 'jwt-decode';

import api from '../lib/api';
import { userRegistrationTrigger } from '../lib/background-task/user-registration';
import tracking from '../lib/tracking';

const UserContext = React.createContext({});

let updateProfileTimeout;

let method = 'email';

const UserProvider = (props) => {
  const { children } = props;

  const [busy, busySet] = useState(true);
  const [registerBusy, registerBusySet] = useState(false);
  const [token, tokenSet] = useState();
  const [email, emailSet] = useState();

  const [profile, profileSet] = useState();

  const [registerError, registerErrorSet] = useState('');
  const [loginError, loginErrorSet] = useState('');

  useEffect(() => {
    AsyncStorage.getItem('login-token').then((i) => {
      if (i) tokenSet(i);
    });
  }, []);

  const logout = useCallback(() => {
    tokenSet(null);
  }, []);

  useEffect(() => {
    api.rest.eventemitter.on('unauthorized', logout);
    return () => {
      api.rest.eventemitter.removeListener('unauthorized', logout);
    };
  }, [logout]);

  useEffect(() => {
    if (!profile) return;

    if (updateProfileTimeout) clearTimeout(updateProfileTimeout);
    updateProfileTimeout = setTimeout(() => {
      delete profile.notifications; // notifications are updated directly using api.user.updateProfile()
      api.user.updateProfile(profile);
    }, 2000);
  }, [profile]);

  const loadUserFromToken = useCallback(async () => {
    if (token) {
      const decoded = jwtDecode(token);
      if (!decoded) return tokenSet(null);
      emailSet(decoded.email);
      AsyncStorage.setItem('login-token', token);

      api.rest.addHeader('Authorization', `Bearer ${token}`);
      busySet(true);
      await api.user
        .getProfile()
        .then((p) => {
          profileSet(p);
          tracking.trackLogin(decoded.email, p, method);
        })
        .finally(() => busySet(false));
    } else {
      AsyncStorage.removeItem('login-token');
      api.rest.removeHeaders('Authorization');
      profileSet(null);
      busySet(false);
    }
  }, [token]);

  useEffect(() => {
    loadUserFromToken();
  }, [loadUserFromToken]);

  const clearErrors = useCallback(() => {
    registerErrorSet('');
    loginErrorSet('');
  }, []);

  const login = useCallback(async (e, password) => {
    try {
      loginErrorSet('');
      busySet(true);
      const res = await api.user.login(e, password);
      if (res.error) throw new Error(res.error);
      tokenSet(res.token);
      // We'll set busy=false after the profile has been fetched from our api (see loadUserFromToken)
    } catch (err) {
      loginErrorSet(err.message);
      throw err;
    }
  }, []);

  const register = useCallback(async (e, password, initialProfile) => {
    registerBusySet(true);
    try {
      registerErrorSet('');
      busySet(true);
      const onboardingData = JSON.parse(await AsyncStorage.getItem('onboarding-data'));

      const res = await api.user.register(e, password, { ...onboardingData, ...initialProfile });
      busySet(false);
      if (res.error) throw new Error(res.error);
      tokenSet(res.token);
      tracking.trackRegister('email');
      userRegistrationTrigger();
    } catch (err) {
      registerErrorSet(err.message);
      throw err;
    } finally {
      registerBusySet(false);
    }
  }, []);

  const updateProfile = useCallback(
    async (partial) => {
      if (!profile) return;
      if (!partial) return;
      profileSet({ ...profile, ...partial });
    },
    [profile],
  );

  const deleteAccount = useCallback(async () => {
    busySet(true);
    await api.user.deleteAccount();
    busySet(false);
    logout();
  }, [logout]);

  const loginFacebook = useCallback(async (tok) => {
    try {
      loginErrorSet('');
      const onboardingData = JSON.parse(await AsyncStorage.getItem('onboarding-data'));
      const res = await api.facebook.login(tok, onboardingData ?? undefined);
      if (res.error) throw new Error(res.error);
      method = 'facebook';
      tokenSet(res.token);
      if (res?.accountCreated) {
        tracking.trackRegister('facebook');
      }
    } catch (err) {
      loginErrorSet(err.message);
      throw err;
    }
  }, []);

  const loginApple = useCallback(async (code, tok) => {
    try {
      loginErrorSet('');
      const onboardingData = JSON.parse(await AsyncStorage.getItem('onboarding-data'));
      const res = await api.apple.login(code, tok, onboardingData ?? undefined);
      if (res.error) throw new Error(res.error);
      method = 'apple';
      tokenSet(res.token);
      if (res?.accountCreated) {
        tracking.trackRegister('apple');
      }
    } catch (err) {
      loginErrorSet(err.message);
      throw err;
    }
  }, []);

  const resetPassword = useCallback(async (e) => {
    busySet(true);
    await api.user.resetPassword(e);
    busySet(false);
  }, []);

  const resetPasswordConfirm = useCallback(async (e, code, password) => {
    busySet(true);
    await api.user.resetPasswordConfirm(e, code, password);
    busySet(false);
  }, []);

  const value = {
    email,
    busy,
    registerBusy,
    token,
    profile,
    updateProfile,
    login,
    loginError,
    register,
    registerError,
    logout,
    clearErrors,
    deleteAccount,
    loginFacebook,
    loginApple,
    resetPassword,
    resetPasswordConfirm,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export { UserContext, UserProvider };
