import { Platform } from 'react-native';

import FacebookLoginButtonAndroid from './FacebookLoginButtonAndroid';
import FacebookLoginButtonIos from './FacebookLoginButtonIos';
import FacebookLoginButtonWeb from './FacebookLoginButtonWeb';

const FacebookLoginButton = (props) => {
  if (Platform.OS === 'ios') {
    return <FacebookLoginButtonIos {...props} />;
  }

  if (Platform.OS === 'android') {
    return <FacebookLoginButtonAndroid {...props} />;
  }

  return <FacebookLoginButtonWeb {...props} />;
};

export default FacebookLoginButton;
