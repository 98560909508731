import { Text } from 'native-base';

import fontSize from '../../styles/fontSize';

const Body2ExtraBold = (props) => (
  <Text fontFamily="body" fontWeight={800} fontSize={fontSize(14)} {...props}>
    {props.children}
  </Text>
);

export default Body2ExtraBold;
