import { Platform } from 'react-native';
import Constants from 'expo-constants';

export const sentenceCase = (s) => s.charAt(0).toUpperCase() + s.slice(1);

export const friendlyNumber = (number, unit) => {
  const num = Math.floor(number);
  const div = number % 1;

  if (['splash', 'pinch', 'dash', 'to serve', 'to garnish', 'to taste'].includes(unit)) return '';

  if (num > 100) return `${Math.ceil(num / 10) * 10}`;
  if (num > 0 && (unit === 'g' || unit === 'ml')) return `${Math.ceil(num / 5) * 5}`;

  if (div < 0.01) return `${num}`;
  if (div < 0.3) return `${num > 0 ? `${num} ` : ''}¼`;
  if (div < 0.4) return `${num > 0 ? `${num} ` : ''}⅓`;
  if (div < 0.6) return `${num > 0 ? `${num} ` : ''}½`;
  if (div < 0.7) return `${num > 0 ? `${num} ` : ''}⅔`;
  if (div < 0.85) return `${num > 0 ? `${num} ` : ''}¾`;
  return `${num + 1}`;
};

export const seoFriendlySlug = (string) =>
  string
    .normalize('NFD') // Change diacritics
    .replace(/[\u0300-\u036f]/g, '') // Remove illegal characters
    .replace(/\s+/g, '-') // Change whitespace to dashes
    .toLowerCase() // Change to lowercase
    .replace(/&/g, '-and-') // Replace ampersand
    .replace(/[^a-z0-9-]/g, '') // Remove anything that is not a letter, number or dash
    .replace(/-+/g, '-') // Remove duplicate dashes
    .replace(/^-*/, '') // Remove starting dashes
    .replace(/-*$/, ''); // Remove trailing dashes;

export const detectMobileUA = () => !!navigator.userAgent.toLowerCase().match(/mobile/i);

export const arrayTo2D = (array, columns) => {
  const result = [];
  for (let i = 0; i < array.length; i += columns) {
    result.push(array.slice(i, i + columns));
  }
  return result;
};

export const createId = (a) =>
  a
    .toLowerCase()
    .replaceAll(' ', '-')
    .replaceAll(/[^a-z1-9-_]/g, '')
    .replaceAll(/-+/g, '-');

export const shuffleArray = (array) => {
  let j;
  let x;
  let i;
  const shuffled = [...array];
  for (i = array.length - 1; i > 0; i--) {
    j = Math.floor(Math.random() * (i + 1));
    x = shuffled[i];
    shuffled[i] = shuffled[j];
    shuffled[j] = x;
  }
  return shuffled;
};

export const byTagOrdering = (a, b) => {
  if (a.ordering || b.ordering) {
    return (a.ordering || Infinity) - (b.ordering || Infinity);
  }
  return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
};

export const isIOSApp = () => Platform.OS === 'ios';

export const isAndroidApp = () => Platform.OS === 'android';

export const isMobileApp = isIOSApp() || isAndroidApp();

export const isDevelopmentMode = () => Constants?.expoConfig?.extra?.devMode;

export const UCFirst = (string) => string.charAt(0).toUpperCase() + string.slice(1);

export const getTimeAsString = (time) => {
  if (!time) return;
  const timeString = `${time}mins`;
  if (time > 59) {
    const hours = Math.floor(time / 60);
    const mins = time - hours * 60;
    return `${hours > 0 ? `${hours}h` : ''} ${mins > 0 ? `${mins}mins` : ''}`.trim();
  }

  return timeString;
};

export const getCuisineTagCountFromRecipeList = (recipeList) =>
  Object.entries(
    recipeList.reduce((prev, curr) => {
      const cuisineTags = curr.tags.filter((tag) => tag.startsWith('cuisine__'));
      if (!cuisineTags.length) return prev;
      // Reduce not necessarry but left in so we can convert back to cuisineTags.reduce if needed
      return [cuisineTags[0]].reduce(
        (prev2, curr2) => {
          const cuisine = curr2.replace('cuisine__', '');
          return { ...prev2, [cuisine]: (prev2?.[cuisine] || 0) + 1 };
        },
        { ...prev },
      );
    }, {}),
  ).sort(([, a], [, b]) => b - a);

export const RE_EMAIL =
  // eslint-disable-next-line max-len
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const ordinalNumberSuffix = function (d) {
  if (d > 3 && d < 21) return 'th';
  switch (d % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
};

export const formatDate = (date) => {
  const d = new Date(date);
  const day = d.getDate() + ordinalNumberSuffix(d.getDate());
  const month = d.toLocaleString('default', { month: 'long' });
  const year = d.getFullYear();
  return `${day} ${month} ${year}`;
};
