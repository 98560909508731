import rest from './rest';

const inProgress = {};

const list2 = async (table) => {
  const res = await rest.get(`/v8/${table}`);
  const data = await res.json();
  return data;
};

const list = (table) => async () => {
  if (inProgress[table]) return inProgress[table];
  const p = list2(table);
  inProgress[table] = p;
  const data = await p;
  delete inProgress[table];
  return data;
};

const get = (table) => async (id) => {
  const res = await rest.get(`/v8/${table}/${id}`);
  const data = await res.json();
  return data;
};

const post = (table) => async (data) => {
  await rest.post(`/v8/${table}`, data);
};

const put = (table) => async (id, data) => {
  await rest.put(`/v8/${table}/${id}`, data);
};

const del = (table) => async (id) => {
  await rest.del(`/v8/${table}/${id}`);
};

const crud = (table) => ({
  list: list(table),
  get: get(table),
  post: post(table),
  put: put(table),
  del: del(table),
});

export default crud;
