import { useContext, useEffect } from 'react';
import { useLinkTo } from '@react-navigation/native';
import { Button } from 'native-base';

import { UserContext } from '../../contexts/UserContext';

import LoginContainer from './components/LoginContainer';

const LogoutScreen = () => {
  const { profile, logout } = useContext(UserContext);
  const linkTo = useLinkTo();

  useEffect(() => {
    if (profile) logout();
  }, [logout, profile]);

  return (
    <LoginContainer title="Log Out" subtitle="You're now logged out">
      <Button width="100%" onPress={() => linkTo('/home')}>
        Home
      </Button>
      <Button variant="outline" width="100%" onPress={() => linkTo('/login')}>
        Login
      </Button>
    </LoginContainer>
  );
};

export default LogoutScreen;
