import { useWindowDimensions } from 'react-native';
import { Image } from 'native-base';

const ImageWrapper = (props) => {
  const { src, displayWidth = 1200 } = props;
  const { width } = useWindowDimensions();

  const w = Math.floor(Math.min(displayWidth, width));

  if (!src) return null;

  let fixedSrc = src;
  if (fixedSrc && fixedSrc.includes('cdn.plateup.app/')) {
    fixedSrc += `?w=${w}`;
  }

  return <Image {...props} src={fixedSrc} />;
};

export default ImageWrapper;
