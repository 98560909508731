import rest from './rest';

const login = async (code, token, profile) => {
  const body = {
    authorization_code: code,
    access_token: token,
    use_alt_key: true,
    profile,
  };

  const loginRes = await rest.postNoCheck('/v8/user/apple/login', body);
  const data = await loginRes.json();

  if (!loginRes.ok) {
    const dataArray = data;
    if (dataArray[0]) throw new Error(dataArray[0]);
    throw new Error('Login failed');
  }

  return data;
};

const apple = { login };

export default apple;
