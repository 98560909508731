import React from 'react';
import { Box, useTheme } from 'native-base';

import Body3 from './Body/Body3';
import FAProIcon from './FAProIcon';

const PlaceholderSearchBox = () => {
  const { colors } = useTheme();
  return (
    <Box
      flex="1"
      borderRadius="100px"
      pr="10px"
      pl="16px"
      bgColor="white"
      flexDirection="row"
      gap={4}
      alignItems="center"
      borderStyle="solid"
      borderWidth="1px"
      borderColor="#E3E3E3"
    >
      <FAProIcon name="search" size={16} />
      <Body3 color={colors.mayo[700]} isTruncated maxWidth="80%">
        Recipes, chefs, ingredients
      </Body3>
    </Box>
  );
};

export default PlaceholderSearchBox;
