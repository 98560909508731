import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Button, IconButton, useDisclose } from 'native-base';

import BottomDrawer from './BottomDrawer/BottomDrawer';
import Heading2 from './Heading/Heading2';
import Counter from './Counter';
import FAProIcon from './FAProIcon';

const CounterButton = (props) => {
  const { variant, size, value, disabled, onChange } = props;

  const { isOpen, onOpen, onClose } = useDisclose();

  const [mutatedValue, mutatedValueSet] = useState(value);

  const label = useMemo(() => `${value} serving${value > 1 ? 's' : ''}`, [value]);

  useEffect(() => {
    mutatedValueSet(value);
  }, [value]);

  const handleChange = useCallback(() => {
    onChange(mutatedValue);
    onClose();
  }, [mutatedValue, onChange, onClose]);

  const handleClose = () => {
    mutatedValueSet(value);
    onClose();
  };

  return (
    <>
      <Button
        size={size}
        variant={disabled ? 'unstyled' : variant}
        onPress={onOpen}
        endIcon={disabled ? undefined : <FAProIcon name="chevron-down" />}
        isDisabled={disabled}
      >
        {label}
      </Button>
      {isOpen && (
        <BottomDrawer isOpen={isOpen} onClose={handleClose} pt={3}>
          <Box width="100%" paddingX={5}>
            <Box alignItems="center" justifyContent="space-between" flexDirection="row">
              <Box flex="1" />
              <Heading2 flex="1" textAlign="center" ml="auto">
                Choose
              </Heading2>
              <Box flex="1">
                <IconButton
                  ml="auto"
                  variant="ghost"
                  size="large"
                  icon={<FAProIcon name="close" />}
                  onPress={handleClose}
                />
              </Box>
            </Box>
            <Box paddingY={4}>
              <Counter value={mutatedValue} onChange={mutatedValueSet} />
            </Box>
            <Box
              width="100%"
              justifyContent="space-between"
              flexDirection="row"
              borderTopColor="#E3E3E3"
              borderTopStyle="solid"
              borderTopWidth="1px"
              paddingTop={4}
              pb={1.5}
            >
              <Button variant="unstyled" size="large" onPress={handleClose}>
                Cancel
              </Button>
              <Button variant="solid" size="large" onPress={handleChange}>
                Done
              </Button>
            </Box>
          </Box>
        </BottomDrawer>
      )}
    </>
  );
};

export default CounterButton;
