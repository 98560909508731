import rest from './rest';

const get = async (payload) => {
  const res = await rest.post(`/v8/quote`, payload);
  if (!res.ok) throw new Error('Error getting v8quote');
  const data = await res.json();
  return data;
};

const quote = { get };
export default quote;
