import React, { useState } from 'react';
import { Box, ScrollView } from 'native-base';

import CheckoutBottom from '../../components/BottomDrawer/CheckoutBottom';
import DiscountBox from '../../components/DiscountBox';
import Loading from '../../components/Loading';
import TopNav2 from '../../components/TopNav2';

import CartScreenHeader from './CartScreenHeader';
import ProductList from './ProductList';

const CartBasketScreen = (props) => {
  const {
    products,
    retailer,
    handleCheckout,
    isLoading,
    ProductListComponent = ProductList,
    productListComponentProps = {},
  } = props;

  const [isDiscountOpen, isDiscountOpenSet] = useState(false);

  const onPressCheckout = () => {
    retailer.showDiscount ? isDiscountOpenSet(true) : handleCheckout();
  };

  const onCloseDiscountDrawer = () => {
    isDiscountOpenSet(false);
  };

  return (
    <Box bg="white">
      <TopNav2 back title="Basket" />

      {isLoading ? (
        <Box height="90%">
          <Loading />
        </Box>
      ) : (
        <>
          <ScrollView mb={100}>
            <CartScreenHeader />
            <ProductListComponent list={products} {...productListComponentProps} />
          </ScrollView>
          <CheckoutBottom products={products} retailer={retailer} onPress={onPressCheckout} isLoading={isLoading} />
          <DiscountBox
            isOpen={isDiscountOpen}
            retailer={retailer}
            onPressNext={handleCheckout}
            onClose={onCloseDiscountDrawer}
          />
        </>
      )}
    </Box>
  );
};

export default CartBasketScreen;
