/* eslint-disable max-len */

import Svg, { Path } from 'react-native-svg';

const PUIcon = () => (
  <Svg width="56" height="64" viewBox="0 0 56 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      id="Vector"
      d="M47.8824 30.756C46.6337 28.3366 44.8671 26.4731 42.585 25.1652C40.3028 23.8574 37.6754 23.2047 34.7097 23.2047C31.7441 23.2047 28.9132 23.8976 26.572 25.2835C24.2307 26.6694 22.5326 28.611 21.4779 31.1083H21.4187V24.0821H8.83253V63.9999H21.4779V47.2089H21.537C22.5516 49.6684 24.2496 51.6005 26.6311 53.0053C29.0102 54.4101 31.685 55.1125 34.653 55.1125C37.621 55.1125 40.3028 54.4574 42.585 53.1519C44.8671 51.8441 46.6337 49.9806 47.8824 47.5612C49.1311 45.1419 49.7555 42.3513 49.7555 39.1893C49.7555 36.0274 49.1311 33.18 47.8824 30.7583V30.756ZM37.3064 42.6114C36.5638 43.607 35.5374 44.3875 34.2249 44.9527C32.9124 45.5179 31.4083 45.8017 29.715 45.8017C28.0217 45.8017 26.5152 45.5203 25.205 44.9527C23.8925 44.3875 22.8637 43.607 22.1235 42.6114C21.3809 41.6158 21.012 40.4735 21.012 39.187C21.012 37.9004 21.3833 36.7487 22.1235 35.7318C22.8637 34.7172 23.8925 33.9368 25.205 33.3905C26.5176 32.8442 28.0217 32.5699 29.715 32.5699C31.4083 32.5699 32.9124 32.8442 34.2249 33.3905C35.5374 33.9368 36.5638 34.7172 37.3064 35.7318C38.0466 36.7464 38.4179 37.8981 38.4179 39.187C38.4179 40.4759 38.0466 41.6158 37.3064 42.6114Z"
      fill="#291441"
    />
    <Path
      id="Vector_2"
      d="M45.8349 1.80681C40.9419 0.640899 34.4738 0 27.6249 0C20.776 0 14.3103 0.640899 9.41484 1.80681C3.18086 3.292 0.151367 5.4772 0.151367 8.4925C0.151367 11.5078 3.18086 13.693 9.41484 15.1782C14.3079 16.3441 20.776 16.985 27.6249 16.985C34.4738 16.985 40.9395 16.3441 45.8349 15.1782C52.0689 13.693 55.0984 11.5078 55.0984 8.4925C55.0984 5.4772 52.0689 3.292 45.8349 1.80681ZM49.4722 8.49013C48.7745 8.89454 47.1782 9.63949 44.0494 10.3348C39.6009 11.321 33.769 11.8649 27.6249 11.8649C21.4808 11.8649 15.6512 11.321 11.2027 10.3348C8.07865 9.64186 6.48231 8.8969 5.78229 8.4925C6.48231 8.08809 8.07628 7.34314 11.2027 6.65021C15.6488 5.66403 21.4831 5.12009 27.6249 5.12009C33.7666 5.12009 39.6009 5.66403 44.0494 6.65021C47.1735 7.34314 48.7698 8.08573 49.4722 8.49013Z"
      fill="#291441"
    />
  </Svg>
);

export default PUIcon;
