import React from 'react';
import { Box, Button, Center, ScrollView, useTheme } from 'native-base';

import Body2Bold from '../../components/Body/Body2Bold';
import FAProIcon from '../../components/FAProIcon';
import Heading3Bold from '../../components/Heading/Heading3Bold';
import InfoTipLarge from '../../components/InfoTipLarge';
import PUIconGradient from '../../components/PUIconGradient';
import SupermarketLogo from '../../components/SupermarketLogo';
import TopNav2 from '../../components/TopNav2';
import { sharedCssStyles } from '../../styles/utils';

const CartExplainerScreen = ({ retailer, handleBack, handleComplete, warning }) => {
  const { colors } = useTheme();

  return (
    <ScrollView contentContainerStyle={sharedCssStyles.scrollViewContainer}>
      <Box background={colors.almond[200]} height="100%">
        <TopNav2 transparent back onBackPress={handleBack} />
        <Box justifyContent="space-between" py={5} flex={1}>
          <Box gap={20} px={5} py={3}>
            <Center flexDirection="row" gap={2}>
              <PUIconGradient />
              <Center width={36} height={36}>
                <FAProIcon name="arrow-right" size={24} color={colors.blueCheese[700]} />
              </Center>
              <Center bgColor="white" width={65} height={65} borderRadius={6.5}>
                <SupermarketLogo retailer={retailer} />
              </Center>
            </Center>
            <Box gap={4}>
              <Heading3Bold textAlign="center" color={colors.brightNavy[500]}>
                Let's log in to your {retailer.displayName} account
              </Heading3Bold>
              <Body2Bold textAlign="center" color={colors.mushroom[500]}>
                Once you've logged in, we'll automatically add the ingredients to your {retailer.displayName} basket.
              </Body2Bold>
            </Box>
            {warning && <InfoTipLarge withIcon>{warning}</InfoTipLarge>}
          </Box>
          <Box px={5}>
            <Button variant="solid" size="fullWidth" onPress={handleComplete}>
              {`Continue to ${retailer.displayName}`}
            </Button>
          </Box>
        </Box>
      </Box>
    </ScrollView>
  );
};

export default CartExplainerScreen;
