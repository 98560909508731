import React, { useContext, useEffect, useState } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { UserContext } from './UserContext';

const VisitorContext = React.createContext({});

const VisitorProvider = (props) => {
  const { children } = props;

  const { profile, updateProfile } = useContext(UserContext);
  const [visitorCart, visitorCartSet] = useState([]);
  const [visitorHouseholdSize, visitorHouseholdSizeSet] = useState(4);

  useEffect(() => {
    AsyncStorage.getItem('visitor-cart').then((value) => {
      if (value) {
        visitorCartSet(JSON.parse(value));
      }
    });
    AsyncStorage.getItem('householdSize').then((value) => {
      if (value) {
        visitorHouseholdSizeSet(JSON.parse(value));
      }
    });
  }, []);

  useEffect(() => {
    AsyncStorage.setItem('visitor-cart', JSON.stringify(visitorCart));
  }, [visitorCart]);

  useEffect(() => {
    if (profile && visitorCart.length) {
      const existing = profile.cart.map((a) => a.id);
      updateProfile({ cart: [...profile.cart, ...visitorCart.filter((a) => !existing.includes(a.id))] });
      visitorCartSet([]);
    }
  }, [profile, updateProfile, visitorCart]);

  useEffect(() => {
    AsyncStorage.setItem('householdSize', JSON.stringify(visitorHouseholdSize));
  }, [visitorHouseholdSize]);

  const value = {
    visitorCart,
    visitorCartSet,
    visitorHouseholdSize,
    visitorHouseholdSizeSet,
  };

  return <VisitorContext.Provider value={value}>{children}</VisitorContext.Provider>;
};

export { VisitorContext, VisitorProvider };
