import rest from './rest';

const login = async (access_token, profile) => {
  const res = await fetch(
    `https://graph.facebook.com/me?fields=email,first_name,last_name&access_token=${access_token}`,
  );

  const user = await res.json();

  const body = {
    user_id: user.id.toLowerCase(),
    access_token,
    email: user.email ? user.email.toLowerCase() : '',
    first_name: user.first_name,
    last_name: user.last_name,
    profile,
  };

  const loginRes = await rest.postNoCheck('/v8/user/facebook/login', body);
  const data = await loginRes.json();

  if (!loginRes.ok) {
    const dataArray = data;
    if (dataArray[0]) throw new Error(dataArray[0]);
    throw new Error('Login failed');
  }

  return data;
};

const facebook = { login };

export default facebook;

// Facebook test user
// Email:
// tswlgmdjkc_1663171725@tfbnw.net
// Password:
// Test123!
