import { Heading } from 'native-base';

import fontSize from '../../styles/fontSize';

const Heading2 = (props) => (
  <Heading fontFamily="heading" fontWeight={600} fontSize={fontSize(18)} {...props}>
    {props.children}
  </Heading>
);

export default Heading2;
