import React, { useContext } from 'react';
import { Pressable } from 'react-native';
import { Box, Row } from 'native-base';

import Body3Bold from '../../components/Body/Body3Bold';
import SupermarketLogo from '../../components/SupermarketLogo';
import { CommonContext } from '../../contexts/CommonContext';
import { QuoteContext } from '../../contexts/QuoteContext';
import useCart from '../../hooks/useCart';

import CartRecipeItemList from './CartRecipeItemList';

const CartScreenHeader = ({ withRecipes = true }) => {
  const { retailerTotals, retailer, changeRetailer } = useContext(QuoteContext);
  const { retailers } = useContext(CommonContext);

  const { recipes } = useCart();

  const retailerButtons = retailers.map((r) => {
    const t = retailerTotals[r.id] || 0;

    return (
      <Pressable key={r.id} onPress={() => changeRetailer(r)}>
        <Box
          bg={retailer.id === r.id ? 'almond.200' : 'white'}
          borderBottomWidth={retailer.id === r.id ? 2 : 0}
          borderColor="mustard.500"
          borderStyle="solid"
          padding={2}
          borderTopLeftRadius={8}
          borderTopRadius={8}
          alignItems="center"
          justifyContent="center"
        >
          <Box paddingY={2}>
            <SupermarketLogo retailer={r} />
          </Box>
          {!!t && <Body3Bold>{`£${t.toFixed(2)}`}</Body3Bold>}
          {!t && <Body3Bold>{`£ -.--`}</Body3Bold>}
        </Box>
      </Pressable>
    );
  });

  return (
    <Box>
      <Row padding={2}>{retailerButtons}</Row>
      {withRecipes && <CartRecipeItemList list={recipes} />}
    </Box>
  );
};

export default CartScreenHeader;
