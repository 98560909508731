import { useRef } from 'react';
import { Animated } from 'react-native';

const useAnimatedHeader = (headerHeight, navBarHeight) => {
  const scroll = useRef(new Animated.Value(0)).current;

  const onScroll = Animated.event([{ nativeEvent: { contentOffset: { y: scroll } } }], { useNativeDriver: true });

  const hightDifference = headerHeight - navBarHeight;

  const topNavOpacity = scroll.interpolate({
    inputRange: [0, hightDifference * 0.7, hightDifference],
    outputRange: [1, 1, 0],
    extrapolate: 'clamp',
  });
  const replacementTopNavOpacity = scroll.interpolate({
    inputRange: [0, hightDifference * 0.5, hightDifference],
    outputRange: [0, 0, 1],
    extrapolate: 'clamp',
  });

  return { scroll, onScroll, hightDifference, topNavOpacity, replacementTopNavOpacity };
};

export default useAnimatedHeader;
