import React from 'react';
import { IconButton } from 'native-base';

import FAProIcon from './FAProIcon';

const TopNavIconButton = ({ onPress, icon, outline, color, solid, children, ...props }) => (
  <IconButton
    variant="ghost"
    size="medium"
    onPress={onPress}
    icon={<FAProIcon name={icon} color={color} solid={solid} />}
    borderWidth={outline ? 1 : undefined}
    borderColor="#E3E3E3"
    {...props}
  >
    {children}
  </IconButton>
);

export default TopNavIconButton;
