import { useContext } from 'react';
import { ScrollView } from 'react-native';
import { useIsFocused, useLinkTo } from '@react-navigation/native';
import { Box, Button, Center, Column } from 'native-base';

import Body3 from '../components/Body/Body3';
import Heading3Bold from '../components/Heading/Heading3Bold';
import Loading from '../components/Loading';
import NotLoggedIn from '../components/NotLoggedIn';
import RecipeTile from '../components/RecipeTile';
import RecipeTileSmall from '../components/RecipeTileSmall';
import SubHeading1 from '../components/SubHeading/SubHeading1';
import SubHeading2 from '../components/SubHeading/SubHeading2';
import { RecipeLiveContext } from '../contexts/RecipeLiveContext';
import { UserContext } from '../contexts/UserContext';
import useOrdered from '../hooks/useOrdered';
import { formatDate } from '../lib/util';

const OrderedScreen = () => {
  const { legacyOrdered, orders, busy } = useOrdered();
  const linkTo = useLinkTo();
  const { mapIds, loading } = useContext(RecipeLiveContext);

  const { profile } = useContext(UserContext);

  const isFocused = useIsFocused();

  if (!isFocused) return <Loading />;

  if (!profile) {
    return <NotLoggedIn />;
  }

  if (loading || busy) {
    return <Loading />;
  }

  if (!orders.length && !legacyOrdered.length) {
    return (
      <Center height="100%">
        <Column space={4} justifyContent="center" alignItems="center">
          <SubHeading1>You haven't ordered any recipes yet...</SubHeading1>
          <Body3>Head over to the explore page to get started</Body3>
          <Button onPress={() => linkTo('/recipe')}> Explore</Button>
        </Column>
      </Center>
    );
  }

  const lastOrder = orders[0];

  const lastRecipes = lastOrder ? mapIds(lastOrder.recipes.map((r) => r.id)) : [];

  const cookedRecipes = lastOrder ? lastOrder.recipes.filter((r) => r.isCooked).map((r) => r.id) : [];

  lastRecipes.sort((a, b) => {
    if (cookedRecipes.includes(a.id) && cookedRecipes.includes(b.id)) {
      return 0;
    }
    if (!cookedRecipes.includes(a.id) && !cookedRecipes.includes(b.id)) {
      return 0;
    }
    if (cookedRecipes.includes(a.id)) {
      return 1;
    }
    return -1;
  });

  const cookItems = lastRecipes.map((a) => <RecipeTile key={a.id} recipe={a} />);

  const orderItems = orders.slice(1).map((a) => {
    const list = mapIds(a.recipes.map((b) => b.id)).map((b) => <RecipeTileSmall key={b.id} recipe={b} />);
    return (
      <Box key={a.id}>
        <Body3 key={a.id} paddingX={5} paddingY={3}>
          {formatDate(a.date)}
        </Body3>

        <ScrollView horizontal>
          <Box width={4} />
          {list}
          <Box width={4} />
        </ScrollView>
      </Box>
    );
  });

  const legacyList = [{ id: 'legacy', recipes: legacyOrdered }].map((a) => {
    const list = mapIds(a.recipes).map((b) => <RecipeTileSmall key={b.id} recipe={b} />);
    if (!list.length) return null;

    return (
      <Box key={a.id}>
        <Body3 key={a.id} paddingX={5} paddingY={3}>
          {`Older orders`}
        </Body3>

        <ScrollView horizontal>
          <Box width={4} />
          {list}
          <Box width={4} />
        </ScrollView>
      </Box>
    );
  });

  return (
    <Box height="100%" bg="white">
      <ScrollView>
        <Heading3Bold paddingX={5} paddingBottom={2}>
          Cook
        </Heading3Bold>
        <Box flexDirection="row" flexWrap="wrap" width="100%" paddingX={4}>
          {cookItems}
        </Box>
        <SubHeading2 paddingX={5} paddingTop={4}>
          Previous orders
        </SubHeading2>
        {orderItems}
        {legacyList}
      </ScrollView>
    </Box>
  );
};

export default OrderedScreen;
