/* eslint-disable no-restricted-globals */
import { useContext, useEffect } from 'react';
import { useLinkTo } from '@react-navigation/native';
import { Box, Text } from 'native-base';

import { UserContext } from '../../../contexts/UserContext';

const LoginAppleScreenWeb = () => {
  const linkTo = useLinkTo();
  const params = new URLSearchParams(window.location.search);

  const code = params.get('code');
  const token = params.get('id_token');

  const { loginApple } = useContext(UserContext);

  useEffect(() => {
    if (!code || !token) return;
    const login = async () => {
      try {
        await loginApple(code, token);
        linkTo('/home');
      } catch (err) {
        linkTo('/login', { replace: true });
      }
    };
    void login();
  }, [code, token, loginApple, linkTo]);

  return (
    <Box>
      <Text>Logging in with apple...</Text>
    </Box>
  );
};

export default LoginAppleScreenWeb;
