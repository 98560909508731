import React from 'react';
import { Box, IconButton, useTheme } from 'native-base';

import Body3 from '../Body/Body3';
import FAProIcon from '../FAProIcon';
import Heading2 from '../Heading/Heading2';
import SubHeading1 from '../SubHeading/SubHeading1';

import BottomDrawer from './BottomDrawer';

const RecipeBottomIconsMoreInfo = ({ handleClose }) => {
  const { colors } = useTheme();

  const ExplainerRow = ({ icon = 'close', title, description }) => (
    <Box px={5} py={3}>
      <Box gap={1} flexDirection="row" height="36px" alignItems="center" alignSelf="stretch">
        <Box width="24px" alignItems="center" textAlign="center">
          <FAProIcon name={icon} />
        </Box>
        <SubHeading1>{title}</SubHeading1>
      </Box>
      <Body3 color={colors.mushroom[500]}>{description}</Body3>
    </Box>
  );

  const Divider = () => <Box pt={2} mb={2} mx={4} borderBottomColor="#E3E3E3" borderBottomWidth="1px" />;

  return (
    <BottomDrawer isOpen={true} onClose={handleClose} pt={0} pb={8}>
      <Box width="100%" alignItems="center" justifyContent="space-between" flexDirection="row" paddingY={3}>
        <Box flex="1" />
        <Heading2 flex="1" textAlign="center" ml="auto">
          Info
        </Heading2>
        <Box flex="1">
          <IconButton ml="auto" variant="ghost" size="large" icon={<FAProIcon name="close" />} onPress={handleClose} />
        </Box>
      </Box>
      <Box>
        <ExplainerRow
          icon="knife-kitchen"
          title="Prep time"
          description="The amount of time you will spend in the kitchen, preparing the meal."
        />
        <Divider />
        <ExplainerRow
          icon="clock"
          title="Total time"
          description="The total time it takes to make the meal and have it ready to serve."
        />
        <Divider />
        <ExplainerRow icon="fire-flame-curved" title="Calories" description="The number of calories per serving" />
      </Box>
    </BottomDrawer>
  );
};

export default RecipeBottomIconsMoreInfo;
