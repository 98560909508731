import React, { useContext } from 'react';
import { Text as ReactNativeText } from 'react-native';
import { useLinkTo } from '@react-navigation/native';
import { Box, Button, Center, IconButton } from 'native-base';

import { GlobalContext } from '../../contexts/GlobalContext';
import Body2 from '../Body/Body2';
import Body3 from '../Body/Body3';
import Body3Bold from '../Body/Body3Bold';
import FAProIcon from '../FAProIcon';
import Heading2 from '../Heading/Heading2';

import BottomDrawer from './BottomDrawer';

const NotLoggedInDrawer = () => {
  const { notLoggedInDialogOpenSet, notLoggedInRedirectSet } = useContext(GlobalContext);

  const linkTo = useLinkTo();

  const handleClose = () => {
    notLoggedInDialogOpenSet(false);
  };

  const handleRegister = () => {
    notLoggedInRedirectSet('/cart');
    handleClose();
    linkTo('/register');
  };

  const handleLogin = () => {
    notLoggedInRedirectSet('/cart');
    handleClose();
    linkTo('/login');
  };

  return (
    <BottomDrawer isOpen onClose={handleClose} pt={0} pb={8}>
      <Box width="100%" alignItems="center" justifyContent="space-between" flexDirection="row" paddingY={3}>
        <Box width="48px" />
        <Heading2 flex="1" textAlign="center" ml="auto">
          Trying to Checkout?
        </Heading2>
        <Box width="48px">
          <IconButton ml="auto" variant="ghost" size="large" icon={<FAProIcon name="close" />} onPress={handleClose} />
        </Box>
      </Box>
      <Center gap={4} pb={8}>
        <Body2 textAlign="center">
          Please register to turn your picked recipes into ingredients with your chosen supermarket.
        </Body2>
        <Body2 textAlign="center">
          We will then be able to save your recipe choices to the cook tab for you to prepare when you're ready.
        </Body2>
      </Center>
      <Box width="100%" alignItems="center" gap={6}>
        <Button size="fullWidth" onPress={handleRegister} variant="solid">
          Register
        </Button>
        <ReactNativeText textAlign="center">
          <Body3>Already have an account? </Body3>
          <Body3Bold onPress={handleLogin} bold>
            Login
          </Body3Bold>
        </ReactNativeText>
      </Box>
    </BottomDrawer>
  );
};

export default NotLoggedInDrawer;
