import React from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useLinkTo } from '@react-navigation/native';
import { getPermissionsAsync, requestPermissionsAsync } from 'expo-notifications';
import { Box, Button, ScrollView } from 'native-base';

import Body2Bold from '../components/Body/Body2Bold';
import Heading3Bold from '../components/Heading/Heading3Bold';
import NotificationIcon from '../components/NotificationIcon';
import tracking from '../lib/tracking';

const NotificationNagScreen = () => {
  const linkTo = useLinkTo();

  const requestPermission = async () => {
    console.log('handleAllow');
    const requestedPermissions = (await AsyncStorage.getItem('notification-permission')) === 'true';
    if (requestedPermissions) return;
    await AsyncStorage.setItem('notification-permission', 'true');

    const { status } = await getPermissionsAsync();
    if (status !== 'granted') {
      console.log('not granted - requestPermissionsAsync()');
      const { status: newStatus } = await requestPermissionsAsync();
      if (newStatus !== 'granted') {
        console.log('not granted');
        tracking.trackNotificationsPermission({ granted: false });
        return;
      } else {
        tracking.trackNotificationsPermission({ granted: true });
      }
    }
  };

  const handleAllow = async () => {
    await requestPermission();
    linkTo('/home');
  };
  const handleIgnore = async () => {
    console.log('handleIgnore');
    await AsyncStorage.setItem('notification-permission', 'true');
    linkTo('/home');
  };

  return (
    <ScrollView backgroundColor="almond.200">
      <Box padding={4} gap={4} paddingTop={24}>
        <Box alignItems="center">
          <NotificationIcon />
        </Box>

        <Heading3Bold paddingX={6} textAlign="center">
          Turn on notifications?
        </Heading3Bold>

        <Body2Bold textAlign="center">
          We can keep you updated on your order, or notify you about other important account activity.
        </Body2Bold>

        <Box height={8} />

        <Button variant="solid" onPress={handleAllow}>
          Yes, notify me
        </Button>

        <Button variant="outline" onPress={handleIgnore}>
          I'd rather not know
        </Button>
      </Box>
      <Box paddingTop={20} />
    </ScrollView>
  );
};

export default NotificationNagScreen;
