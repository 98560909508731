import React, { useContext, useState } from 'react';
import { Pressable } from 'react-native';
import { Box, Center, Column, Row } from 'native-base';

import Body3 from '../../components/Body/Body3';
import ChefCardList from '../../components/ChefCardList';
import FAProIcon from '../../components/FAProIcon';
import InfoTip from '../../components/InfoTip';
import RecipeCardList from '../../components/RecipeCardList';
import { SearchContext } from '../../contexts/SearchContext';

const SearchResults = ({ showLoaderSet }) => {
  const { keywords, recipes, chefs } = useContext(SearchContext);

  const [activeTab, activeTabSet] = useState(0);

  const searchTermString = `"${keywords.join('"')}"`;

  return (
    <Box px={5} flex={1}>
      <Box paddingY={2} gap={5} flex={1}>
        <Row width="100%" gap={4} borderBottomWidth={0.5} borderColor="#E3E3E3">
          <Box width="74px">
            <Pressable onPress={() => activeTabSet(0)}>
              <Column alignItems="center">
                <Center height={10} width={10}>
                  <FAProIcon name="bowl-hot" size={24} solid={activeTab === 0} />
                </Center>
                <Box borderColor="mustard.500" borderBottomWidth={activeTab === 0 ? 2 : 0}>
                  <Body3 padding={1}>Recipes</Body3>
                </Box>
              </Column>
            </Pressable>
          </Box>

          <Box width="74px">
            <Pressable onPress={() => activeTabSet(1)}>
              <Column alignItems="center">
                <Center height={10} width={10}>
                  <FAProIcon name="user-chef" size={24} solid={activeTab === 1} />
                </Center>
                <Box borderColor="mustard.500" borderBottomWidth={activeTab === 1 ? 2 : 0}>
                  <Body3 padding={1}>Chefs</Body3>
                </Box>
              </Column>
            </Pressable>
          </Box>
        </Row>

        <Box flex={1}>
          {activeTab === 0 && (
            <RecipeCardList
              list={recipes}
              listHeaderComponent={
                <Center>
                  <InfoTip
                    text={`Showing ${recipes.length} result${recipes.length !== 1 ? 's' : ''} for ${searchTermString}`}
                  />
                </Center>
              }
              showLoaderSet={showLoaderSet}
            />
          )}

          {activeTab === 1 && (
            <ChefCardList
              list={chefs}
              listHeaderComponent={
                <Center>
                  <InfoTip
                    text={`Showing ${chefs.length} result${chefs.length !== 1 ? 's' : ''} for ${searchTermString}`}
                  />
                </Center>
              }
              showLoaderSet={showLoaderSet}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default SearchResults;
