import { Box, Text } from 'native-base';

import fontSize from '../styles/fontSize';

import SupermarketLogo from './SupermarketLogo';

const SupermarketPrice = (props) => {
  const { cost, retailer } = props;

  if (!cost) return null;

  const costFixed = (cost || 0).toFixed(2);

  return (
    <Box flexDirection="row" gap={2} alignItems="center">
      <Box flexDirection="row" alignItems="center">
        <Text fontFamily="body" fontWeight={700} fontSize={fontSize(16)}>{`£${costFixed}`}</Text>
        <Text fontFamily="body" fontWeight={700} fontSize={fontSize(12)}>
          {' p/s'}
        </Text>
      </Box>
      <Box flex={1}>
        <SupermarketLogo retailer={retailer} width="100%" />
      </Box>
    </Box>
  );
};

export default SupermarketPrice;
