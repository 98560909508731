import { useContext, useEffect, useState } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useLinkTo } from '@react-navigation/native';
import { Box, Button, ScrollView } from 'native-base';

import { UserContext } from '../../../contexts/UserContext';

import { DATA } from './GetStartedData';
import ShowcaseSlide from './ShowcaseSlide';

const OnboardingScreenGetStarted = (props) => {
  const { onNext } = props;
  const linkTo = useLinkTo();
  const { profile } = useContext(UserContext);

  const [currentSlideIndex, currentSlideIndexSet] = useState(0);

  useEffect(() => {
    const transitionTimer = setTimeout(() => {
      currentSlideIndexSet((currentSlideIndex + 1) % 3);
    }, 3000);
    return () => clearTimeout(transitionTimer);
  }, [currentSlideIndex]);

  const handleLoginPressed = () => {
    if (profile) {
      AsyncStorage.setItem('onboarding-complete', 'true');
      linkTo('/home');
    } else {
      linkTo('/login');
    }
  };

  return (
    <ScrollView __contentContainer={{ alignItems: 'center', height: '100%' }} bg="white">
      <ShowcaseSlide data={DATA[currentSlideIndex]} />

      <Box flex={1} py={2} px={4} gap="4" alignItems="center" width="100%">
        <Button size="fullWidth" maxWidth={400} onPress={onNext}>
          Get started
        </Button>
        <Button size="fullWidth" maxWidth={400} variant="outline" onPress={handleLoginPressed}>
          {profile ? 'Skip' : 'Log in'}
        </Button>
      </Box>
    </ScrollView>
  );
};

export default OnboardingScreenGetStarted;
