import React from 'react';
import { Box, Center, useTheme } from 'native-base';

import CustomText from './CustomText';

const OrLine = () => {
  const { colors } = useTheme();

  return (
    <Center display="flex" flexDirection="row" gap={2} py={2}>
      <Box width="100px" height="1px" bg="#E3E3E3" />
      <CustomText textAlign="center" color={colors.mayo[700]} fontFamily="subHeading" fontWeight={700} fontSize={9}>
        or
      </CustomText>
      <Box width="100px" height="1px" bg="#E3E3E3" />
    </Center>
  );
};

export default OrLine;
