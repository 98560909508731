import React, { useContext, useState } from 'react';
import { Dimensions } from 'react-native';
import { Box, PresenceTransition, ScrollView } from 'native-base';

import { FilterContext } from '../../contexts/FilterContext';
import { GlobalContext } from '../../contexts/GlobalContext';
import { TAG_GROUPS } from '../../hooks/useTagGroup';

import ChangeCostPerServing from './Filters/ChangeCostPerServing';
import ChangeGlobalPreferences from './Filters/ChangeGlobalPreferences';
import ChangeGlobalPreferencesPreview from './Filters/ChangeGlobalPreferencesPreview';
import ChangePillar from './Filters/ChangePillar';
import ChangePrepTime from './Filters/ChangePrepTime';
import ChangeTags from './Filters/ChangeTags';
import ChangeTotalTime from './Filters/ChangeTotalTime';
import FiltersFooter from './Filters/FiltersFooter';
import FiltersHeader from './Filters/FiltersHeader';
import BottomDrawer from './BottomDrawer';

const FiltersMain = () => {
  const { filtersMainDialogOpen, filtersMainDialogOpenSet } = useContext(GlobalContext);
  const { resetFilters, saveFilters } = useContext(FilterContext);

  const [globalPreferencesTabOpen, globalPreferencesTabOpenSet] = useState(false);

  const handleOpenGlobalPreferences = () => globalPreferencesTabOpenSet(true);
  const handleCloseGlobalPreferences = () => globalPreferencesTabOpenSet(false);

  const handleClose = () => {
    saveFilters();
    filtersMainDialogOpenSet(false);
  };

  if (!filtersMainDialogOpen) return null;

  return (
    <BottomDrawer isOpen={filtersMainDialogOpen} onClose={handleClose}>
      {globalPreferencesTabOpen ? (
        <PresenceTransition
          visible={true}
          animate={{ transition: { duration: 200 }, translateX: 0 }}
          initial={{ translateX: Dimensions.get('window').width ?? 500 }}
          exit={{ translateX: 0 }}
        >
          <FiltersHeader
            handleBack={handleCloseGlobalPreferences}
            title="Global preferences"
            handleClose={handleClose}
          />
          <ScrollView width="100%">
            <ChangeGlobalPreferences />
          </ScrollView>
          <FiltersFooter
            handleClearFilters={resetFilters}
            handleClose={handleCloseGlobalPreferences}
            boxProps={{ width: undefined }}
          />
        </PresenceTransition>
      ) : (
        <>
          <FiltersHeader handleClose={handleClose} />
          <ScrollView width="100%">
            <Box gap={2}>
              <ChangeGlobalPreferencesPreview onPress={handleOpenGlobalPreferences} />
              <ChangeTags label="Cuisines" group={TAG_GROUPS.cuisine} />
              <ChangePillar />
              <ChangePrepTime />
              <ChangeTotalTime />
              <ChangeCostPerServing />
            </Box>
          </ScrollView>
          <FiltersFooter handleClearFilters={resetFilters} handleClose={handleClose} />
        </>
      )}
    </BottomDrawer>
  );
};

export default FiltersMain;
