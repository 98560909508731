import colors from '../base/colors';

const primary = colors.brightNavy[700];

const IconButton = {
  defaultProps: {},

  baseStyle: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '48px',

    _icon: {
      color: primary,
    },
  },

  variants: {
    ghost: {
      bg: 'white',
    },
    subtle: {
      bg: 'almond.400',
    },
    solid: {
      bg: 'brightNavy.700',
    },
  },

  sizes: {
    large: {
      width: '48px',
      height: '48px',
      _icon: {
        size: '24px',
      },
    },
    medium: {
      width: '40px',
      height: '40px',
      _icon: {
        size: '16px',
      },
    },
    small: {
      width: '36px',
      height: '36px',
      _icon: {
        size: '16px',
      },
    },
  },
};

export default IconButton;
