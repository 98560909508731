import rest from './rest';

const post = async (data) => {
  try {
    await rest.post(`/v8/tracking`, data);
  } catch (e) {
    // do nothing
  }
};

const tracking = { post };
export default tracking;
