import React, { useContext, useMemo, useState } from 'react';
import { Box, Button, IconButton } from 'native-base';

import { QuoteContext } from '../../contexts/QuoteContext';
import useCart from '../../hooks/useCart';
import useOrdered from '../../hooks/useOrdered';
import Counter from '../Counter';
import FAProIcon from '../FAProIcon';
import Heading2 from '../Heading/Heading2';

import BottomDrawer from './BottomDrawer';

const RecipeBottomCooking = (props) => {
  const { recipe, servings, servingsSet } = props;
  const id = useMemo(() => recipe?.id, [recipe]);

  const { orders, markCooked, markUncooked } = useOrdered();
  const { retailer } = useContext(QuoteContext);
  const { isAdded, add, remove } = useCart(id);

  const [adding, addingSet] = useState(false);

  const lastOrder = orders[0];
  const isCooked = lastOrder?.recipes.find((r) => r.id === recipe.id)?.isCooked;

  const handleClose = () => {
    addingSet(false);
  };

  const handleAdd = () => {
    add(id, servings);
    addingSet(false);
  };

  const handleAddRemove = () => {
    if (isAdded) {
      remove(id);
    } else {
      addingSet(true);
    }
  };

  const cost = recipe.costPerRetailer[retailer.id];

  if (adding) {
    return (
      <BottomDrawer isOpen={true} onClose={handleClose} pt={3}>
        <Box width="100%" paddingX={5}>
          <Box alignItems="center" justifyContent="space-between" flexDirection="row">
            <Box flex="1" />
            <Heading2 flex="1" textAlign="center" ml="auto">
              Choose
            </Heading2>
            <Box flex="1">
              <IconButton
                ml="auto"
                variant="ghost"
                size="large"
                icon={<FAProIcon name="close" />}
                onPress={handleClose}
              />
            </Box>
          </Box>
          <Box paddingY={4}>
            <Counter value={servings} onChange={servingsSet} />
          </Box>
          <Box
            width="100%"
            justifyContent="space-between"
            flexDirection="row"
            borderTopColor="#E3E3E3"
            borderTopStyle="solid"
            borderTopWidth="1px"
            paddingTop={4}
            pb={1.5}
          >
            <Button variant="unstyled" size="large" onPress={handleClose}>
              Cancel
            </Button>
            <Button variant="solid" size="large" onPress={handleAdd}>
              Done
            </Button>
          </Box>
        </Box>
      </BottomDrawer>
    );
  }

  return (
    <BottomDrawer isOpen disableOverlay>
      <Box flexDirection="row" justifyContent="space-between" width="100%" pb={cost > 0 ? undefined : 5}>
        {isCooked ? (
          <Button
            size="small"
            variant={'subtle'}
            onPress={() => markUncooked(orders[0].id, recipe.id)}
            leftIcon={<FAProIcon name="check" />}
          >
            Cooked
          </Button>
        ) : (
          <Button size="small" variant={'outline'} onPress={() => markCooked(orders[0].id, recipe.id)}>
            Mark as cooked
          </Button>
        )}
        <Button size="small" variant={isAdded ? 'subtle' : 'solid'} onPress={handleAddRemove}>
          {isAdded ? 'Remove' : 'Add'}
        </Button>
      </Box>
    </BottomDrawer>
  );
};

export default RecipeBottomCooking;
