import React from 'react';
import { Center, IconButton, useTheme } from 'native-base';

import FAProIcon from './FAProIcon';

const RatingStars = ({ value, valueSet, Container = Center }) => {
  const { colors } = useTheme();

  return (
    <Container flexDirection="row">
      {[1, 2, 3, 4, 5].map((star) => (
        <IconButton
          key={`star-${star}`}
          size="medium"
          onPress={() => valueSet(star)}
          icon={
            <FAProIcon
              name="star"
              size={24}
              solid={value >= star ? true : undefined}
              color={value >= star ? colors.mustard[500] : undefined}
            />
          }
        />
      ))}
    </Container>
  );
};

export default RatingStars;
