import { useContext, useMemo } from 'react';

import { TagContext } from '../contexts/TagContext';

export const TAG_GROUPS = {
  cuisine: 'cuisine',
  avoidance: 'avoidance',
  avoidDiet: 'avoid-diet',
  mealType: 'meal-type',
  difficulty: 'difficulty',
  additionalInfo: 'additional-info',
};

const useTagGroup = (group) => {
  const { additionalInfo, avoidDiets, avoidances, cuisines, difficulties, mealTypes } = useContext(TagContext);

  const tagGroup = useMemo(() => {
    switch (group) {
      case TAG_GROUPS.cuisine:
        return cuisines;
      case TAG_GROUPS.avoidance:
        return avoidances;
      case TAG_GROUPS.avoidDiet:
        return avoidDiets;
      case TAG_GROUPS.mealType:
        return mealTypes;
      case TAG_GROUPS.difficulty:
        return difficulties;
      case TAG_GROUPS.additionalInfo:
        return additionalInfo;
      default:
        return [];
    }
  }, [additionalInfo, avoidDiets, avoidances, cuisines, difficulties, mealTypes, group]);

  return { tagGroup };
};

export default useTagGroup;
