const baseStyle = {
  textAlign: 'center',
};

const sizes = {
  small: '12px',
  medium: '16px',
  large: '24px',
  xlarge: '24px',
};

const defaultProps = {};

const Icon = {
  baseStyle,
  sizes,
  defaultProps,
};

export default Icon;
