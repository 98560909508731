/* eslint-disable react-native/no-inline-styles */
import { useEffect, useState } from 'react';
import { Linking, Pressable } from 'react-native';
import * as Clipboard from 'expo-clipboard';
import { Box, Button, Divider } from 'native-base';

import BottomDrawer from './BottomDrawer/BottomDrawer';
import Heading2 from './Heading/Heading2';
import Heading3Bold from './Heading/Heading3Bold';
import FAProIcon from './FAProIcon';

const defaultVoucherButton = {
  variant: 'accent',
  text: 'Copy voucher code',
};

const DiscountBox = ({ isOpen, retailer, onPressNext, onClose }) => {
  const [voucherButton, voucherButtonSet] = useState(defaultVoucherButton);
  const copyToClipboard = async () => {
    await Clipboard.setStringAsync(retailer.discountCode);
    voucherButtonSet({
      variant: 'success',
      text: 'Code copied to clipboard',
    });
  };

  const redirectToTAC = () => {
    Linking.openURL(retailer.discountTAC);
  };

  useEffect(() => {
    if (voucherButton.variant === 'success') {
      setTimeout(() => {
        voucherButtonSet(defaultVoucherButton);
      }, 1500);
    }
  }, [voucherButton.variant]);

  return (
    <BottomDrawer isOpen={isOpen} px={0}>
      <Box marginBottom={20}>
        <Heading2>Offer</Heading2>
      </Box>
      <Box position="absolute" right={5} top={5}>
        <Pressable onPress={onClose}>
          <FAProIcon name="xmark" size={22} />
        </Pressable>
      </Box>
      <Heading3Bold bottom={10} style={{ textAlign: 'center' }}>
        {retailer.discountText}
      </Heading3Bold>
      <Box width="100%" px={21}>
        <Button variant={voucherButton.variant} bottom={5} onPress={copyToClipboard}>
          {voucherButton.text}
        </Button>
        <Button variant="link" bottom={5} onPress={redirectToTAC}>
          Terms and conditions
        </Button>
      </Box>
      <Divider bg="#E3E3E3" my={5} />
      <Box width="100%" px={21}>
        <Button onPress={onPressNext} marginBottom={10}>
          Next
        </Button>
      </Box>
    </BottomDrawer>
  );
};

export default DiscountBox;
