import React, { useState } from 'react';

const GlobalContext = React.createContext({});

const GlobalProvider = (props) => {
  const { children } = props;

  const [currentScreen, currentScreenSet] = useState();
  const [topNavOptions, topNavOptionsSet] = useState({});
  const [exploreTabsOptions, exploreTabsOptionsSet] = useState({ hideIcons: false });
  const [filtersMainDialogOpen, filtersMainDialogOpenSet] = useState(false);
  // set to FILTER_SINGLE_COMPONENT
  const [filtersSingleDialogType, filtersSingleDialogTypeSet] = useState(false);

  const [notLoggedInDialogOpen, notLoggedInDialogOpenSet] = useState(false);
  const [notLoggedInRedirect, notLoggedInRedirectSet] = useState(false);

  const value = {
    currentScreen,
    currentScreenSet,
    topNavOptions,
    topNavOptionsSet,
    exploreTabsOptions,
    exploreTabsOptionsSet,
    filtersMainDialogOpen,
    filtersMainDialogOpenSet,
    filtersSingleDialogType,
    filtersSingleDialogTypeSet,
    notLoggedInDialogOpen,
    notLoggedInDialogOpenSet,
    notLoggedInRedirect,
    notLoggedInRedirectSet,
  };

  return <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>;
};

export { GlobalContext, GlobalProvider };
