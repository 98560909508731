import ImageWrapper from './ImageWrapper';

const SupermarketLogo = (props) => {
  const { retailer, width = '72px', height = '18px', white } = props;
  if (!retailer) return null;

  const { name, image, imageWhite } = retailer;

  return (
    <ImageWrapper src={white ? imageWhite : image} alt={name} width={width} height={height} resizeMode="contain" />
  );
};

export default SupermarketLogo;
