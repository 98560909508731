import React, { useContext, useEffect, useState } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';

import usePopulateRecipe from '../hooks/usePopulateRecipe';
import tracking from '../lib/tracking';

import { ChefLiveContext } from './ChefLiveContext';
import { FilterContext } from './FilterContext';

const SearchContext = React.createContext({});

const SearchProvider = (props) => {
  const { children } = props;

  const { filteredList } = useContext(FilterContext);
  const { rows: chefList } = useContext(ChefLiveContext);

  const populateRecipe = usePopulateRecipe();

  const [chefs, chefsSet] = useState([]);
  const [recipes, recipesSet] = useState([]);
  const [keywords, keywordsSet] = useState([]);
  const [recentSearches, recentSearchesSet] = useState([]);

  useEffect(() => {
    AsyncStorage.getItem('recent-searches').then((i) => {
      if (i) {
        try {
          recentSearchesSet(JSON.parse(i));
        } catch (e) {
          AsyncStorage.setItem('recent-searches', JSON.stringify([]));
        }
      }
    });
  }, []);

  useEffect(() => {
    if (!keywords.length) return;
    if (!filteredList?.length) return;
    if (!chefList?.length) return;

    const populated = filteredList.map(populateRecipe);

    const doSearch = async () => {
      const matchedRecipes = populated
        .map((recipe) => {
          let recipeWeighting = 0;
          keywords.forEach((keyword) => {
            try {
              const lcKeyword = keyword.toLowerCase();

              const nameIncludesKeyword = recipe.name.toLowerCase().includes(lcKeyword);
              if (nameIncludesKeyword) recipeWeighting += 10;

              const descriptionIncludesKeyword = recipe.description.toLowerCase().includes(lcKeyword);
              if (descriptionIncludesKeyword) recipeWeighting += 5;

              const ingredientIncludesKeyword = recipe.ingredientGroups
                .map(({ list }) => list.some((ingredient) => ingredient.name.toLowerCase().includes(lcKeyword)))
                .some((matchedIngredient) => matchedIngredient);
              if (ingredientIncludesKeyword) recipeWeighting += 3;

              const chefIncludesKeyword = recipe.chef?.name.toLowerCase().includes(lcKeyword);
              if (chefIncludesKeyword) recipeWeighting += 1;

              const tagsIncludesKeyword = recipe.tags?.join(', ').toLowerCase().includes(lcKeyword);
              if (tagsIncludesKeyword) recipeWeighting += 1;
            } catch (e) {
              console.log('WARN:', recipe.name, 'looks broken --', e.message);
            }
          });
          return { recipe, recipeWeighting };
        })
        .filter(({ recipeWeighting }) => recipeWeighting)
        .sort(({ recipeWeighting: a }, { recipeWeighting: b }) => b - a)
        .map(({ recipe }) => recipe);

      const matchedChefs = chefList.filter((chef) =>
        keywords.some((keyword) => chef.name.toLowerCase().includes(keyword.toLowerCase())),
      );

      recipesSet(matchedRecipes);
      chefsSet(matchedChefs);
    };

    tracking.trackSearch(keywords);
    doSearch();
  }, [chefList, filteredList, keywords, populateRecipe]);

  useEffect(() => {
    recentSearchesSet((b) => [...new Set([...keywords, ...b])].filter((a) => a));
  }, [keywords]);

  useEffect(() => {
    AsyncStorage.setItem('recent-searches', JSON.stringify(recentSearches));
  }, [recentSearches]);

  const value = { chefs, recipes, keywords, keywordsSet, recentSearches };

  return <SearchContext.Provider value={value}>{children}</SearchContext.Provider>;
};

export { SearchContext, SearchProvider };
