import React from 'react';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { LinearGradient } from 'expo-linear-gradient';
import { NativeBaseProvider } from 'native-base';

import { CheckoutProvider } from './contexts/CheckoutContext';
import { ChefLiveProvider } from './contexts/ChefLiveContext';
import { CommonProvider } from './contexts/CommonContext';
import { FilterProvider } from './contexts/FilterContext';
import { GlobalProvider } from './contexts/GlobalContext';
import { IngredientProvider } from './contexts/IngredientContext';
import { QuoteProvider } from './contexts/QuoteContext';
import { RecipeLiveProvider } from './contexts/RecipeLiveContext';
import { SearchProvider } from './contexts/SearchContext';
import { TagProvider } from './contexts/TagContext';
import { UserProvider } from './contexts/UserContext';
import { VisitorProvider } from './contexts/VisitorContext';
import { WeightedProvider } from './contexts/WeightedContext';
import theme from './styles/theme';

const config = {
  strictMode: 'warn',
  dependencies: {
    'linear-gradient': LinearGradient,
  },
};

const Providers = (props) => {
  const { children } = props;
  return (
    <GlobalProvider>
      <IngredientProvider>
        <CommonProvider>
          <TagProvider>
            <ChefLiveProvider>
              <RecipeLiveProvider>
                <UserProvider>
                  <VisitorProvider>
                    <QuoteProvider>
                      <CheckoutProvider>
                        <FilterProvider>
                          <WeightedProvider>
                            <SearchProvider>
                              <SafeAreaProvider>
                                <NativeBaseProvider theme={theme} config={config}>
                                  <>{children}</>
                                </NativeBaseProvider>
                              </SafeAreaProvider>
                            </SearchProvider>
                          </WeightedProvider>
                        </FilterProvider>
                      </CheckoutProvider>
                    </QuoteProvider>
                  </VisitorProvider>
                </UserProvider>
              </RecipeLiveProvider>
            </ChefLiveProvider>
          </TagProvider>
        </CommonProvider>
      </IngredientProvider>
    </GlobalProvider>
  );
};

export default Providers;
