import React from 'react';
import { PresenceTransition } from 'native-base';

const ScaleUp = ({ children }) => (
  <PresenceTransition
    visible={true}
    initial={{
      opacity: 1,
      scale: 0.0,
    }}
    animate={{
      scale: 1,
      opacity: 1,
    }}
    exit={{
      scale: 1,
      opacity: 0,
    }}
  >
    {children}
  </PresenceTransition>
);

export default ScaleUp;
