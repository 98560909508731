import { Path, Svg } from 'react-native-svg';

const SvgImageOverlayBottomCurve = (props) => {
  const { w = 800, h = 100 } = props;

  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 ${w} ${h}`}>
      <Path
        fill="#fff"
        d={`
                M0,0
                C0,0,${w / 2},${h * 2},${w},0
                L${w},0
                L${w},${h}
                L0,${h}Z
                `}
      ></Path>
    </Svg>
  );
};

export default SvgImageOverlayBottomCurve;
