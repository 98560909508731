import { Heading } from 'native-base';

import fontSize from '../../styles/fontSize';

const Heading3Bold = (props) => {
  const { withShadow, ...rest } = props;
  if (withShadow) {
    rest.style = {
      textShadowColor: 'rgba(67, 66, 65, 0.99)',
      textShadowOffset: {
        width: 1,
        height: 1,
      },
      textShadowRadius: 15,
    };
  }
  return (
    <Heading fontFamily="heading" fontWeight={700} fontSize={fontSize(32)} {...rest}>
      {props.children}
    </Heading>
  );
};

export default Heading3Bold;
