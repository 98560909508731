import { Heading } from 'native-base';

import fontSize from '../../styles/fontSize';

const SubHeading1 = (props) => {
  const { withShadow, ...rest } = props;
  if (withShadow) {
    rest.style = {
      textShadowColor: 'rgba(0, 0, 0, 0.99)',
      textShadowOffset: {
        width: -1.8451179265975952,
        height: -1.8451179265975952,
      },
      textShadowRadius: 18.599327087402344,
    };
  }
  return (
    <Heading fontFamily="subHeading" fontWeight={700} fontSize={fontSize(16)} {...rest}>
      {props.children}
    </Heading>
  );
};

export default SubHeading1;
