/*
see:
https://github.com/GeekyAnts/NativeBase/blob/master/src/theme/index.ts
for base theme
*/

import { extendTheme } from 'native-base';

import colors from './base/colors';
import typography from './base/typography';
import { ActionsheetContent } from './components/Actionsheet';
import Button from './components/Button';
import Icon from './components/Icon';
import IconButton from './components/IconButton';
import Switch from './components/Switch';

const theme = extendTheme({
  config: {
    initialColorMode: 'light',
    useSystemColorMode: false,
  },
  components: {
    ActionsheetContent,
  },
});

// deliberatly overwrite instead of extend
theme.colors = colors;
theme.fonts = typography.fonts;
theme.fontConfig = typography.fontConfig;

theme.components.Button = Button;
theme.components.IconButton = IconButton;
theme.components.Icon = Icon;
theme.components.Switch = Switch;

export default theme;
