/* eslint-disable react-native/no-inline-styles */
import React from 'react';
import { Animated } from 'react-native';
import { Box } from 'native-base';

import useAnimatedHeader from './useAnimatedHeader';

const AnimatedHeaderFlatList = ({
  Header,
  TopNavBar,
  ReplacementTopNavBar,
  flatListProps = {},
  headerHeight = 315,
  navBarHeight = 72,
}) => {
  const { onScroll, topNavOpacity, replacementTopNavOpacity } = useAnimatedHeader(headerHeight, navBarHeight);

  return (
    <>
      <Animated.FlatList
        {...flatListProps}
        onScroll={onScroll}
        ListHeaderComponent={<Box height={headerHeight}>{Header}</Box>}
      />
      {/* TopNav will be rendered on top of the FlatList and fade out on scroll down */}
      <Animated.View
        style={{
          position: 'absolute',
          top: 0,
          elevation: 2,
          height: navBarHeight,
          opacity: topNavOpacity,
          width: '100%',
        }}
      >
        {TopNavBar}
      </Animated.View>
      {/* ReplacementTopNav will be rendered on top of the FlatList and fade in on scroll down */}
      <Animated.View
        style={{
          position: 'absolute',
          top: 0,
          elevation: 2,
          height: navBarHeight,
          opacity: replacementTopNavOpacity,
          width: '100%',
        }}
      >
        {ReplacementTopNavBar}
      </Animated.View>
    </>
  );
};

export default AnimatedHeaderFlatList;
