import React from 'react';
import { useLinkTo } from '@react-navigation/native';
import { Button, Center, Pressable } from 'native-base';

import Heading3 from './Heading/Heading3';
import SubHeading1 from './SubHeading/SubHeading1';
import ChefAvatar from './ChefAvatar';
import ImageWrapper from './ImageWrapper';
import MurkyGradient from './MurkyGradient';

const CarouselCard = (props) => {
  const linkTo = useLinkTo();

  const { item } = props;

  if (!item) return null;

  const { title, subtitle, imageUrl, targetUrl, chef, buttonText = 'See recipe' } = item;

  const handlePress = () => linkTo(targetUrl);

  return (
    <Pressable maxWidth="100%" height={280} width={330} onPress={handlePress}>
      <ImageWrapper
        displayWidth={330}
        src={imageUrl}
        alt={title}
        width="100%"
        height="100%"
        resizeMode="cover"
        borderRadius={10}
        position="absolute"
        top="0"
        left="0"
      />
      <Center height="100%" width="100%" justifyContent="flex-end">
        <MurkyGradient
          color1="#452C0500"
          color2="#452C0559"
          py="40px"
          borderBottomRightRadius={10}
          borderBottomLeftRadius={10}
          gap={1}
          pb={8}
          px={2}
        >
          {!!chef && (
            <Center width="274px">
              <ChefAvatar chef={chef} size="48px" />
              <SubHeading1 color="white" withShadow={true}>
                {chef?.name}
              </SubHeading1>
            </Center>
          )}
          <Center width="274px">
            {subtitle && (
              <SubHeading1 textAlign="center" color="white" withShadow={true}>
                {subtitle}
              </SubHeading1>
            )}
            <Heading3 color="white" textAlign="center" withShadow={true} height="100px">
              {title}
            </Heading3>
          </Center>
          {!!buttonText && (
            <Button onPress={handlePress} variant="ghost">
              {buttonText}
            </Button>
          )}
        </MurkyGradient>
      </Center>
    </Pressable>
  );
};

export default CarouselCard;
