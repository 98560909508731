import { Column, Row } from 'native-base';

import { fromGramsTo } from '../lib/convert';
import { friendlyNumber } from '../lib/util';

import Body2 from './Body/Body2';
import Caption from './Caption';
import ImageWrapper from './ImageWrapper';

const IngredientItem = (props) => {
  const { servings, recipeServings, ingredient } = props;

  const quantity = ingredient.displayUnit
    ? fromGramsTo(ingredient.displayUnit, ingredient.quantity, ingredient.typicalSize)
    : ingredient.quantity;

  const unit = ingredient.displayUnit ? ingredient.displayUnit : ingredient.unit;

  const count = quantity * (servings / recipeServings);
  const friendlyNum = friendlyNumber(count, unit);
  const displayUnit = unit === 'whole' ? '' : ` ${unit}`;

  let name = ingredient.name;
  if (ingredient.prepStep && ingredient.prepStep !== 'none') {
    name += `, ${ingredient.prepStep}`;
  }

  return (
    <Row alignItems="center" space={4}>
      <ImageWrapper
        displayWidth={48}
        src={ingredient.image}
        alt={name}
        width="48px"
        height="48px"
        resizeMode="cover"
        borderRadius={7.2}
      />
      <Column>
        <Caption color="mushroom.500">{`${friendlyNum}${displayUnit}`}</Caption>
        <Body2>{name}</Body2>
      </Column>
    </Row>
  );
};

export default IngredientItem;
