import React, { useCallback, useContext, useEffect, useState } from 'react';

import useCart from '../hooks/useCart';
import { getQuote } from '../lib/get-quote';
import tracking from '../lib/tracking';

import { CommonContext } from './CommonContext';
import { UserContext } from './UserContext';

const QuoteContext = React.createContext({});

const ingredientToWhisk = (i, multi) => ({
  ...i,
  quantity: i.quantity * multi,
});

const QuoteProvider = (props) => {
  const { children } = props;

  const { profile } = useContext(UserContext);
  const { retailers } = useContext(CommonContext);

  const [retailer, retailerSet] = useState();
  const [retailerProducts, retailerProductsSet] = useState({});

  const { recipes } = useCart();

  useEffect(() => {
    const f = retailers.find((a) => a.id === profile?.retailer);
    retailerSet(f ? f : retailers[0]);
  }, [profile?.retailer, retailers]);

  useEffect(() => {
    const func = async () => {
      if (!recipes.length) return;

      const ingredients = [];

      for (const r of recipes) {
        const multi = r.cartServings / r.servings;
        for (const g of r.ingredientGroups) {
          for (const i of g.list) {
            const a = ingredientToWhisk(i, multi);
            const f = ingredients.find((a2) => a2.id === a.id);
            if (f) {
              f.quantity += a.quantity;
            } else {
              ingredients.push(a);
            }
          }
        }
      }

      tracking.trackGetQuote(recipes, retailer);

      const orderedRetailers = [retailer, ...retailers.filter((a) => a.id !== retailer?.id)];

      for (const r of orderedRetailers) {
        const q = await getQuote(ingredients, r, !!r.skipQuote);
        if (r.skipQuote) continue;
        if (!q || !q[r.id]) continue;

        const list = q[r.id].products.map((p) => ({
          ...p,
          quantity: p.cupboardItem ? 0 : p.quantity,
          image: p.photo,
        }));

        retailerProductsSet((a) => ({ ...a, [r.id]: list }));
      }
    };

    func();
  }, [recipes, retailer, retailers]);

  const retailerTotals = Object.entries(retailerProducts).reduce((a, c) => {
    const [k, v] = c;
    const val = v.reduce((acc, p) => acc + p.price * (p.quantity || 0), 0);
    return { ...a, [k]: val };
  }, {});

  const productInc = useCallback(
    (p) => {
      if (!retailer?.id) return p;
      retailerProductsSet((l) => {
        const n = l[retailer.id].map((a) => ({ ...a, quantity: a.quantity + (a.id === p.id ? 1 : 0) }));
        return { ...l, [retailer.id]: n };
      });
      tracking.trackUpdateProductQuantity(p);
    },
    [retailer?.id],
  );

  const productDec = useCallback(
    (p) => {
      if (!retailer?.id) return p;
      retailerProductsSet((l) => {
        const n = l[retailer.id].map((a) => ({ ...a, quantity: a.quantity - (a.id === p.id ? 1 : 0) }));
        return { ...l, [retailer.id]: n };
      });
      tracking.trackUpdateProductQuantity(p);
    },
    [retailer?.id],
  );

  const changeRetailer = useCallback((r) => {
    retailerSet(r);
  }, []);

  const value = { retailerProducts, retailerTotals, retailer, productInc, productDec, changeRetailer };

  return <QuoteContext.Provider value={value}>{children}</QuoteContext.Provider>;
};

export { QuoteContext, QuoteProvider };
