import React, { useContext, useMemo } from 'react';
import { Box, HStack, IconButton, VStack } from 'native-base';

import Body2Bold from '../../components/Body/Body2Bold';
import FAProIcon from '../../components/FAProIcon';
import ImageWrapper from '../../components/ImageWrapper';
import SubHeading1 from '../../components/SubHeading/SubHeading1';
import SubHeading2 from '../../components/SubHeading/SubHeading2';
import { QuoteContext } from '../../contexts/QuoteContext';

const ProductListItem = (props) => {
  const { product } = props;
  const { productInc, productDec } = useContext(QuoteContext);

  const inc = () => productInc(product);
  const dec = () => productDec(product);

  return (
    <HStack paddingY={3} paddingX={5} maxWidth="100%" width="393px" alignItems="center" space={2}>
      <Box
        width={16}
        height={16}
        borderRadius="12px"
        borderWidth="0.7px"
        borderColor="#E3E3E3"
        bgColor="white"
        padding={1}
        alignItems="center"
        justifyContent="center"
      >
        <ImageWrapper displayWidth={64} alt={product.name} width="100%" height="100%" src={product.image} />
      </Box>

      <VStack flex={1} space={2}>
        <SubHeading1>{product.name}</SubHeading1>
        {!!product.price && (
          <HStack>
            <Body2Bold>£{product.price.toFixed(2)}</Body2Bold>
          </HStack>
        )}
      </VStack>

      {!!product.quantity && (
        <HStack
          alignItems="center"
          justifyContent="center"
          bgColor="white"
          borderRadius="24px"
          borderWidth="1px"
          borderColor="#E3E3E3"
        >
          <IconButton size="small" onPress={dec}>
            <FAProIcon name={product.quantity > 1 ? 'minus' : 'trash-can'} />
          </IconButton>

          <Body2Bold>{product.quantity}</Body2Bold>

          <IconButton size="small" onPress={inc}>
            <FAProIcon name="add" />
          </IconButton>
        </HStack>
      )}

      {!product.quantity && (
        <IconButton size="large" variant="solid" onPress={inc}>
          <FAProIcon name="add" color="white" size="24px" />
        </IconButton>
      )}
    </HStack>
  );
};

const ProductList = (props) => {
  const { list } = props;

  const orderedList = useMemo(
    () =>
      list.sort((a, b) => {
        const weight = (type) => {
          switch (type) {
            case 'Meat & Fish':
              return 150;
            case 'Fruit & Veg':
              return 140;
            case 'Dairy, Eggs & Chilled':
              return 130;
            case 'Bread & Bakery':
              return 110;
            case 'Food Cupboard':
              return 100;
            case 'Frozen':
              return 90;
            case 'Breakfast Foods':
              return 80;
            case 'Dietary & World Foods':
              return 70;
            case 'Snacks & Treats':
              return 60;
            case 'Drinks':
              return 50;
            case 'Household':
              return 40;
            case 'Health & Beauty':
              return 30;
            case 'Baby & Toddler':
              return 20;
            case 'Pet':
              return 10;
            case 'Other':
            default:
              return 0;
          }
        };
        return weight(b?.type) - weight(a?.type);
      }),
    [list],
  );

  const mainItems = orderedList
    .filter((a) => !a.cupboardItem)
    .filter((a) => !!a.quantity)
    .map((p) => <ProductListItem key={`mainItems-${p.id}`} product={p} />);
  const secondItems = orderedList
    .filter((a) => a.cupboardItem)
    .filter((a) => !!a.quantity)
    .map((p) => <ProductListItem key={`secondItems-${p.id}`} product={p} />);

  const thirdItems = orderedList
    .filter((a) => !a.cupboardItem)
    .filter((a) => !a.quantity)
    .map((p) => <ProductListItem key={`thirdItems-${p.id}`} product={p} />);
  const fourthItems = orderedList
    .filter((a) => a.cupboardItem)
    .filter((a) => !a.quantity)
    .map((p) => <ProductListItem key={`fourthItems-${p.id}`} product={p} />);

  return (
    <Box>
      <SubHeading2 paddingY={3} paddingX={5}>
        Your basket
      </SubHeading2>
      {mainItems}
      {secondItems}

      <SubHeading2 paddingY={3} paddingX={5}>
        You may also need...
      </SubHeading2>
      {thirdItems}
      {fourthItems}

      <Box height={200} />
    </Box>
  );
};

export default ProductList;
