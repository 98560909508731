import { useContext } from 'react';
import WebView from 'react-native-webview';
import { useLinkTo } from '@react-navigation/native';
import Constants from 'expo-constants';
import { Box } from 'native-base';

import { GlobalContext } from '../../../contexts/GlobalContext';
import { UserContext } from '../../../contexts/UserContext';

const BASE = Constants?.expoConfig?.extra?.apiUrl ?? 'http://localhost:8081';

const appleLoginHref =
  `https://appleid.apple.com/auth/authorize` +
  `?client_id=app.plateup.groceries` +
  `&redirect_uri=${BASE}/v8/apple/auth` +
  `&response_type=code%20id_token` +
  `&scope=name%20email` +
  `&state=https://staging-v2.plateup.app/dummy` +
  `&response_mode=form_post`;

const LoginAppleScreenAndroid = () => {
  const linkTo = useLinkTo();
  const { loginApple } = useContext(UserContext);
  const { notLoggedInRedirect } = useContext(GlobalContext);

  const handleNavChange = async (event) => {
    console.log('event.url', event.url);

    if (!event.url.includes('id_token=')) return;

    const code = event.url.replace(/.*code=/g, '').replace(/&.*/, '');
    const token = event.url.replace(/.*id_token=/g, '').replace(/&.*/, '');
    if (!token) return;
    try {
      await loginApple(code, token);
      if (notLoggedInRedirect) {
        linkTo(notLoggedInRedirect);
      } else {
        linkTo('/home');
      }
    } catch (err) {
      linkTo('/login', { replace: true });
    }
  };

  return (
    <Box height="100%">
      <WebView
        incognito
        textZoom={125}
        source={{ uri: appleLoginHref }}
        userAgent="Mozilla/5.0 (Ubuntu; Mobile) Firefox/115.0"
        onNavigationStateChange={handleNavChange}
      />
    </Box>
  );
};

export default LoginAppleScreenAndroid;
