import { useLinkTo } from '@react-navigation/native';
import { Button } from 'native-base';

import SubHeading1 from '../../../components/SubHeading/SubHeading1';
import LoginContainer from '../components/LoginContainer';

/**
 * @deprecated
 */
const ResetPasswordConfirmScreen = () => {
  const linkTo = useLinkTo();

  return (
    <LoginContainer title="Reset Password" subtitle="Your password has been reset">
      <SubHeading1 py={5}>Please login with your new password.</SubHeading1>
      <Button onPress={() => linkTo('/login')} width="100%">
        Login
      </Button>
    </LoginContainer>
  );
};

export default ResetPasswordConfirmScreen;
