import React, { useCallback } from 'react';

import useCrud from '../hooks/useCrud';
import api from '../lib/api';

const IngredientContext = React.createContext({});

const IngredientProvider = (props) => {
  const { children } = props;

  const { loading, rows, list, get, post, put, del } = useCrud(api.ingredient);

  const find = useCallback((id) => rows.find((a) => a.id === id), [rows]);

  const value = { loading, rows, list, get, post, put, del, find };

  return <IngredientContext.Provider value={value}>{children}</IngredientContext.Provider>;
};

export { IngredientContext, IngredientProvider };
