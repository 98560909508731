import { useContext, useEffect, useState } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useLinkTo } from '@react-navigation/native';
import { addEventListener, getInitialURL, parse } from 'expo-linking';
import { addNotificationResponseReceivedListener } from 'expo-notifications';

import { GlobalContext } from '../contexts/GlobalContext';
import { parseAppsflyerTags, parseBranchTags } from '../lib/trackEvent';

const NotificationLinkHandler = () => {
  const linkTo = useLinkTo();
  const { currentScreen } = useContext(GlobalContext);

  const [url, urlSet] = useState(null);
  const onChange = (event) => {
    urlSet(event.url);
  };
  useEffect(() => {
    getInitialURL().then((u) => urlSet(u));
    const subscription = addEventListener('url', onChange);
    return () => subscription.remove();
  }, []);

  useEffect(() => {
    const redirecToNotificationNagScreen = async () => {
      const onboardingComplete = await AsyncStorage.getItem('onboarding-complete');
      if (onboardingComplete !== 'true') return;

      const requestedPermissions = (await AsyncStorage.getItem('notification-permission')) === 'true';
      if (requestedPermissions) return;

      linkTo('/notification-nag');
    };
    redirecToNotificationNagScreen();
  }, [currentScreen, linkTo]);

  useEffect(() => {
    addNotificationResponseReceivedListener((response) => {
      console.log('addNotificationResponseReceivedListener', response);
      const link = response?.notification?.request?.content?.data?.link;
      if (link && link !== '/') {
        console.log('open notification link: ', link);
        linkTo(link);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const func = async () => {
      if (!url) return;
      const a = parse(url);
      parseAppsflyerTags(a?.queryParams);
      parseBranchTags(a?.queryParams);
      const link = a?.queryParams?.deep_link_value;
      if (link && link !== '/') {
        linkTo(link);
      }
      urlSet(null);
    };
    func();
  }, [url, linkTo]);

  return null;
};

export default NotificationLinkHandler;
