import React, { useState } from 'react';
import { Input, InputGroup, InputRightAddon, Pressable } from 'native-base';

import Body2Bold from './Body/Body2Bold';

const PasswordTextField = ({ value, onChange, placeholder = 'Password' }) => {
  const [show, showSet] = useState(false);
  return (
    <InputGroup width="100%">
      <Input
        value={value}
        onChangeText={onChange}
        placeholder={placeholder}
        type={show ? 'text' : 'password'}
        flex={1}
        variant="outline"
        size="md"
        borderRightWidth="0px"
        borderRadius="8px"
        borderWidth="1px"
        borderColor="#E3E3E3"
        bg="white"
        px="16px"
        py="17px"
      />
      <InputRightAddon
        borderLeftWidth="0px"
        borderRadius="8px"
        borderWidth="1px"
        borderColor="#E3E3E3"
        children={
          <Pressable onPress={() => showSet(!show)} p={1} ml="auto">
            <Body2Bold>{show ? 'Hide' : 'Show'}</Body2Bold>
          </Pressable>
        }
      />
    </InputGroup>
  );
};

export default PasswordTextField;
