import { useContext, useState } from 'react';
import { useLinkTo, useRoute } from '@react-navigation/native';
import { Button } from 'native-base';

import TextField from '../../../components/TextField';
import { UserContext } from '../../../contexts/UserContext';
import LoginContainer from '../components/LoginContainer';

/**
 * @deprecated
 */
const ResetPasswordCodeScreen = () => {
  const { resetPasswordConfirm, busy } = useContext(UserContext);
  const linkTo = useLinkTo();
  const route = useRoute();

  const [email, emailSet] = useState('');
  const [password, passwordSet] = useState('');

  if (!route.params) return null;

  const { resetCode } = route.params;

  const handleSubmit = async () => {
    await resetPasswordConfirm(email, resetCode, password);
    linkTo('/reset-password/confirm');
  };

  return (
    <LoginContainer title="Reset Password" subtitle="Please enter your email and your new password">
      <TextField placeholder="email" value={email} onChange={emailSet} />
      <TextField placeholder="password" value={password} onChange={passwordSet} type="password" pb={4} />

      <Button onPress={handleSubmit} busy={busy} width="100%">
        Reset Password
      </Button>
    </LoginContainer>
  );
};

export default ResetPasswordCodeScreen;
