import { Button } from 'native-base';

import FAProIcon from './FAProIcon';

const Chip = (props) => {
  const { variant, label, startIconName, endIconName, children, ...rest } = props;

  let bvariant = 'outline';
  let bg = 'white';
  let borderWidth = null;
  let leftIcon = null;
  let endIcon = null;

  if (variant === 'outline') {
    bvariant = 'outline';
    bg = 'white';
    borderWidth = '1px';
  } else if (variant === 'filled') {
    bvariant = 'subtle';
    bg = 'almond.400';
  } else if (variant === 'active') {
    bvariant = 'subtle';
    bg = 'mustard.500';
  } else if (variant === 'supermarket') {
    bvariant = 'ghost';
  }

  if (startIconName) {
    leftIcon = <FAProIcon name={startIconName} />;
  }
  if (endIconName) {
    endIcon = <FAProIcon name={endIconName} />;
  }

  return (
    <Button
      bg={bg}
      shadow="none"
      borderWidth={borderWidth}
      variant={bvariant}
      size="medium"
      leftIcon={leftIcon}
      endIcon={endIcon}
      _text={{
        fontSize: 12,
      }}
      paddingLeft="12px"
      paddingRight="12px"
      height="36px"
      {...rest}
    >
      {label}
      {children}
    </Button>
  );
};

export default Chip;
