/* eslint-disable max-len */
import React, { useContext, useMemo } from 'react';
import Svg, { Path } from 'react-native-svg';
import * as Haptics from 'expo-haptics';
import { Button } from 'native-base';

import { UserContext } from '../../contexts/UserContext';
import useFavourites from '../../hooks/useFavourites';

const FavouriteButton = (props) => {
  const { id } = props;
  const { profile } = useContext(UserContext);

  const { isFavourite, add, remove } = useFavourites();

  const favourited = useMemo(() => {
    if (!id) return false;
    return isFavourite(id);
  }, [isFavourite, id]);

  if (!profile) return null;

  const handleFavourite = () => {
    Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
    if (favourited) {
      return remove(id);
    }
    return add(id);
  };

  return (
    <Button onPress={handleFavourite} variant="link" paddingLeft={0} paddingRight={0}>
      <FavouriteIcon variant={favourited ? 'active' : undefined} />
    </Button>
  );
};

export const FavouriteIcon = (props) => {
  const { variant } = props;

  let fill = '#291441';
  let opacity = 0.08;

  if (variant === 'active') {
    fill = '#E87451';
    opacity = '1';
  }

  if (variant === 'dark') {
    fill = '#291441';
    opacity = 0.5;
  }

  return (
    <Svg width="25" height="24" viewBox="0 0 25 24" fill="none">
      <Path
        d="M3.17473 14.1378L11.3489 21.7691C11.6882 22.0858 12.136 22.2622 12.602 22.2622C13.0679 22.2622 13.5157 22.0858 13.855 21.7691L22.0292 14.1378C23.4044 12.8576 24.1824 11.0617 24.1824 9.18442V8.92205C24.1824 5.76003 21.898 3.06395 18.7812 2.54374C16.7185 2.19994 14.6195 2.87396 13.1448 4.34866L12.602 4.8915L12.0591 4.34866C10.5844 2.87396 8.48546 2.19994 6.42269 2.54374C3.30591 3.06395 1.02148 5.76003 1.02148 8.92205V9.18442C1.02148 11.0617 1.79955 12.8576 3.17473 14.1378Z"
        fill={fill}
        opacity={opacity}
      />
      <Path
        d="M12.5594 4.62079L13.1219 4.05923C14.6922 2.52876 16.8672 1.8322 19.0047 2.18798C22.2344 2.7261 24.6016 5.52079 24.6016 8.79735V9.06923C24.6016 11.0145 23.7953 12.8755 22.3703 14.202L13.9 22.1099C13.5484 22.438 13.0844 22.6208 12.6016 22.6208C12.1187 22.6208 11.6547 22.438 11.3031 22.1099L2.83234 14.202C1.40922 12.8755 0.601562 11.0145 0.601562 9.06923V8.79735C0.601562 5.52079 2.96969 2.7261 6.19844 2.18798C8.29375 1.8322 10.5109 2.52876 12.0391 4.05923L12.5594 4.62079ZM12.5594 6.74423L10.9797 5.11766C9.79375 3.9322 8.10156 3.39126 6.44687 3.66735C3.93953 4.08548 2.05938 6.25673 2.05938 8.79735V9.06923C2.05938 10.602 2.73672 12.0599 3.85562 13.1052L12.325 21.013C12.4 21.0833 12.4984 21.1208 12.5594 21.1208C12.7047 21.1208 12.8031 21.0833 12.8781 21.013L21.3484 13.1052C22.4641 12.0599 23.1016 10.602 23.1016 9.06923V8.79735C23.1016 6.25673 21.2641 4.08548 18.7562 3.66735C17.0594 3.39126 15.4094 3.9322 14.2234 5.11766L12.5594 6.74423Z"
        fill="white"
      />
    </Svg>
  );
};

export default FavouriteButton;
