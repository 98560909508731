import { Box, Pressable, Text, View } from 'native-base';

import fontSize from '../styles/fontSize';

import FAProIcon from './FAProIcon';

const Counter = (props) => {
  const { value, onChange } = props;

  const handleDecrement = () => {
    if (onChange) {
      onChange(value - 1);
    }
  };

  const handleIncrement = () => {
    if (onChange) {
      onChange(value + 1);
    }
  };

  return (
    <View flexDirection="row" gap={2} alignItems="center" justifyContent="center">
      <Pressable onPress={handleDecrement}>
        <Box width={8} height={8} bg="mustard.500" borderRadius={16} alignItems="center" justifyContent="center">
          <FAProIcon name="minus" />
        </Box>
      </Pressable>
      <Text fontFamily="body" fontWeight={500} fontSize={fontSize(15)} width={42} textAlign="center" {...props}>
        {value}
      </Text>
      <Pressable onPress={handleIncrement}>
        <Box width={8} height={8} bg="mustard.500" borderRadius={16} alignItems="center" justifyContent="center">
          <FAProIcon name="plus" />
        </Box>
      </Pressable>
    </View>
  );
};

export default Counter;
