import React, { useContext } from 'react';
import { Box } from 'native-base';

import { FilterContext } from '../../../contexts/FilterContext';
import Chip from '../../Chip';

import FilterSection from './FilterSection';

const ChangeTotalTime = ({ label = 'Total time', boxProps }) => {
  const { timeMax, timeMaxSet } = useContext(FilterContext);

  const handleTotalTime = (time) => {
    timeMaxSet(time);
  };

  const handleClear = () => {
    timeMaxSet(9999);
  };

  return (
    <FilterSection label={label} clearFilter={handleClear} boxProps={boxProps}>
      <Box flexDirection="row" gap={2} flexWrap="wrap">
        <Chip
          label="Under 20 mins"
          variant={timeMax >= 20 ? 'active' : 'outline'}
          onPress={() => handleTotalTime(20)}
        />
        <Chip
          label="Under 30 mins"
          variant={timeMax >= 30 ? 'active' : 'outline'}
          onPress={() => handleTotalTime(timeMax === 30 ? 20 : 30)}
        />
        <Chip
          label="Under 45 mins"
          variant={timeMax >= 45 ? 'active' : 'outline'}
          onPress={() => handleTotalTime(timeMax === 45 ? 30 : 45)}
        />
        <Chip
          label="45 mins and more"
          variant={timeMax > 45 ? 'active' : 'outline'}
          onPress={() => handleTotalTime(timeMax === 9999 ? 45 : 9999)}
        />
      </Box>
    </FilterSection>
  );
};

export default ChangeTotalTime;
