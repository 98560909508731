import { useEffect, useState } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useLinkTo } from '@react-navigation/native';

let onboardingComplete = false;

const OnboardingRedirector = () => {
  const linkTo = useLinkTo();
  const [busy, busySet] = useState(true);

  useEffect(() => {
    AsyncStorage.getItem('onboarding-complete').then((a) => {
      busySet(false);
      if (a === 'true') {
        onboardingComplete = true;
      }
    });
  }, []);

  useEffect(() => {
    if (onboardingComplete) return;
    if (busy) return;

    linkTo('/onboarding');

    onboardingComplete = true;
  }, [busy, linkTo]);

  return null;
};

export default OnboardingRedirector;
