/* eslint-disable no-restricted-globals */
import { Button } from 'native-base';

import FAProIcon from '../../FAProIcon';

const FacebookLoginButtonWeb = (props) => {
  const handleClick = () => {
    const facebookLoginHref =
      `https://www.facebook.com/v14.0/dialog/oauth` +
      `?client_id=2665787293441815` +
      `&redirect_uri=${window.location.origin}/login/facebook` +
      `&state=123456` +
      `&scope=email` +
      `&response_type=token`;
    window.open(facebookLoginHref, '_self');
  };

  return (
    <Button
      variant="solid"
      bg="facebookBlue"
      size="large"
      leftIcon={<FAProIcon name="facebook" />}
      onPress={handleClick}
      {...props}
    >
      Continue with Facebook
    </Button>
  );
};

export default FacebookLoginButtonWeb;
