import { useContext, useEffect, useMemo, useState } from 'react';
import { useLinkTo } from '@react-navigation/native';

import { CheckoutContext } from '../../../contexts/CheckoutContext';
import { GlobalContext } from '../../../contexts/GlobalContext';
import { QuoteContext } from '../../../contexts/QuoteContext';
import { UserContext } from '../../../contexts/UserContext';
import useCart from '../../../hooks/useCart';
import { productsToAmazonFreshReq } from '../../../lib/get-quote';
import { CHECKOUT_EVENTS } from '../../../lib/tracking';
import CartAbortScreen from '../CartAbortScreen';
import CartBasketScreen from '../CartBasketScreen';
import CartErrorScreen from '../CartErrorScreen';
import CartExplainerScreen from '../CartExplainerScreen';
import CartWebViewScreen from '../CartWebViewScreen';

import AmazonFreshProductList from './AmazonFreshProductList';

const RETAILER_HOSTNAME = 'amazon.co.uk';
const RETAILER_LOGIN_URL = 'https://www.amazon.co.uk/ap/signin';
const RETAILER_BASKET_URL = 'https://www.amazon.co.uk/cart/localmarket';
const RETAILER_SLOT_URL = 'https://www.amazon.co.uk/gp/buy/shipoptionselect/handlers/display.html';
const RETAILER_CHECKOUT_URL = 'https://www.amazon.co.uk/gp/buy/payselect/handlers/display.html';
const RETAILER_CHECKOUT_CONFIRMATION_URL = 'https://www.amazon.co.uk/gp/buy/thankyou/handlers/display.html';
const RETAILER_MORRISONS_ONE = 'https://www.amazon.co.uk/alm/byg';
const RETAILER_MORRISONS_TWO = 'https://www.amazon.co.uk/gp/aw/c';

/**
 * Amazon checkout flow:
 * -> Explainer: For first time checkouts open Explainer
 * -> Checkout
 */
const urls = [];
const AmazonFreshCartScreen = () => {
  const linkTo = useLinkTo();

  const { cart, recipes } = useCart();

  const [error, errorSet] = useState(false);
  const [busy, busySet] = useState(false);
  const [products, productsSet] = useState([]);
  const [checkoutUrl, checkoutUrlSet] = useState('');
  const [explain, explainSet] = useState(false);
  const [aborted, abortedSet] = useState(false);
  const [checkoutComplete, checkoutCompleteSet] = useState(false);

  const [trackedRetailerLogin, trackedRetailerLoginSet] = useState(false);
  const [trackedBasket, trackedBasketSet] = useState(false);
  const [trackedSlot, trackedSlotSet] = useState(false);
  const [trackedCheckout, trackedCheckoutSet] = useState(false);
  const [trackedConfirm, trackedConfirmSet] = useState(false);

  const [morrisonsTriggered, morrisonsTriggeredSet] = useState(false);

  const { notLoggedInDialogOpenSet } = useContext(GlobalContext);
  const { profile, updateProfile } = useContext(UserContext);
  const { retailer } = useContext(QuoteContext);
  const { checkoutAmazonFresh, checkoutDone, trackCheckoutScreen, trackCheckoutEvent, setTrackedCheckoutItems } =
    useContext(CheckoutContext);

  useEffect(() => {
    const l = [];

    for (const r of recipes) {
      const multi = r.cartServings / r.servings;
      for (const ig of r.ingredientGroups) {
        for (const i of ig.list) {
          const f = l.find((a) => a.id === i.id);
          if (f) {
            f.quantity += i.cupboardItem ? 0 : i.quantity * multi;
            f.neededQuantity += i.quantity * multi;
          } else {
            l.push({
              ...i,
              quantity: i.cupboardItem ? 0 : i.quantity * multi,
              neededQuantity: i.quantity * multi,
              price: 0,
            });
          }
        }
      }
    }

    productsSet(l);
  }, [recipes]);

  const productInc = (p) => {
    productsSet(
      products.map((a) => ({
        ...a,
        quantity: a.id === p.id ? a.neededQuantity : a.quantity,
      })),
    );
  };
  const productDec = (p) => {
    productsSet(
      products.map((a) => ({
        ...a,
        quantity: a.id === p.id ? 0 : a.quantity,
      })),
    );
  };

  const amazonFreshData = useMemo(
    () => ({ ingredients: productsToAmazonFreshReq(products.filter((a) => !!a.quantity)) }),
    [products],
  );

  const handleCheckout = async () => {
    if (!profile) {
      notLoggedInDialogOpenSet(true);
      return;
    }

    if (!profile?.retailerCheckouts?.includes(retailer.id)) {
      if (explain) {
        updateProfile({ retailerCheckouts: [...(profile.retailerCheckouts ?? []), retailer.id] });
        explainSet(false);
      } else {
        explainSet(true);
        return;
      }
    }

    setTrackedCheckoutItems(cart, products, retailer);
    busySet(true);
    try {
      const data = await checkoutAmazonFresh(amazonFreshData);

      if (!data) return;

      if (data.redirect) {
        checkoutUrlSet(data.redirect);
      } else {
        throw new Error(`No redirect supplied on response ${JSON.stringify(data)}`);
      }
    } catch (e) {
      errorSet(true);
      console.error(e);
    } finally {
      busySet(false);
    }
  };

  const handleExplainBack = () => {
    explainSet(false);
  };

  const handleAbort = () => {
    trackCheckoutEvent(CHECKOUT_EVENTS.PressCancel);
    abortedSet(true);
  };

  const handleResume = () => {
    trackCheckoutEvent(CHECKOUT_EVENTS.PressContinueToCheckout);

    abortedSet(false);
  };

  const handleBackToCart = () => {
    trackCheckoutEvent(CHECKOUT_EVENTS.PressBackToBasket);
    trackedRetailerLoginSet(false);
    trackedBasketSet(false);
    trackedSlotSet(false);
    trackedCheckoutSet(false);
    trackedConfirmSet(false);
    checkoutUrlSet('');
    abortedSet(false);
  };

  const handleComplete = async () => {
    await checkoutDone();
    linkTo('/order-transferred');
  };

  const handleAlreadyCheckedOut = async () => {
    trackCheckoutEvent(CHECKOUT_EVENTS.PressAlreadyCheckedOut);
    return handleComplete();
  };

  const handleBackToPlateUp = async () => {
    trackCheckoutEvent(CHECKOUT_EVENTS.PressCheckoutComplete);
    return handleComplete();
  };

  const handleNavChange = async (event) => {
    const { url } = event;
    if (!urls.includes(url)) {
      urls.push(url);
      trackCheckoutScreen('AMAZON_URL', url);
      console.log(url);
    }
    checkoutUrlSet(url);

    if (!trackedRetailerLogin && url.startsWith(RETAILER_LOGIN_URL)) {
      trackedRetailerLoginSet(true);
      trackCheckoutScreen(CHECKOUT_EVENTS.ViewLogin, RETAILER_LOGIN_URL);
    }
    if (!trackedBasket && url.startsWith(RETAILER_BASKET_URL)) {
      trackedBasketSet(true);
      trackCheckoutScreen(CHECKOUT_EVENTS.ViewBasket, RETAILER_BASKET_URL);
    }
    if (!trackedSlot && url.startsWith(RETAILER_SLOT_URL)) {
      trackedSlotSet(true);
      trackCheckoutScreen(CHECKOUT_EVENTS.ViewSlotBooking, RETAILER_SLOT_URL);
    }
    if (!trackedCheckout && url.startsWith(RETAILER_CHECKOUT_URL)) {
      trackedCheckoutSet(true);
      trackCheckoutScreen(CHECKOUT_EVENTS.ViewPayment, RETAILER_CHECKOUT_URL);
    }
    if (!trackedConfirm && url.startsWith(RETAILER_CHECKOUT_CONFIRMATION_URL)) {
      trackedConfirmSet(true);
      trackCheckoutScreen(CHECKOUT_EVENTS.ViewConfirmation, RETAILER_CHECKOUT_CONFIRMATION_URL);
      checkoutCompleteSet(true);
    }
    if (!morrisonsTriggered && url.startsWith(RETAILER_MORRISONS_ONE)) {
      morrisonsTriggeredSet(true);
      trackCheckoutScreen(CHECKOUT_EVENTS.ViewPayment, RETAILER_MORRISONS_ONE);
    }
    if (morrisonsTriggered && url.startsWith(RETAILER_MORRISONS_TWO)) {
      checkoutCompleteSet(true);
      trackCheckoutScreen(CHECKOUT_EVENTS.ViewConfirmation, RETAILER_MORRISONS_TWO);
    }
  };

  if (error) {
    return <CartErrorScreen clearError={() => errorSet(false)} />;
  }

  if (explain) {
    return <CartExplainerScreen retailer={retailer} handleComplete={handleCheckout} handleBack={handleExplainBack} />;
  }

  if (aborted) {
    return (
      <CartAbortScreen
        handleComplete={handleAlreadyCheckedOut}
        handleBackToCart={handleBackToCart}
        handleResume={handleResume}
      />
    );
  }

  if (checkoutUrl) {
    return (
      <CartWebViewScreen
        url={checkoutUrl}
        handleNavChange={handleNavChange}
        handleAbort={handleAbort}
        handleComplete={handleBackToPlateUp}
        fakeUrl={RETAILER_HOSTNAME}
        checkoutComplete={checkoutComplete}
      />
    );
  }

  return (
    <CartBasketScreen
      products={products}
      retailer={retailer}
      isLoading={busy}
      handleCheckout={handleCheckout}
      ProductListComponent={AmazonFreshProductList}
      productListComponentProps={{ productInc, productDec }}
    />
  );
};

export default AmazonFreshCartScreen;
