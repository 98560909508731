export const DATA = [
  {
    recipeId: 'butternut-squash-coconut-dhal',
    chefImage: 'https://staging-cdn.plateup.app/common/20231013/2d3d8d9a-847e-459b-af87-92af1d6cf064',
    titleWord: 'smarter',
    costs: [
      { id: 'amazonfresh-gb', cost: 2.76 },
      { id: 'tesco', cost: 2.4 },
      { id: 'sainsburys', cost: 2.81 },
      { id: 'ocado', cost: 3.45 },
    ],
  },
  {
    recipeId: 'marry-me-chicken-orzo-',
    chefImage: 'https://staging-cdn.plateup.app/common/20231013/70a9869e-70f3-449b-8cb8-320a91cf7bb7',
    titleWord: 'faster',
    costs: [
      { id: 'amazonfresh-gb', cost: 2.3 },
      { id: 'tesco', cost: 3.82 },
      { id: 'sainsburys', cost: 5.96 },
      { id: 'ocado', cost: 6.04 },
    ],
  },
  {
    recipeId: 'greek-chicken-traybake',
    chefImage: 'https://staging-cdn.plateup.app/common/20231016/d97a35be-b240-413c-993f-a1b99c1f64f6',
    titleWord: 'cheaper',
    costs: [
      { id: 'amazonfresh-gb', cost: 2.96 },
      { id: 'tesco', cost: 3 },
      { id: 'sainsburys', cost: 2.96 },
      { id: 'ocado', cost: 3.69 },
    ],
  },
];
