import api from './api';

const RETAILER_SKU_PROP = {
  tesco: 'tescoSKU',
  'amazonfresh-gb': 'amazonfreshASIN',
};

export const breakpointsToIngredients = (ingredient, retailer) => {
  const { name, unit, breakpoints = [], quantity, cupboardItem } = ingredient;
  const retailerBreakpoints = breakpoints.filter((a) => a.retailer === retailer.id).filter((a) => a.quantity);

  const useCount = !['amazonfresh-gb'].includes(retailer.id);

  const skuProp = RETAILER_SKU_PROP[retailer.id];

  if (!retailerBreakpoints.length || !skuProp) {
    return [ingredient];
  }

  retailerBreakpoints.sort((a, b) => {
    if (a.quantity < b.quantity) return -1;
    if (a.quantity > b.quantity) return 1;
    return 0;
  });

  let remain = quantity;
  const res = [];

  const largest = retailerBreakpoints[retailerBreakpoints.length - 1];

  /**
Fill from largest to smallest, but fill remaining weight with "first (smallest) covering breakpoint"

2200 = 1*1600 + 1*650 (2250)
1700 = 1*1600 + 1*300 (1900)
1600 = 1*1600 (1600)
1500 = 1*1600 (1600)
660 = 1*1600 (1600)
   */

  // While the largest breakpoint is less than the remaining weight, add it
  while (remain >= largest.quantity) {
    const f = res.find((a) => a[skuProp] === largest.productId);
    const quantityAdd = useCount ? 1 : largest.quantity;

    if (f) {
      f.quantity += quantityAdd;
    } else {
      res.push({
        name,
        unit: useCount ? 'count' : unit,
        [skuProp]: largest.productId,
        quantity: quantityAdd,
        cupboardItem,
      });
    }
    remain -= largest.quantity;
  }

  // If we have remaining unsatisfied quantity
  if (remain > 0) {
    // Iterate over the breakpoints (smallest to largest)
    for (const b of retailerBreakpoints) {
      // If this breakpoint covers the remaining unsatisfied quantity, add one of it and break out.
      if (remain <= b.quantity) {
        const f = res.find((a) => a[skuProp] === b.productId);
        const quantityAdd = useCount ? 1 : b.quantity;

        if (f) {
          f.quantity += quantityAdd;
        } else {
          res.push({
            name,
            unit: useCount ? 'count' : unit,
            [skuProp]: b.productId,
            quantity: quantityAdd,
            cupboardItem,
          });
        }
        remain -= b.quantity;
        break;
      }
    }
  }

  return res;
};

const mapIngredient = (ingredient) => {
  const { name, unit, search, quantity, tescoSKU, amazonfreshASIN, cupboardItem } = ingredient;
  return { name, unit, search, quantity, tescoSKU, amazonfreshASIN, cupboardItem };
};

export const getQuote = async (ingredients, retailer, trackOnly) => {
  if (trackOnly) {
    return await api.quote.get({
      ingredients: ingredients.map(mapIngredient),
      retailers: [retailer.id],
      trackOnly,
    });
  }

  const res = await api.quote.get({
    ingredients: ingredients.reduce((a, c) => [...a, ...breakpointsToIngredients(c, retailer)], []).map(mapIngredient),
    retailers: [retailer.id],
  });

  return res;
};

const AmazonFreshUnit = {
  count: 'count',
  cups: 'cups',
  grams: 'grams',
  kilograms: 'kilograms',
  liters: 'liters',
  milliliters: 'milliliters',
  ounces: 'ounces',
  tbsp: 'tbsp',
  tsp: 'tsp',
  fl_oz: 'fl_oz',
  gallons: 'gallons',
  pints: 'pints',
  pounds: 'pounds',
  quarts: 'quarts',
};

const toAmazonQuantityList = (a, u) => {
  let amount = a;
  let unit = AmazonFreshUnit.count;
  switch (u) {
    case 'cup':
    case 'cups':
      unit = AmazonFreshUnit.cups;
      break;
    case 'oz':
      unit = AmazonFreshUnit.ounces;
      break;
    case 'kg':
      unit = AmazonFreshUnit.kilograms;
      break;
    case 'g':
      unit = AmazonFreshUnit.grams;
      break;
    case 'tsp':
      unit = AmazonFreshUnit.tsp;
      break;
    case 'tbsp':
      unit = AmazonFreshUnit.tbsp;
      break;
    case 'ml':
      unit = AmazonFreshUnit.milliliters;
      break;
    case 'l':
      unit = AmazonFreshUnit.liters;
      break;
    case 'splash':
    case 'dash':
    case 'pinch':
    case 'handful':
      /**
       * Since we're swapping this to 'count' we want to reduce the quantity to 1, otherwise "two handfuls of X"
       * will translate to quantity: 2 of X.
       * We could reflect this in the name, by returning it back to the product.name, however this may could make the
       * matching worse on amazons side, unless we're also providing brand names or ASINs to improve the matching.
       */
      amount = 1;
      unit = AmazonFreshUnit.count;
      break;
    default:
      unit = AmazonFreshUnit.count;
      break;
  }
  return [{ unit, amount }];
};

export const productsToAmazonFreshReq = (list) => {
  const l = list
    .reduce((a, c) => [...a, ...breakpointsToIngredients(c, { id: 'amazonfresh-gb' })], [])
    .map((p) => ({
      name: p.search || p.name,
      asinOverride: p.amazonfreshASIN,
      quantityList: toAmazonQuantityList(p.quantity, p.unit),
    }));

  return l;
};
