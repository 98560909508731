/* eslint-disable no-restricted-globals */
import { useContext, useState } from 'react';
import { Linking } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useLinkTo, useRoute } from '@react-navigation/native';
import { Box, Button } from 'native-base';

import CheckoutBottom from '../../../components/BottomDrawer/CheckoutBottom';
import Heading1 from '../../../components/Heading/Heading1';
import { CheckoutContext } from '../../../contexts/CheckoutContext';
import { QuoteContext } from '../../../contexts/QuoteContext';
import useCart from '../../../hooks/useCart';
import CartErrorScreen from '../CartErrorScreen';
import CartScreenHeader from '../CartScreenHeader';
import ProductList from '../ProductList';

/**
 * @deprecated we no longer support web checkouts in expo
 */
const SainsburysCartScreenWeb = () => {
  const linkTo = useLinkTo();
  const route = useRoute();
  const { cart } = useCart();
  const { retailerProducts, retailer } = useContext(QuoteContext);
  const { checkoutOauth, checkoutDone } = useContext(CheckoutContext);

  const [busy, busySet] = useState(false);
  const [error, errorSet] = useState(false);

  const [retailerLoginUrl, retailerLoginUrlSet] = useState('');

  const products = retailerProducts[retailer.id] || [];

  const { token: qsToken } = route?.params || { token: '' };

  const handleCheckout = async () => {
    busySet(true);
    const token = await AsyncStorage.getItem(`${retailer.id}-token`);

    const data = await checkoutOauth(token);

    if (!data) {
      busySet(false);
      return;
    }
    if (data.status === 'ItemsTransferred') {
      const url = data.retailerUrls?.trolleyUrl || '';

      Linking.openURL(url);

      await checkoutDone(cart, products, retailer);
      linkTo('/order-transferred');
    } else if (['CredentialsMissing', 'InvalidCredentials'].includes(data.status)) {
      await AsyncStorage.removeItem(`${retailer.id}-token`);

      const url =
        `https://login.whisk.com/retailers/oauth/v2/authorize?retailer=${retailer.id}&redirect_uri=` +
        `${window.location.origin}/cart`;

      retailerLoginUrlSet(url);
    } else {
      console.error('checkout error', data);
      errorSet(true);
    }
    busySet(false);
  };

  if (qsToken) {
    return (
      <Box height="100%">
        <Heading1>Cart</Heading1>
        <Button
          variant="link"
          isLoading={busy}
          onPress={async () => {
            busySet(true);
            await AsyncStorage.setItem(`${retailer.id}-token`, qsToken);
            handleCheckout();
          }}
        >
          Continue to {retailer.displayName}
        </Button>
      </Box>
    );
  }

  if (retailerLoginUrl) {
    return (
      <Box height="100%" bg="white">
        <Heading1>Cart</Heading1>
        <Button variant="link" isLoading={busy} onPress={() => (window.location = retailerLoginUrl)}>
          Login to {retailer.displayName}
        </Button>
      </Box>
    );
  }

  if (error) {
    return <CartErrorScreen clearError={() => errorSet(false)} />;
  }

  return (
    <Box height="100%" bg="white">
      <CartScreenHeader />

      <ProductList list={products} />

      <CheckoutBottom retailer={retailer} isLoading={busy} onPress={handleCheckout} />
    </Box>
  );
};

export default SainsburysCartScreenWeb;
