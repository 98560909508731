/* eslint-disable max-len */

import Svg, { ClipPath, Defs, G, LinearGradient, Path, Rect, Stop } from 'react-native-svg';

const PUIconGradient = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" width="65" height="65" viewBox="0 0 65 65" fill="none">
    <G clipPath="url(#clip0_1777_10418)">
      <Path d="M65.002 0.000244141H0.00195312V65.0002H65.002V0.000244141Z" fill="url(#paint0_linear_1777_10418)" />
      <Path
        d="M46.3379 31.8075C45.4799 30.1451 44.266 28.8646 42.6979 27.966C41.1297 27.0674 39.3244 26.6189 37.2866 26.6189C35.2489 26.6189 33.3037 27.095 31.695 28.0473C30.0862 28.9995 28.9195 30.3336 28.1947 32.0496H28.1541V27.2218H19.5059V54.6501H28.1947V43.1126H28.2354C28.9325 44.8026 30.0992 46.1303 31.7356 47.0955C33.3704 48.0608 35.2082 48.5434 37.2476 48.5434C39.287 48.5434 41.1297 48.0933 42.6979 47.1963C44.266 46.2976 45.4799 45.0171 46.3379 43.3548C47.1959 41.6924 47.6249 39.7749 47.6249 37.6023C47.6249 35.4296 47.1959 33.4731 46.3379 31.8091V31.8075ZM39.0709 39.9536C38.5606 40.6378 37.8554 41.174 36.9535 41.5624C36.0516 41.9508 35.0181 42.1458 33.8546 42.1458C32.6911 42.1458 31.656 41.9524 30.7557 41.5624C29.8539 41.174 29.147 40.6378 28.6384 39.9536C28.1281 39.2695 27.8746 38.4846 27.8746 37.6006C27.8746 36.7166 28.1297 35.9253 28.6384 35.2265C29.147 34.5294 29.8539 33.9931 30.7557 33.6178C31.6576 33.2424 32.6911 33.0539 33.8546 33.0539C35.0181 33.0539 36.0516 33.2424 36.9535 33.6178C37.8554 33.9931 38.5606 34.5294 39.0709 35.2265C39.5795 35.9236 39.8346 36.715 39.8346 37.6006C39.8346 38.4863 39.5795 39.2695 39.0709 39.9536Z"
        fill="#291441"
      />
      <Path
        d="M44.9311 11.9161C41.569 11.1149 37.1246 10.6746 32.4186 10.6746C27.7126 10.6746 23.2699 11.1149 19.9061 11.9161C15.6226 12.9366 13.541 14.4381 13.541 16.5099C13.541 18.5818 15.6226 20.0833 19.9061 21.1038C23.2683 21.9049 27.7126 22.3453 32.4186 22.3453C37.1246 22.3453 41.5674 21.9049 44.9311 21.1038C49.2146 20.0833 51.2963 18.5818 51.2963 16.5099C51.2963 14.4381 49.2146 12.9366 44.9311 11.9161ZM47.4304 16.5083C46.951 16.7862 45.8541 17.2981 43.7043 17.7758C40.6476 18.4534 36.6404 18.8272 32.4186 18.8272C28.1969 18.8272 24.1913 18.4534 21.1346 17.7758C18.988 17.2997 17.8911 16.7878 17.4101 16.5099C17.8911 16.2321 18.9864 15.7202 21.1346 15.2441C24.1896 14.5664 28.1985 14.1927 32.4186 14.1927C36.6388 14.1927 40.6476 14.5664 43.7043 15.2441C45.8509 15.7202 46.9478 16.2304 47.4304 16.5083Z"
        fill="#291441"
      />
    </G>
    <Defs>
      <LinearGradient
        id="paint0_linear_1777_10418"
        x1="32.502"
        y1="65.0002"
        x2="32.502"
        y2="0.000244141"
        gradientUnits="userSpaceOnUse"
      >
        <Stop stopColor="#EFDAC0" />
        <Stop offset="0.16" stopColor="#EED4B9" />
        <Stop offset="0.37" stopColor="#EDC3A7" />
        <Stop offset="0.63" stopColor="#EBA889" />
        <Stop offset="0.91" stopColor="#E88260" />
        <Stop offset="1" stopColor="#E87451" />
      </LinearGradient>
      <ClipPath id="clip0_1777_10418">
        <Rect y="0.000244141" width="65" height="65" rx="6.5" fill="white" />
      </ClipPath>
    </Defs>
  </Svg>
);

export default PUIconGradient;
