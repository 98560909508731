/* eslint-disable react-native/no-inline-styles */
import { useEffect } from 'react';
import { Video } from 'expo-av';
import { Box, Button } from 'native-base';

import Body2Bold from '../../components/Body/Body2Bold';
import Heading3Bold from '../../components/Heading/Heading3Bold';
import { isMobileApp } from '../../lib/util';

import OnboardingHeader from './OnboardingHeader';

const OnboardingScreenAnimation2 = (props) => {
  const { onNext, onPrev, onSkip } = props;

  useEffect(() => {
    if (!isMobileApp) {
      onNext();
    }
  }, [onNext]);

  if (!isMobileApp) return null;

  return (
    <Box height="100%" bg="almond.400">
      <Box position="absolute" bg="almond.200" bottom={0} width="100%" height="50%" />
      <Video
        source={require('../../../assets/mp4/animation-get.mp4')}
        rate={1.0}
        volume={0.0}
        isMuted={true}
        resizeMode="contain"
        shouldPlay
        style={{ width: '100%', height: '100%', marginTop: 60 }}
      />

      <Box position="absolute" top={0} width="100%" paddingY={6} paddingX={4}>
        <OnboardingHeader onPrev={onPrev} onSkip={onSkip} active={2} />
        <Box alignItems="center" gap={4} py={4} width="100%">
          <Heading3Bold textAlign="center">2. Get your groceries</Heading3Bold>
          <Body2Bold textAlign="center">Price compare and buy only what you need</Body2Bold>
        </Box>
      </Box>

      <Box position="absolute" bg="almond.200" bottom={0} width="100%" paddingBottom={6} paddingX={4}>
        <Button onPress={onNext}>Next</Button>
      </Box>
    </Box>
  );
};

export default OnboardingScreenAnimation2;
