import * as Notifications from 'expo-notifications';

export const showNotification = async (title, body, link) => {
  Notifications.setNotificationHandler({
    handleNotification: async () => ({
      shouldShowAlert: true,
      shouldPlaySound: false,
      shouldSetBadge: false,
    }),
  });

  await Notifications.scheduleNotificationAsync({
    content: {
      title,
      body,
      data: {
        link,
      },
    },
    trigger: null,
  });
};
