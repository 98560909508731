/* eslint-disable no-restricted-globals */
import { Platform } from 'react-native';

import LoginAppleScreenAndroid from './LoginAppleScreenAndroid';
import LoginAppleScreenIos from './LoginAppleScreenIos';
import LoginAppleScreenWeb from './LoginAppleScreenWeb';

const LoginAppleScreen = (props) => {
  if (Platform.OS === 'ios') {
    return <LoginAppleScreenIos {...props} />;
  }

  if (Platform.OS === 'android') {
    return <LoginAppleScreenAndroid {...props} />;
  }

  return <LoginAppleScreenWeb {...props} />;
};

export default LoginAppleScreen;
