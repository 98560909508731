const typography = {
  fontConfig: {
    Bagoss: {
      700: { normal: 'Bagoss-Bold' },
      600: { normal: 'Bagoss-SemiBold' },
      400: { normal: 'Bagoss-Regular' },
    },
    Manrope: {
      800: { normal: 'Manrope-ExtraBold' },
      700: { normal: 'Manrope-Bold' },
      500: { normal: 'Manrope-Medium' },
    },
  },

  fonts: {
    heading: 'Bagoss',
    subHeading: 'Manrope',
    body: 'Manrope',
    quote: 'Bagoss',
  },
};

export default typography;
