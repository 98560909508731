import React, { useCallback } from 'react';

import useCrudTables from '../hooks/useCrudTables';
import api from '../lib/api';

const ChefLiveContext = React.createContext({});

const ChefLiveProvider = (props) => {
  const { children } = props;

  const {
    loading,
    rows,
    list,
    get,
    post,
    put,
    del,
    copyLiveToDraft,
    moveDraftToLive,
    moveDraftToArchive,
    moveLiveToArchive,
    moveArchiveToDraft,
    moveArchiveToLive,
  } = useCrudTables('live', api.chef);

  const find = useCallback((id) => rows.find((a) => a.id === id), [rows]);

  const value = {
    loading,
    rows,
    list,
    get,
    post,
    put,
    del,
    copyLiveToDraft,
    moveDraftToLive,
    moveDraftToArchive,
    moveLiveToArchive,
    moveArchiveToDraft,
    moveArchiveToLive,
    find,
  };

  return <ChefLiveContext.Provider value={value}>{children}</ChefLiveContext.Provider>;
};

export { ChefLiveContext, ChefLiveProvider };
