import React from 'react';
import { Box, Button, IconButton, useDisclose } from 'native-base';

import Body3Bold from '../Body/Body3Bold';
import Caption from '../Caption';
import FAProIcon from '../FAProIcon';
import Heading2 from '../Heading/Heading2';
import TextField from '../TextField';

import BottomDrawer from './BottomDrawer';

const AccountScreenDeleteOther = ({ otherReason, onChange }) => {
  const { isOpen, onOpen, onClose } = useDisclose();

  return (
    <>
      <Button variant={otherReason ? 'accent' : 'ghost'} width="100%" onPress={onOpen}>
        <Body3Bold textAlign="center">Other</Body3Bold>
      </Button>
      <BottomDrawer isOpen={isOpen} onClose={onClose} pt={0} pb={8}>
        <Box height="100%" width="100%">
          <Box width="100%" alignItems="center" justifyContent="space-between" flexDirection="row" py={3}>
            <Box flex="1" />
            <Heading2 flex="1" textAlign="center" ml="auto">
              Other reason
            </Heading2>
            <Box flex="1">
              <IconButton ml="auto" variant="ghost" size="large" icon={<FAProIcon name="close" />} onPress={onClose} />
            </Box>
          </Box>
          <Box py={8}>
            <Caption>What could we have done differently?</Caption>
            <TextField value={otherReason} onChange={onChange} placeholder="Please type here..." />
          </Box>
          <Box
            mt="auto"
            pt={6}
            pb={2}
            justifyContent="space-between"
            alignItems="flex-end"
            borderTopWidth="1px"
            borderTopColor="#E3E3E3"
          >
            <Button size="large" onPress={onClose}>
              Save
            </Button>
          </Box>
        </Box>
      </BottomDrawer>
    </>
  );
};

export default AccountScreenDeleteOther;
