import React from 'react';
import { Box, HStack, IconButton, VStack } from 'native-base';

import Body2Bold from '../../../components/Body/Body2Bold';
import FAProIcon from '../../../components/FAProIcon';
import ImageWrapper from '../../../components/ImageWrapper';
import SubHeading1 from '../../../components/SubHeading/SubHeading1';
import SubHeading2 from '../../../components/SubHeading/SubHeading2';
import { fromGramsTo } from '../../../lib/convert';
import { friendlyNumber } from '../../../lib/util';

const AmazonFreshProductListItem = (props) => {
  const { product, productInc, productDec } = props;

  const inc = () => productInc(product);
  const dec = () => productDec(product);

  const quantity = product.displayUnit
    ? fromGramsTo(product.displayUnit, product.neededQuantity, product.typicalSize)
    : product.neededQuantity;

  const unit = product.displayUnit ? product.displayUnit : product.unit;

  const friendlyNum = friendlyNumber(quantity, unit);

  return (
    <HStack paddingY={3} paddingX={5} maxWidth="100%" width="393px" alignItems="center" space={2}>
      <Box
        width={16}
        height={16}
        borderRadius="12px"
        borderWidth="0.7px"
        borderColor="#E3E3E3"
        bgColor="white"
        padding={1}
        alignItems="center"
        justifyContent="center"
      >
        <ImageWrapper displayWidth={64} width="100%" height="100%" src={product.image} alt={product.name} />
      </Box>

      <VStack flex={1}>
        <SubHeading1>{product.name}</SubHeading1>

        <HStack>
          <Body2Bold>{`${friendlyNum} ${unit}`}</Body2Bold>
        </HStack>
      </VStack>

      {!!product.quantity && (
        <IconButton size="large" variant="solid" onPress={dec}>
          <FAProIcon name="minus" color="white" size="24px" />
        </IconButton>
      )}

      {!product.quantity && (
        <IconButton size="large" variant="solid" onPress={inc}>
          <FAProIcon name="add" color="white" size="24px" />
        </IconButton>
      )}
    </HStack>
  );
};

const AmazonFreshProductList = (props) => {
  const { list, productInc, productDec } = props;

  const mainItems = list
    .filter((a) => !a.cupboardItem)
    .filter((a) => !!a.quantity)
    .map((p) => (
      <AmazonFreshProductListItem
        key={`mainItems-${p.id}`}
        product={p}
        productInc={productInc}
        productDec={productDec}
      />
    ));
  const secondItems = list
    .filter((a) => a.cupboardItem)
    .filter((a) => !!a.quantity)
    .map((p) => (
      <AmazonFreshProductListItem
        key={`secondItems-${p.id}`}
        product={p}
        productInc={productInc}
        productDec={productDec}
      />
    ));

  const thirdItems = list
    .filter((a) => !a.cupboardItem)
    .filter((a) => !a.quantity)
    .map((p) => (
      <AmazonFreshProductListItem
        key={`thirdItems-${p.id}`}
        product={p}
        productInc={productInc}
        productDec={productDec}
      />
    ));
  const fourthItems = list
    .filter((a) => a.cupboardItem)
    .filter((a) => !a.quantity)
    .map((p) => (
      <AmazonFreshProductListItem
        key={`fourthItems-${p.id}`}
        product={p}
        productInc={productInc}
        productDec={productDec}
      />
    ));

  return (
    <Box>
      <SubHeading2 paddingY={3} paddingX={5}>
        Your basket
      </SubHeading2>
      {mainItems}
      {secondItems}

      <SubHeading2 paddingY={3} paddingX={5}>
        You may also need...
      </SubHeading2>
      {thirdItems}
      {fourthItems}

      <Box height={200} />
    </Box>
  );
};

export default AmazonFreshProductList;
