import { Box, Pressable, Text } from 'native-base';

import fontSize from '../styles/fontSize';

import FAProIcon from './FAProIcon';

const Filter = (props) => {
  const { title, label, onPress } = props;

  return (
    <Pressable
      onPress={onPress}
      flexDirection="row"
      width="352px"
      justifyContent="space-between"
      alignItems="center"
      borderRadius="100px"
      bg="almond.200"
      height="64px"
      paddingLeft="19px"
      paddingRight="19px"
      paddingTop="12px"
      paddingBottom="12px"
      maxWidth="100%"
    >
      <Text fontFamily="body" fontWeight={700} fontSize={fontSize(16)} color="brightNavy.700">
        {title}
      </Text>
      <Box flexDirection="row" alignItems="center" gap={2}>
        <Text fontFamily="body" fontWeight={500} fontSize={fontSize(15)} color="brightNavy.700">
          {label}
        </Text>
        <FAProIcon name="chevron-right" />
      </Box>
    </Pressable>
  );
};

export default Filter;
