const MULTI = {
  whole: 1,
  g: 1,
  ml: 1,
  kg: 1000,
  l: 1000,
  c: 238,
  cups: 238,
  oz: 28,
  tsp: 5,
  tbsp: 15,
  splash: 5,
  dash: 0.6,
  pinch: 0.3,
  handful: 28,
  'to serve': 1,
  'to garnish': 1,
  'to taste': 1,
};

export const fromGramsTo = (u, q, typicalSize) => {
  const n = Number(q);
  const t = Number(typicalSize);
  if (!n) return 0;
  if (u === 'whole' && t) return q / t;
  if (!MULTI[u]) return 0;
  const a = n / MULTI[u];
  return a;
};

export const toGramsFrom = (u, q, typicalSize) => {
  const n = Number(q);
  const t = Number(typicalSize);
  if (!n) return 0;
  if (u === 'whole' && t) return q * t;
  if (!MULTI[u]) return 0;
  if (['g', 'ml'].includes(u)) return n;
  const a = n * MULTI[u];
  return a;
};
