import rest from './rest';

const login = async (email, password) => {
  const body = { email: email.toLowerCase(), password };
  const res = await rest.post('/v8/user/login', body);
  if (!res.ok) throw new Error('Login failed');
  const data = await res.json();
  return data;
};

const DEFAULT_PROFILE = {
  firstName: 'user',
  lastName: '',
  ordered: [],
  favourites: [],
  retailer: '',
  householdSize: 2,
  cart: [],
  tags: [],
};

const getProfile = async () => {
  if (!rest.getHeaders().Authorization) throw new Error('Not logged in');
  const res = await rest.get('/v8/user/profile');
  const data = await res.json();
  return { ...DEFAULT_PROFILE, ...data };
};

const register = async (email, password, profile) => {
  const body = { email: email.toLowerCase(), password, profile };
  const res = await rest.post('/v8/user', body);
  const data = await res.json();
  return data;
};

const updateProfile = async (p) => {
  if (!rest.getHeaders().Authorization) throw new Error('Not logged in');
  const res = await rest.patch('/v8/user/profile', p);
  const data = await res.json();
  return { ...DEFAULT_PROFILE, ...data };
};

const deleteAccount = async () => {
  await rest.del('/v8/user/');
};

const resetPassword = async (email) => {
  await rest.post('/v8/user/reset-password', { email: email.toLowerCase() });
};

const resetPasswordConfirm = async (email, code, password) => {
  await rest.post('/v8/user/reset-password/confirm', { email: email.toLowerCase(), code, password });
};

const user = { register, login, getProfile, updateProfile, deleteAccount, resetPassword, resetPasswordConfirm };

export default user;
