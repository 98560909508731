import React from 'react';

import ImageWrapper from './ImageWrapper';

const ChefAvatar = ({ chef, size = 36 }) => {
  if (!chef) return null;
  return (
    <ImageWrapper
      displayWidth={size}
      src={chef?.avatar || ''}
      alt={chef?.name || 'chef'}
      size={size}
      borderRadius={size}
      borderWidth={1.6}
      borderColor="white"
    />
  );
};

export default ChefAvatar;
