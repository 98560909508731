import React, { useContext } from 'react';
import { Box } from 'native-base';

import { FilterContext } from '../../../contexts/FilterContext';
import Chip from '../../Chip';

import FilterSection from './FilterSection';

const ChangePrepTime = ({ label = 'Prep time', boxProps }) => {
  const { prepTimeMax, prepTimeMaxSet } = useContext(FilterContext);

  const handlePrepTime = (time) => {
    prepTimeMaxSet(time);
  };

  const handleClear = () => {
    prepTimeMaxSet(9999);
  };

  return (
    <FilterSection label={label} clearFilter={handleClear} boxProps={boxProps}>
      <Box flexDirection="row" gap={2} flexWrap="wrap">
        <Chip
          label="Under 5 mins"
          variant={prepTimeMax >= 5 ? 'active' : 'outline'}
          onPress={() => handlePrepTime(5)}
        />
        <Chip
          label="Under 10 mins"
          variant={prepTimeMax >= 10 ? 'active' : 'outline'}
          onPress={() => handlePrepTime(prepTimeMax === 10 ? 5 : 10)}
        />
        <Chip
          label="Under 15 mins"
          variant={prepTimeMax >= 15 ? 'active' : 'outline'}
          onPress={() => handlePrepTime(prepTimeMax === 15 ? 10 : 15)}
        />
        <Chip
          label="15 mins and more"
          variant={prepTimeMax > 15 ? 'active' : 'outline'}
          onPress={() => handlePrepTime(prepTimeMax === 9999 ? 15 : 9999)}
        />
      </Box>
    </FilterSection>
  );
};

export default ChangePrepTime;
