import { useContext, useMemo } from 'react';
import { useLinkTo } from '@react-navigation/native';
import { Box, Button, Center, ScrollView } from 'native-base';

import Body3 from '../../components/Body/Body3';
import PlaceholderCartPage from '../../components/Placeholder/PlaceholderCartPage/PlaceholderCartPage';
import SubHeading1 from '../../components/SubHeading/SubHeading1';
import TopNav2 from '../../components/TopNav2';
import { CheckoutContext } from '../../contexts/CheckoutContext';
import useCart from '../../hooks/useCart';

const EmptyCartScreen = () => {
  const linkTo = useLinkTo();

  const { cart } = useContext(CheckoutContext);
  const { restore } = useCart();

  const canRestore = useMemo(() => cart.length > 0, [cart]);

  const handleExplore = () => {
    linkTo('/recipe');
  };

  const handleRestore = () => {
    restore(cart);
  };

  return (
    <Box bg="white" height="100%">
      <TopNav2 back title="Basket" />
      <ScrollView>
        <Center width="100%">
          <PlaceholderCartPage />
        </Center>
        <Center gap={6} px={10} py={6} pt="70px" width="100%" position="absolute" top={0}>
          <Center>
            <SubHeading1>Your basket is empty</SubHeading1>
            <Body3>Add recipes to your basket to see them here</Body3>
            {canRestore && (
              <Body3>alternatively you can restore your previous basket if you have not finished shopping.</Body3>
            )}
          </Center>
          <Center gap={2}>
            <Button onPress={handleExplore} size="fullWidth" variant="solid" maxWidth="195px">
              Explore recipes
            </Button>
            {canRestore && (
              <Button onPress={handleRestore} size="fullWidth" variant="subtle" maxWidth="195px">
                Restore previous cart
              </Button>
            )}
          </Center>
        </Center>
      </ScrollView>
    </Box>
  );
};

export default EmptyCartScreen;
