import { useContext, useEffect, useRef, useState } from 'react';
import { useIsFocused, useLinkTo } from '@react-navigation/native';
import { Box, Button, Center } from 'native-base';

import Body3 from '../components/Body/Body3';
import Loading from '../components/Loading';
import NotLoggedIn from '../components/NotLoggedIn';
import RecipeCardList from '../components/RecipeCardList';
import SubHeading1 from '../components/SubHeading/SubHeading1';
import { UserContext } from '../contexts/UserContext';
import useFavourites from '../hooks/useFavourites';

const FavouritesScreen = () => {
  const { recipes, loading } = useFavourites();
  const { profile } = useContext(UserContext);
  const linkTo = useLinkTo();

  const isFocused = useIsFocused();

  const [currentRecipes, setCurrentRecipes] = useState(recipes);

  const showLoader = useRef(true);

  const showLoaderSet = (value) => {
    showLoader.current = value;
  };

  useEffect(() => {
    if (!showLoader.current) {
      showLoaderSet(true);
    }
  }, [isFocused]);

  useEffect(() => {
    if (!isFocused) {
      setCurrentRecipes(recipes);
    }
  }, [isFocused, recipes]);

  if (!isFocused && showLoader.current) return <Loading />;

  if (loading) {
    return <Loading />;
  }

  if (!profile) {
    return <NotLoggedIn />;
  }

  const handleExplore = () => {
    linkTo('/recipe');
  };

  if (!currentRecipes.length) {
    return (
      <Center px={10} py={6} flex={1} backgroundColor="white" gap={1}>
        <SubHeading1>You haven't favourited any recipes yet...</SubHeading1>
        <Body3>Click the heart icon on a recipe to add it to favourites</Body3>

        <Button variant="solid" mt={4} onPress={handleExplore}>
          Explore recipes
        </Button>
      </Center>
    );
  }

  return (
    <Box height="100%" bg="white">
      <RecipeCardList list={currentRecipes} showLoaderSet={showLoaderSet} />
    </Box>
  );
};

export default FavouritesScreen;
