import React from 'react';
import { Box, Pressable } from 'native-base';

import Body1 from './Body/Body1';
import FAProIcon from './FAProIcon';

const ListItem = ({ icon, text, onPress, disabled, color }) => (
  <Pressable onPress={onPress} disabled={disabled} opacity={disabled ? 0.4 : undefined}>
    <Box flexDirection="row" gap="1" alignItems="center" paddingY="2">
      <Box padding="2">
        <FAProIcon color={color} name={icon} size={16} />
      </Box>
      <Body1 color={color}>{text}</Body1>
    </Box>
  </Pressable>
);

export default ListItem;
