import React from 'react';
import { Box, useTheme } from 'native-base';

import Body3Bold from '../Body/Body3Bold';
import TopNavCartButton from '../TopNavCartButton';

const RecipeAddedToast = () => {
  const { colors } = useTheme();

  return (
    <Box
      width="353px"
      maxWidth="100%"
      backgroundColor={colors.almond[400]}
      alignItems="center"
      justifyContent="space-between"
      paddingLeft={5}
      paddingY={2}
      paddingRight={2}
      flexDirection="row"
      borderRadius="32px"
      mt="10px"
      _ios={{
        mt: '72px',
      }}
    >
      <Body3Bold>Recipe added to your basket</Body3Bold>
      {/* disableOnPress has been added as the Toasts are outside the NavigationContainer */}
      <TopNavCartButton variant="ghost" disableOnPress />
    </Box>
  );
};

export default RecipeAddedToast;
