import { View } from 'native-base';
import { Box } from 'native-base';

import CustomText from '../../../components/CustomText';
import ImageWrapper from '../../../components/ImageWrapper';
import fontSize from '../../../styles/fontSize';

const RecipeTile = ({ recipe }) => {
  const ingredientTiles = recipe.ingredientGroups[0].list
    .filter((a) => !a.cupboardItem)
    .sort((a, b) => (a.id < b.id ? -1 : 1))
    .map((a) => <ImageWrapper key={a.id} borderRadius={8} alt="" width={6} height={6} src={a.image} />);

  return (
    <View
      // eslint-disable-next-line react-native/no-inline-styles, react-native/no-color-literals
      style={{
        position: 'absolute',
        left: 0,
        bottom: 35,
        backgroundColor: 'white',
        borderRadius: 10,
        padding: 12,
        maxWidth: 175,
        elevation: 10,
        shadowColor: 'black',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
      }}
    >
      <ImageWrapper borderRadius={4} alt="" width="100%" height={100} src={recipe.images[0]} />
      <CustomText textAlign="center" fontFamily="heading" fontWeight={600} fontSize={fontSize(16)}>
        {recipe.name}
      </CustomText>
      <Box flexDirection="row" flexWrap="wrap" gap={1.5}>
        {ingredientTiles}
      </Box>
    </View>
  );
};

export default RecipeTile;
