/* eslint-disable react-native/no-inline-styles */
import { useContext } from 'react';
import { useLinkTo } from '@react-navigation/native';
import * as AppleAuthentication from 'expo-apple-authentication';

import { GlobalContext } from '../../../contexts/GlobalContext';
import { UserContext } from '../../../contexts/UserContext';

const AppleLoginButtonIos = () => {
  const linkTo = useLinkTo();

  const { loginApple } = useContext(UserContext);
  const { notLoggedInRedirect } = useContext(GlobalContext);

  return (
    <AppleAuthentication.AppleAuthenticationButton
      buttonType={AppleAuthentication.AppleAuthenticationButtonType.SIGN_IN}
      buttonStyle={AppleAuthentication.AppleAuthenticationButtonStyle.WHITE_OUTLINE}
      cornerRadius={44}
      style={{ width: '100%', height: 44 }}
      onPress={async () => {
        try {
          const credential = await AppleAuthentication.signInAsync({
            requestedScopes: [
              AppleAuthentication.AppleAuthenticationScope.FULL_NAME,
              AppleAuthentication.AppleAuthenticationScope.EMAIL,
            ],
          });
          console.log('credential', credential);

          const { authorizationCode, identityToken } = credential;
          console.log('loginApple', authorizationCode, identityToken);
          await loginApple(authorizationCode, identityToken);
          if (notLoggedInRedirect) {
            linkTo(notLoggedInRedirect);
          } else {
            linkTo('/home');
          }

          // signed in
        } catch (e) {
          console.log('apple login error', e);
          if (e.code === 'ERR_REQUEST_CANCELED') {
            // handle that the user canceled the sign-in flow
            linkTo('/login');
          } else {
            // handle other errors
            linkTo('/login');
          }
        }
      }}
    />
  );
};

export default AppleLoginButtonIos;
