import { useContext, useEffect, useRef } from 'react';
import { useIsFocused } from '@react-navigation/native';
import { Box } from 'native-base';

import Loading from '../components/Loading';
import RecipeCardList from '../components/RecipeCardList';
import { WeightedContext } from '../contexts/WeightedContext';

const RecipeListScreen = () => {
  const { weightedList } = useContext(WeightedContext);

  const isFocused = useIsFocused();

  const showLoader = useRef(true);

  const showLoaderSet = (value) => {
    showLoader.current = value;
  };

  useEffect(() => {
    if (!showLoader.current) {
      showLoaderSet(true);
    }
  }, [isFocused]);

  if (!isFocused && showLoader.current) return <Loading />;

  const recipes = weightedList;

  return (
    <Box height="100%" bg="white">
      <RecipeCardList list={recipes} withFilters displayTotalRecipes showLoaderSet={showLoaderSet} />
    </Box>
  );
};

export default RecipeListScreen;
