import rest from './rest';

const post = async (payload) => {
  const res = await rest.postNoCheck(`/v8/checkout`, payload);
  const data = await res.json();
  return data;
};

const postAmazonFresh = async (payload) => {
  const res = await rest.postNoCheck(`/v8/amazon-fresh/checkout`, payload);
  const data = await res.json();
  return data;
};

const checkout = { post, postAmazonFresh };
export default checkout;
