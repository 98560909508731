import React, { useContext } from 'react';

import { CommonContext } from './CommonContext';
import { FilterContext } from './FilterContext';

const W_PILLAR = 1000;

const WeightedContext = React.createContext({});

const WeightedProvider = (props) => {
  const { children } = props;
  const { filteredList } = useContext(FilterContext);
  const { pillarQuickEats, pillarMakeAhead, pillarSlowerCooks } = useContext(CommonContext);

  const weightedList = filteredList.map((recipe) => {
    let weight = 0;

    const p1i = pillarQuickEats.recipes.findIndex((a) => a.id === recipe.id);
    const p2i = pillarMakeAhead.recipes.findIndex((a) => a.id === recipe.id);
    const p3i = pillarSlowerCooks.recipes.findIndex((a) => a.id === recipe.id);

    if (p1i >= 0) {
      weight += W_PILLAR - p1i;
    } else if (p2i >= 0) {
      weight += W_PILLAR - p2i;
    } else if (p3i >= 0) {
      weight += W_PILLAR - p3i;
    }

    return { ...recipe, weight };
  });

  weightedList.sort((a, b) => b.weight - a.weight);

  const value = {
    weightedList,
  };

  return <WeightedContext.Provider value={value}>{children}</WeightedContext.Provider>;
};

export { WeightedContext, WeightedProvider };
