import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useLinkTo } from '@react-navigation/native';
import { Box, Button, ScrollView, useTheme } from 'native-base';

import Body2Bold from '../../components/Body/Body2Bold';
import Body3Bold from '../../components/Body/Body3Bold';
import AccountScreenDeleteOther from '../../components/BottomDrawer/AccountScreenDeleteOther';
import Heading3Bold from '../../components/Heading/Heading3Bold';
import TopNav2 from '../../components/TopNav2';
import { UserContext } from '../../contexts/UserContext';
import tracking from '../../lib/tracking';

const AccountScreenDelete = () => {
  const { colors } = useTheme();
  const linkTo = useLinkTo();

  const { deleteAccount } = useContext(UserContext);

  const [couldntFindRecipes, couldntFindRecipesSet] = useState(false);
  const [didntUnderstand, didntUnderstandSet] = useState(false);
  const [tooExpensive, tooExpensiveSet] = useState(false);
  const [lackingSupermarket, lackingSupermarketSet] = useState(false);
  const [lackingChef, lackingChefSet] = useState(false);
  const [exploring, exploringSet] = useState(false);
  const [otherReason, otherReasonSet] = useState('');
  const [confirm, confirmSet] = useState(false);

  const confirmEnabled = useMemo(
    () =>
      couldntFindRecipes ||
      didntUnderstand ||
      tooExpensive ||
      lackingSupermarket ||
      lackingChef ||
      exploring ||
      otherReason,
    [couldntFindRecipes, didntUnderstand, tooExpensive, lackingSupermarket, lackingChef, exploring, otherReason],
  );

  const handleBack = confirm ? () => confirmSet(false) : undefined;

  const handleDelete = useCallback(() => {
    const payload = {
      couldntFindRecipes,
      didntUnderstand,
      tooExpensive,
      lackingSupermarket,
      lackingChef,
      exploring,
      other: !!otherReason,
      otherReason,
    };

    tracking.trackDeleteAccount(payload);
    deleteAccount();

    linkTo('/home');
  }, [
    couldntFindRecipes,
    didntUnderstand,
    tooExpensive,
    lackingSupermarket,
    lackingChef,
    exploring,
    otherReason,
    linkTo,
    deleteAccount,
  ]);

  const deletionForm = (
    <>
      <ScrollView>
        <Box gap={4} py={3} pb={6}>
          <Heading3Bold textAlign="center">What could we have done differently?</Heading3Bold>
          <Body2Bold textAlign="center" color={colors.mushroom[500]}>
            Please let us know how we can improve our service in the future by choosing relevant options below.
          </Body2Bold>
        </Box>
        <Box gap={2} px={3}>
          <Button
            variant={couldntFindRecipes ? 'accent' : 'outline'}
            width="100%"
            onPress={() => couldntFindRecipesSet(!couldntFindRecipes)}
          >
            <Body3Bold textAlign="center">I couldn't find recipes that I liked</Body3Bold>
          </Button>
          <Button
            variant={didntUnderstand ? 'accent' : 'outline'}
            width="100%"
            onPress={() => didntUnderstandSet(!didntUnderstand)}
          >
            <Body3Bold textAlign="center">I didn't understand how to use the app</Body3Bold>
          </Button>
          <Button
            variant={tooExpensive ? 'accent' : 'outline'}
            width="100%"
            onPress={() => tooExpensiveSet(!tooExpensive)}
          >
            <Body3Bold textAlign="center">The recipes were too expensive</Body3Bold>
          </Button>
          <Button
            variant={lackingSupermarket ? 'accent' : 'outline'}
            width="100%"
            onPress={() => lackingSupermarketSet(!lackingSupermarket)}
          >
            <Body3Bold textAlign="center">You don't have my preferred supermarket</Body3Bold>
          </Button>
          <Button
            variant={lackingChef ? 'accent' : 'outline'}
            width="100%"
            onPress={() => lackingChefSet(!lackingChef)}
          >
            <Body3Bold textAlign="center">I couldn't find chefs that I liked</Body3Bold>
          </Button>
          <Button variant={exploring ? 'accent' : 'outline'} width="100%" onPress={() => exploringSet(!exploring)}>
            <Body3Bold textAlign="center">I was just exploring</Body3Bold>
          </Button>
          <AccountScreenDeleteOther otherReason={otherReason} onChange={otherReasonSet} />
        </Box>
      </ScrollView>
      <Button isDisabled={!confirmEnabled} variant="solid" width="100%" onPress={() => confirmSet(true)} mt="auto">
        Confirm
      </Button>
    </>
  );

  const confirmDelete = (
    <>
      <ScrollView gap={6}>
        <Box gap={4} py={3}>
          <Heading3Bold textAlign="center">We're sorry to see you go</Heading3Bold>
          <Body2Bold textAlign="center" color={colors.mushroom[500]}>
            Once you delete your account you won't have access to your favourite recipes or be able to checkout with
            your chosen supermarket. We'll remove all your data.
          </Body2Bold>
          <Body2Bold textAlign="center" color={colors.mushroom[500]}>
            Are you sure you want to delete your account?
          </Body2Bold>
        </Box>
      </ScrollView>
      <Button variant="solid" bg={colors.ketchup[500]} width="100%" onPress={handleDelete} mt="auto">
        Delete Account
      </Button>
    </>
  );

  const content = confirm ? confirmDelete : deletionForm;

  return (
    <Box flex={1}>
      <TopNav2 back onBackPress={handleBack} />
      <Box flex={1} bg="white" py={3} px={5}>
        {content}
      </Box>
    </Box>
  );
};

export default AccountScreenDelete;
