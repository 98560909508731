import React, { useContext, useMemo } from 'react';
import { Box } from 'native-base';

import { QuoteContext } from '../../contexts/QuoteContext';
import useCart from '../../hooks/useCart';
import CheckoutButton from '../Button/CheckoutButton';
import CustomText from '../CustomText';
import SubHeading2 from '../SubHeading/SubHeading2';

import BottomDrawer from './BottomDrawer';

const CheckoutBottom = ({ products, onPress, isLoading }) => {
  const { cart, recipes } = useCart();
  const { retailer, retailerTotals } = useContext(QuoteContext);

  const total = retailerTotals[retailer.id];

  const displayPrices = !!(total && total > 0);
  const totalServings = useMemo(() => cart.reduce((prev, curr) => (prev += curr.servings), 0), [cart]);

  return (
    <BottomDrawer isOpen disableOverlay>
      <Box width="100%">
        <Box padding={2}>
          <Box flexDirection="row" justifyContent="space-between" width="100%">
            <SubHeading2>Total</SubHeading2>
            {displayPrices && <SubHeading2>£{total.toFixed(2)}</SubHeading2>}
            {!displayPrices && <SubHeading2>£ -.--</SubHeading2>}
          </Box>
          <Box flexDirection="row" justifyContent="space-between" width="100%" paddingY={2}>
            <CustomText>
              {recipes.length} recipe{recipes.length > 1 && 's'}, {totalServings} servings
            </CustomText>
            {displayPrices && <CustomText>£{(total / totalServings).toFixed(2)} per serving</CustomText>}
            {!displayPrices && <CustomText>prices shown on amazon.co.uk</CustomText>}
          </Box>
        </Box>
        <CheckoutButton width="100%" products={products} retailer={retailer} onPress={onPress} isLoading={isLoading} />
      </Box>
    </BottomDrawer>
  );
};

export default CheckoutBottom;
