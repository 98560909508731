import { useContext } from 'react';
import { Box, Button, Text } from 'native-base';

import { CheckoutContext } from '../../contexts/CheckoutContext';
import useCart from '../../hooks/useCart';
import fontSize from '../../styles/fontSize';
import ImageWrapper from '../ImageWrapper';

const COLORS = {
  'amazonfresh-gb': '#74BC1F',
  tesco: '#02549F',
  sainsburys: '#F47320',
  ocado: '#4D216D',
  waitrose: '#7BB134',
};

const CheckoutButton = (props) => {
  const { products, retailer, onPress } = props;
  const { name, imageWhite } = retailer;

  const { cart } = useCart();
  const { trackContinueToSupermarket } = useContext(CheckoutContext);

  const trackedOnPress = () => {
    trackContinueToSupermarket(cart, products, retailer);
    return onPress();
  };

  return (
    <Button
      variant="solid"
      borderRadius={8}
      bg={COLORS[retailer.id]}
      height={59}
      _hover={{ bg: COLORS[retailer.id], opacity: 0.8 }}
      _pressed={{ bg: COLORS[retailer.id], opacity: 0.8 }}
      {...props}
      onPress={trackedOnPress}
    >
      <Box flexDirection="row" alignItems="center" justifyContent="center" gap={2}>
        <Text fontFamily="body" fontWeight={700} fontSize={fontSize(16)} color="white">
          Checkout at
        </Text>
        <ImageWrapper
          displayWidth={65}
          src={imageWhite}
          alt={name}
          width="75px"
          marginTop="5px"
          height="30px"
          resizeMode="contain"
        />
      </Box>
    </Button>
  );
};

export default CheckoutButton;
