import React from 'react';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { Center, useTheme } from 'native-base';

import FAProIcon from '../components/FAProIcon';
import Loading from '../components/Loading';

import AccountScreen from './AccountScreen/AccountScreen';
import ExploreStack from './ExploreStack';
import OrderedScreen from './OrderedScreen';

const Tab = createMaterialTopTabNavigator();

const MainTabs = () => {
  const { colors } = useTheme();
  return (
    <Tab.Navigator
      tabBarPosition="bottom"
      screenOptions={{
        tabBarLabelStyle: {
          fontFamily: 'Manrope-Bold',
          fontStyle: 'normal',
          fontWeight: 700,
          fontSize: 12,
          textTransform: 'none',
        },
        tabBarActiveTintColor: colors.brightNavy[500],
        tabBarInactiveTintColor: colors.brightNavy[400],
        tabBarIndicatorStyle: {
          backgroundColor: colors.brightNavy[700],
          borderRadius: 10,
          height: 5,
          bottom: 2,
          left: '2%',
          width: '29%',
        },
        tabBarStyle: {
          borderTopColor: '#E3E3E3',
          borderTopWidth: 1,
        },
        swipeEnabled: false,
        lazy: true,
        lazyPlaceholder: () => (
          <Center flex={1} bg="white">
            <Loading />
          </Center>
        ),
      }}
    >
      <Tab.Screen
        name="ExploreStack"
        component={ExploreStack}
        options={{
          title: 'Explore',
          tabBarIcon: ({ focused, color }) => <FAProIcon name="search" solid={focused} color={color} size={24} />,
        }}
      />
      <Tab.Screen
        name="Ordered"
        component={OrderedScreen}
        options={{
          title: 'Cook',
          tabBarIcon: ({ focused, color }) => <FAProIcon name="oven" solid={focused} color={color} size={24} />,
        }}
      />
      <Tab.Screen
        name="Account"
        component={AccountScreen}
        options={{
          title: 'Account',
          tabBarIcon: ({ focused, color }) => <FAProIcon name="user-circle" solid={focused} color={color} size={24} />,
        }}
      />
    </Tab.Navigator>
  );
};

export default MainTabs;
