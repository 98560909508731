import { memo } from 'react';
import { useLinkTo } from '@react-navigation/native';
import { Box, Pressable } from 'native-base';

import Body3 from './Body/Body3';
import ImageWrapper from './ImageWrapper';

export const RECIPE_TILE_WIDTH = 104;
export const RECIPE_TILE_HEIGHT = 104;
export const RECIPE_TILE_MARGIN_REM = 2;

const RecipeTileSmall = (props) => {
  const { recipe } = props;
  const linkTo = useLinkTo();

  if (!recipe) return null;

  const { id, name } = recipe;

  return (
    <Pressable onPress={() => linkTo(`/ordered/${id}`)} margin={RECIPE_TILE_MARGIN_REM}>
      <Box width={RECIPE_TILE_WIDTH}>
        <ImageWrapper
          displayWidth={RECIPE_TILE_WIDTH}
          src={recipe.images[0]}
          alt={recipe.name}
          width={`${RECIPE_TILE_WIDTH}px`}
          height={`${RECIPE_TILE_HEIGHT}px`}
          resizeMode="cover"
          borderRadius="8px"
        />

        <Body3 paddingTop={3} isTruncated numberOfLines={2}>
          {name}
        </Body3>
      </Box>
    </Pressable>
  );
};

export default memo(RecipeTileSmall);
