import React, { useContext, useEffect, useState } from 'react';
import { Box } from 'native-base';

import { UserContext } from '../../../contexts/UserContext';
import { VisitorContext } from '../../../contexts/VisitorContext';
import Counter from '../../Counter';

import FilterSection from './FilterSection';

let saveTimeout;
const ChangeHouseholdSize = ({ label = 'Household size', boxProps }) => {
  const { profile, updateProfile } = useContext(UserContext);
  const { visitorHouseholdSize, visitorHouseholdSizeSet } = useContext(VisitorContext);

  const [householdSize, householdSizeSet] = useState(profile ? profile.householdSize : visitorHouseholdSize);

  useEffect(() => {
    clearTimeout(saveTimeout);
    saveTimeout = setTimeout(() => {
      if (profile) {
        if (profile.householdSize !== householdSize) {
          updateProfile({ householdSize });
        }
      } else if (visitorHouseholdSize !== householdSize) {
        visitorHouseholdSizeSet(householdSize);
      }
    }, 1000);
  }, [householdSize, profile, updateProfile, visitorHouseholdSize, visitorHouseholdSizeSet]);

  return (
    <FilterSection label={label} boxProps={boxProps}>
      <Box flexDirection="row" gap={2} flexWrap="wrap">
        <Counter value={householdSize} onChange={householdSizeSet} />
      </Box>
    </FilterSection>
  );
};
export default ChangeHouseholdSize;
