import { useState } from 'react';
import { Pressable } from 'react-native';
import { useKeepAwake } from 'expo-keep-awake';
import { Box, Column, Row } from 'native-base';

import Body3 from '../../components/Body/Body3';
import FAProIcon from '../../components/FAProIcon';
import ImageWrapper from '../../components/ImageWrapper';
import IngredientItem from '../../components/IngredientItem';
import SubHeading1 from '../../components/SubHeading/SubHeading1';
import TextWithBold from '../../components/TextWithBold';

const Tabs = (props) => {
  const { recipe, servings } = props;
  const [activeTab, activeTabSet] = useState(0);

  useKeepAwake();

  if (!recipe) return null;

  const { ingredientGroups, directionGroups } = recipe;

  const ingredients = ingredientGroups.reduce((a, c) => [...a, ...c.list], []);
  const ingredientsA = ingredients.filter((a) => !a.cupboardItem);
  const ingredientsB = ingredients.filter((a) => a.cupboardItem);

  const groups = () => {
    let cumulativeCount = 0;

    return directionGroups.map((g, gi) => {
      const group = (
        <Column key={gi} borderRadius={10} bg="almond.200" paddingX={5} marginTop={2}>
          {g.title && <SubHeading1 paddingY={4}>{g.title}</SubHeading1>}
          {g?.images && g.images.length > 0 && (
            <ImageWrapper src={g.images[0]} alt={g.title} width="100%" height="300px" borderRadius={8} />
          )}
          {g.list.map((d, di) => (
            <Column key={d.id} paddingY={4} space={2}>
              <SubHeading1>{cumulativeCount + di + 1}</SubHeading1>
              <TextWithBold text={d.text} />
            </Column>
          ))}
        </Column>
      );
      cumulativeCount += g.list.length;
      return group;
    });
  };

  return (
    <Box paddingY={2}>
      <Row width="100%" borderBottomWidth={0.5} borderColor="#E3E3E3">
        <Box flex={1}>
          <Pressable onPress={() => activeTabSet(0)}>
            <Column alignItems="center" space={2}>
              <FAProIcon name="carrot" size={16} solid={activeTab === 0} />

              <Box borderColor="mustard.500" borderBottomWidth={activeTab === 0 ? 2 : 0}>
                <Body3 padding={1}>Ingredients • {ingredientsA.length}</Body3>
              </Box>
            </Column>
          </Pressable>
        </Box>

        <Box flex={1}>
          <Pressable onPress={() => activeTabSet(1)}>
            <Column alignItems="center" space={2}>
              <FAProIcon name="list" size={16} solid={activeTab === 1} />
              <Box borderColor="mustard.500" borderBottomWidth={activeTab === 1 ? 2 : 0}>
                <Body3 padding={1}>Method</Body3>
              </Box>
            </Column>
          </Pressable>
        </Box>
      </Row>

      {activeTab === 0 && (
        <Column space={2}>
          <SubHeading1 paddingTop={4} paddingBottom={3}>
            Ingredients
          </SubHeading1>
          {ingredientsA.map((ingredient) => (
            <IngredientItem
              key={ingredient.id}
              ingredient={ingredient}
              servings={servings}
              recipeServings={recipe.servings}
            />
          ))}
          <SubHeading1 paddingTop={4} paddingBottom={3}>
            Items you may also need
          </SubHeading1>
          <Body3>
            These are things we think you'll already have at home but we'll give you the option to add to your cart.
          </Body3>
          {ingredientsB.map((ingredient) => (
            <IngredientItem
              key={ingredient.id}
              ingredient={ingredient}
              servings={servings}
              recipeServings={recipe.servings}
            />
          ))}
        </Column>
      )}

      {activeTab === 1 && <Box marginTop={2}>{groups()}</Box>}
    </Box>
  );
};

export default Tabs;
