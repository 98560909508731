import { Platform } from 'react-native';

import SainsburysCartScreenApp from './SainsburysCartScreenApp';
import SainsburysCartScreenWeb from './SainsburysCartScreenWeb';

/**
 * @deprecated we no longer support web checkouts in expo
 */
const SainsburysCartScreen = () => {
  if (Platform.OS === 'web') {
    return <SainsburysCartScreenWeb />;
  }

  return <SainsburysCartScreenApp />;
};

export default SainsburysCartScreen;
