import api from '../api';
import { showNotification } from '../notifications';

const taskKey = 'push-notifications';
const log = (...args) => {
  console.log(taskKey, ...args);
};

export const pushTask = async (now, profile) => {
  log(`Check push notifications`, profile.notifications);

  if (!profile.notifications) {
    log('if (!profile.notifications) {');
    return false;
  }

  let notification = null;

  for (const n of profile.notifications) {
    const goLive = new Date(n.goLiveDate).getTime();
    if (now > goLive) {
      notification = n;
      break;
    }
  }

  if (!notification) {
    log('if (!notification) { ');
    return false;
  }

  await api.user.updateProfile({ notifications: profile.notifications.filter((n) => n.id !== notification.id) });

  showNotification(notification.title, notification.body, notification.link);

  return true;
};
