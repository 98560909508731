import React, { useContext, useMemo } from 'react';
import { Box, Center } from 'native-base';

import { QuoteContext } from '../../contexts/QuoteContext';
import useCart from '../../hooks/useCart';
import AddRemoveButton from '../AddRemoveButton';
import Body3 from '../Body/Body3';
import CounterButton from '../CounterButton';

import BottomDrawer from './BottomDrawer';

const RecipeBottom = (props) => {
  const { recipe, servings, servingsSet } = props;
  const id = useMemo(() => recipe?.id, [recipe]);

  const { retailer } = useContext(QuoteContext);
  const { isAdded, cartItem, add, remove } = useCart(id);

  const handleAdd = () => {
    add(id, servings);
  };

  const handleRemove = () => {
    remove(id);
  };

  const cost = recipe.costPerRetailer[retailer.id];

  return (
    <BottomDrawer isOpen disableOverlay>
      <Box flexDirection="row" justifyContent="space-between" width="100%" pb={cost > 0 ? undefined : 5}>
        <Center flexDirection="column">
          <CounterButton
            value={cartItem ? cartItem.servings : servings}
            onChange={servingsSet}
            size="small"
            variant="outline"
            disabled={isAdded}
          />
          {cost > 0 ? <Body3>£{cost.toFixed(2)} p/s</Body3> : undefined}
        </Center>
        <AddRemoveButton isAdded={isAdded} handleAdd={handleAdd} handleRemove={handleRemove} />
      </Box>
    </BottomDrawer>
  );
};

export default RecipeBottom;
