import { useCallback, useEffect, useState } from 'react';

const move = (src, dst, id) =>
  src
    .get(id)
    .then((a) => dst.put(a.id, a))
    .then(() => src.del(id));

const copy = (src, dst, id) => src.get(id).then((a) => dst.put(a.id, a));

const useCrudTables = (table, api) => {
  const [loading, loadingSet] = useState(true);
  const [rows, rowsSet] = useState([]);
  const { draft, live, archive } = api;
  const use = api[table];

  const setLoading = () => {
    loadingSet(true);
    return Promise.resolve();
  };
  const clearLoading = () => {
    loadingSet(false);
    return Promise.resolve();
  };

  const list = useCallback(
    () =>
      setLoading()
        .then(() => use.list())
        .then(rowsSet)
        .then(clearLoading),
    [use],
  );

  const get = useCallback(
    (id) =>
      setLoading()
        .then(() => use.get(id))
        .then((res) => {
          clearLoading();
          return res;
        }),
    [use],
  );

  const post = useCallback(
    (id, v) =>
      setLoading()
        .then(() => use.post(id, v))
        .then(list),
    [list, use],
  );

  const put = useCallback(
    (id, v) =>
      setLoading()
        .then(() => use.put(id, v))
        .then(list),
    [list, use],
  );

  const del = useCallback(
    (ids) =>
      setLoading()
        .then(() => Promise.allSettled(ids.map((id) => use.del(id))))
        .then(list),
    [list, use],
  );

  const copyLiveToDraft = useCallback(
    (ids) =>
      setLoading()
        .then(() => Promise.allSettled(ids.map((id) => copy(live, draft, id))))
        .then(clearLoading),
    [draft, live],
  );

  const moveDraftToLive = useCallback(
    (ids) =>
      setLoading()
        .then(() => Promise.allSettled(ids.map((id) => move(draft, live, id))))
        .then(list),
    [draft, list, live],
  );

  const moveDraftToArchive = useCallback(
    (ids) =>
      setLoading()
        .then(() => Promise.allSettled(ids.map((id) => move(draft, archive, id))))
        .then(list),
    [archive, draft, list],
  );

  const moveLiveToArchive = useCallback(
    (ids) =>
      setLoading()
        .then(() => Promise.allSettled(ids.map((id) => move(live, archive, id))))
        .then(list),
    [archive, list, live],
  );

  const moveArchiveToDraft = useCallback(
    (ids) =>
      setLoading()
        .then(() => Promise.allSettled(ids.map((id) => move(archive, draft, id))))
        .then(list),
    [archive, draft, list],
  );

  const moveArchiveToLive = useCallback(
    (ids) =>
      setLoading()
        .then(() => Promise.allSettled(ids.map((id) => move(archive, live, id))))
        .then(list),
    [archive, list, live],
  );

  useEffect(() => {
    list();
  }, [list]);

  return {
    loading,
    rows,
    list,
    get,
    post,
    put,
    del,
    copyLiveToDraft,
    moveDraftToLive,
    moveDraftToArchive,
    moveLiveToArchive,
    moveArchiveToDraft,
    moveArchiveToLive,
  };
};

export default useCrudTables;
