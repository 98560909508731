import { memo, useContext } from 'react';
import { useLinkTo } from '@react-navigation/native';
import { Box, Center, Column, Pressable, Row } from 'native-base';

import { ChefLiveContext } from '../contexts/ChefLiveContext';
import { QuoteContext } from '../contexts/QuoteContext';
import { UserContext } from '../contexts/UserContext';
import useCart from '../hooks/useCart';
import { getTimeAsString } from '../lib/util';

import Heading2 from './Heading/Heading2';
import FavouriteButton from './IconButton/FavouriteButton';
import AddRemoveButton from './AddRemoveButton';
import ChefAvatar from './ChefAvatar';
import CustomText from './CustomText';
import FAProIcon from './FAProIcon';
import ImageWrapper from './ImageWrapper';
import MurkyGradient from './MurkyGradient';
import SupermarketPrice from './SupermarketPrice';

export const RECIPE_CARD_WIDTH = 380;
export const RECIPE_CARD_HEIGHT = 330;

const RecipeCard = (props) => {
  const { recipe, width = RECIPE_CARD_WIDTH, showLoaderSet } = props;
  const linkTo = useLinkTo();
  const { retailer } = useContext(QuoteContext);
  const { find: findChef } = useContext(ChefLiveContext);
  const { profile, visitorHouseholdSize } = useContext(UserContext);
  const { isAdded, add, remove } = useCart(recipe?.id);

  const servings = recipe.fixedServings
    ? recipe.servings
    : profile?.householdSize || visitorHouseholdSize || recipe.servings;

  if (!recipe) return null;

  const { id, name, nutrition, costPerRetailer, chefId } = recipe;

  const chef = findChef(chefId);

  const ingredients = recipe.ingredientGroups.reduce((a, c) => [...a, ...c.list], []);
  const ingredientsA = ingredients.filter((a) => !a.cupboardItem);

  const calories = (nutrition?.enerc_kcal || 0) / recipe.servings;
  const numIngredients = ingredientsA.length;
  const cost = retailer && costPerRetailer[retailer.id];

  const time = getTimeAsString(recipe.totalTime);

  const handleAdd = () => {
    add(id, servings);
  };

  const handleRemove = () => {
    remove(id);
  };

  const handleRedirect = () => {
    showLoaderSet && showLoaderSet(false);
    linkTo(`/recipe/${id}`);
  };

  return (
    <Pressable onPress={handleRedirect} margin={2}>
      <Box maxWidth="100%" width={width} height={RECIPE_CARD_HEIGHT}>
        <Box position="relative" width="100%" height={200} justifyContent="flex-end">
          <ImageWrapper
            src={recipe.images[0]}
            alt={recipe.name}
            width="100%"
            height="200px"
            resizeMode="cover"
            borderRadius={16}
            position="absolute"
          />
          <MurkyGradient
            pt={8}
            padding={2}
            gap={1}
            color1="#29262D00"
            color2="#29262D99"
            flexDirection="row"
            justifyContent="space-between"
            borderBottomRadius={16}
          >
            <Box display="flex" flexDirection="row" gap={1} alignItems="center">
              <ChefAvatar chef={chef} />
              <Box flexDirection="row" height="24px" alignItems="center">
                <Center width="24px" height="24px">
                  <FAProIcon name="clock" size={16} color="white" />
                </Center>
                <CustomText color="white" fontWeight={700}>
                  {time}
                </CustomText>
              </Box>
            </Box>
            <Box flex={1} />
            <FavouriteButton id={id} />
          </MurkyGradient>
        </Box>

        <Heading2 paddingTop={3}>{name}</Heading2>

        <Row paddingTop={3} justifyContent="space-between">
          <Column>
            <CustomText color="mushroom.500" paddingBottom={1}>
              {calories.toFixed(0)}kcal • {numIngredients} ingredients
            </CustomText>
            <SupermarketPrice cost={cost} retailer={retailer} />
          </Column>

          <Column>
            <AddRemoveButton isAdded={isAdded} handleAdd={handleAdd} handleRemove={handleRemove} />
          </Column>
        </Row>
      </Box>
    </Pressable>
  );
};

export default memo(RecipeCard);
