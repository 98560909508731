import React from 'react';

import Chip from './Chip';
import SupermarketLogo from './SupermarketLogo';

const SupermarketChip = ({ retailer, selected, onPress }) => (
  <Chip variant={selected ? 'active' : 'outline'} onPress={onPress}>
    <SupermarketLogo retailer={retailer} height="40px" white={selected} />
  </Chip>
);

export default SupermarketChip;
