import React, { useContext } from 'react';
import { Box } from 'native-base';

import { FilterContext } from '../../../contexts/FilterContext';
import Chip from '../../Chip';

import FilterSection from './FilterSection';

const ChangeCostPerServing = ({ label = 'Cost per serving', withClear = true, boxProps }) => {
  const { costMax, costMaxSet } = useContext(FilterContext);

  const handleCost = (cost) => {
    costMaxSet(cost);
  };

  const handleClear = () => {
    costMaxSet(9999);
  };

  return (
    <FilterSection label={label} clearFilter={withClear ? handleClear : undefined} boxProps={boxProps}>
      <Box flexDirection="row" gap={2} flexWrap="wrap">
        <Chip label="£2" variant={costMax >= 2 ? 'active' : 'outline'} onPress={() => handleCost(2)} />
        <Chip
          label="£4"
          variant={costMax >= 4 ? 'active' : 'outline'}
          onPress={() => handleCost(costMax === 4 ? 2 : 4)}
        />
        <Chip
          label="£6"
          variant={costMax >= 6 ? 'active' : 'outline'}
          onPress={() => handleCost(costMax === 6 ? 4 : 6)}
        />
        <Chip
          label="£8+"
          variant={costMax >= 8 ? 'active' : 'outline'}
          onPress={() => handleCost(costMax === 9999 ? 6 : 9999)}
        />
      </Box>
    </FilterSection>
  );
};

export default ChangeCostPerServing;
