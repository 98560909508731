import { memo } from 'react';
import { useLinkTo } from '@react-navigation/native';
import { Box, Button, Pressable, Row } from 'native-base';

import useOrdered from '../hooks/useOrdered';
import { getTimeAsString } from '../lib/util';

import Heading2 from './Heading/Heading2';
import CustomText from './CustomText';
import FAProIcon from './FAProIcon';
import ImageWrapper from './ImageWrapper';

export const RECIPE_TILE_WIDTH = 164;
export const RECIPE_TILE_MARGIN_REM = 2;

const RecipeTile = (props) => {
  const { recipe } = props;
  const linkTo = useLinkTo();
  const { orders } = useOrdered();

  if (!recipe) return null;

  const { id, name } = recipe;

  const time = getTimeAsString(recipe.totalTime);

  const isCooked = orders[0]?.recipes.find((r) => r.id === id)?.isCooked;

  return (
    <Pressable onPress={() => linkTo(`/ordered/${id}`)} margin={RECIPE_TILE_MARGIN_REM}>
      <Box width={RECIPE_TILE_WIDTH} height={225}>
        <Box>
          <Box>
            <ImageWrapper
              displayWidth={RECIPE_TILE_WIDTH}
              src={recipe.images[0]}
              alt={recipe.name}
              width={`${RECIPE_TILE_WIDTH}px`}
              height="164px"
              resizeMode="cover"
              borderRadius="10px"
            />

            <Box position="absolute" top={2} bottom={2} left={2} right={2} alignItems="center" justifyContent="center">
              {isCooked && (
                <Button
                  variant="subtle"
                  size="medium"
                  leftIcon={<FAProIcon name="check" />}
                  onPress={() => linkTo(`/ordered/${id}`)}
                >
                  Cooked
                </Button>
              )}
            </Box>
          </Box>

          <Row position="absolute" bottom={2} left={2} right={2} alignItems="center" space={2}>
            <FAProIcon name="clock" size={16} color="white" />
            <CustomText color="white" fontWeight={700}>
              {time}
            </CustomText>
          </Row>
        </Box>

        <Heading2 paddingTop={3} isTruncated numberOfLines={2}>
          {name}
        </Heading2>
      </Box>
    </Pressable>
  );
};

export default memo(RecipeTile);
